import {
  GraphQLResolveInfo,
  SelectionSetNode,
  FieldNode,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Milliseconds: { input: any; output: any }
  Nanoseconds: { input: any; output: any }
  Int64: { input: any; output: any }
  Time: { input: any; output: any }
  Map: { input: any; output: any }
  Any: { input: any; output: any }
  URL: { input: any; output: any }
  StringMap: { input: any; output: any }
  /** A NVIdentity type name. */
  NVIdentityType: { input: any; output: any }
}

/** BatteryMasterUnit (BMU) */
export type BatteryMasterUnit = Computer &
  NorthvoltIdentity & {
    readonly id: Scalars['ID']['output']
    readonly idref?: Maybe<IdRef>
    readonly meta: IdentityMeta
    readonly displayName?: Maybe<Scalars['String']['output']>
    /** BMU State, software versions and serials */
    readonly state?: Maybe<BatteryMasterUnitState>
  }

/** BatteryMasterUnitState is the reported status of the BMU */
export type BatteryMasterUnitState = NorthvoltState & {
  readonly id: Scalars['ID']['output']
  readonly idref?: Maybe<IdRef>
  readonly meta: StateMeta
  /** BMU software version, for example: 1.03.08_dev */
  readonly version?: Maybe<Scalars['String']['output']>
  /** BMU serial number (aka BMU SN), for example: 193148381006 */
  readonly serial?: Maybe<Scalars['String']['output']>
  /** Main controller software version, example: 1.1.3@cc3f */
  readonly mcVersion?: Maybe<Scalars['String']['output']>
  /** Cell sensing circuit software version, example: 0.0.4 */
  readonly cscVersion?: Maybe<Scalars['String']['output']>
  /** Safety controller software version, example: 0.0.10@6a56 */
  readonly scVersion?: Maybe<Scalars['String']['output']>
  /** Power measurement board software version, example: 3.4.8 */
  readonly pmbVersion?: Maybe<Scalars['String']['output']>
}

/** Internal type. [BatteryFault!] list should be included into BatterySystemState. */
export type BatteryFaults = NorthvoltState & {
  readonly id: Scalars['ID']['output']
  readonly meta: StateMeta
  readonly idref?: Maybe<IdRef>
  /**
   * Faults associated with this battery system. Empty list means no faults are reported at the moment.
   *
   * Null means that faults could not be retrieved.
   */
  readonly list?: Maybe<ReadonlyArray<BatteryFault>>
}

/** Information about a specific fault as reported by a battery system */
export type BatteryFault = {
  /** DTC SPN code for the fault */
  readonly dtcSpn: Scalars['Int']['output']
  /** FMI J1939 Failure Mode Identifier severity of the fault */
  readonly fmi?: Maybe<Scalars['Int']['output']>
  /** Occurence count of the fault */
  readonly occurrenceCount?: Maybe<Scalars['Int']['output']>
  /**
   * First occurence time of the fault. The occurrence time is when the BMS decides there is (or was) a fault, which can be
   * a little later than when the fault has really occurred.
   */
  readonly firstOccurredAt?: Maybe<Scalars['Time']['output']>
  /**
   * Last occurence time of the fault. The occurrence time is when the BMS decides there is (or was) a fault, which can be
   * a little later than when the fault has really occurred.
   */
  readonly lastOccurredAt?: Maybe<Scalars['Time']['output']>
  /** The time when the fault was reported from the battery system. */
  readonly reportedAt?: Maybe<Scalars['Time']['output']>
  /**
   * Time since system start when DTC was first triggered during the current power cycle,
   * in fractional seconds [s].
   *
   * Not reported if the DTC has not triggered this power cycle.
   */
  readonly timeSinceCurrentPowerCycleStart?: Maybe<Scalars['Float']['output']>
  /** If the fault is active */
  readonly isActive?: Maybe<Scalars['Boolean']['output']>
  /** Action enumeration code */
  readonly action?: Maybe<Scalars['Int']['output']>
  /**
   * Error trigger code.
   * Index of the first error trigger for the DTC, as reported by the BMU diagnostics protocol.
   */
  readonly errorTrigger?: Maybe<Scalars['Int64']['output']>
  /**
   * Error triggers.
   * List of error trigger codes and additional values, as reported by the ACO.
   */
  readonly errorTriggers?: Maybe<ReadonlyArray<BatteryFaultTrigger>>
  /**
   * Fault code type indicating the originating system (BMU, cooling unit, ...)
   * 0: BMU
   * 1: Cooling System
   */
  readonly type?: Maybe<Scalars['Int']['output']>
}

/** Information about an error trigger causing a fault code. */
export type BatteryFaultTrigger = {
  /** The error trigger code. */
  readonly code: Scalars['Int']['output']
  /** The error trigger name. */
  readonly name?: Maybe<Scalars['String']['output']>
  /**
   * The index of an array error trigger code.
   *
   * Each element in the array is a separate error trigger of the same kind, typically for the same
   * kind of error where there are multiple devices. The code/name will give the kind of error, the
   * index which device, and each one can have a value with further information for that instance.
   * Multiple triggered instances are sent as multiple BatteryFaultTrigger:s.
   * An example of an index could be the module number for error triggers coming from module level
   * circuits.
   */
  readonly index?: Maybe<Scalars['Int']['output']>
  /**
   * Additional value related to the trigger. This may represent where the trigger
   * happened, as a bitfield or numbering.
   */
  readonly value?: Maybe<Scalars['Int']['output']>
  /**
   * Time since system start when the trigger first happened during the current power cycle,
   * in fractional seconds [s].
   *
   * Not reported if the trigger has not triggered this power cycle.
   */
  readonly timeSinceCurrentPowerCycleStart?: Maybe<Scalars['Float']['output']>
}

/**
 * Experimental state of a Voltpack Mobile Hub.
 * Not guaranteed to be backwards compatible.
 * Use with caution.
 *
 * This state intentionally uses names as close to RTDB as possible,
 * in order to enable closer collaboration from embedded through cloud.
 *
 * 🚚🔋 Maintained by the Voltpack Mobile Software team. 🚚🔋
 */
export type HubState = {
  /**
   * RTDB Variables for the Hub.
   *
   * It will have string keys and values that are JSON numbers or booleans.
   * The keys will be RTDB variable names from the Hub:
   * {
   *   "RTDB_AC_LOAD_FREQUENCY_HZ_F32": 50.000004,
   *   "RTDB_AC_LOAD_POWER_KW_F32": 0,
   *   "RTDB_AC_LOAD_REACTIVE_POWER_KVAR_F32": 0,
   *   "RTDB_AC_RPS_GRID_FREQUENCY_HZ_F32": 59.329002,
   *   "RTDB_AC_RPS_REACTIVE_POWER_KVAR_F32": 0,
   *   "RTDB_AC_RPS_TOTAL_POWER_KW_F32": 0,
   *   "RTDB_LOAD_BREAKER_IS_OPEN_B": true,
   * }
   *
   * Arrays are mapped inside this Map such that each entry is suffixed with ".<index>":
   * {
   *   "RTDB_ACO_VERSION_AU08.0": 34,
   *   "RTDB_ACO_VERSION_AU08.1": 48,
   *   "RTDB_ACO_VERSION_AU08.2": 100,
   *   "RTDB_ACO_VERSION_AU08.3": 56,
   * }
   */
  readonly rtdbVariables: Scalars['Map']['output']
}

/** A Northvolt battery module */
export type BatteryModule = NorthvoltIdentity & {
  readonly id: Scalars['ID']['output']
  readonly meta: IdentityMeta
  readonly idref?: Maybe<IdRef>
  readonly displayName?: Maybe<Scalars['String']['output']>
  readonly parentSystem?: Maybe<BatterySystem>
  readonly state?: Maybe<BatteryModuleState>
}

/**
 * Battery module state.
 *
 * Null in any field means the retrieval of the value for that field failed.
 *
 * The lengths of all array fields in this state are always the same for the same model and generation of the
 * battery module, but are all unrelated. If the lengths of some arrays are the equal, this should be considered
 * as a coincidence and not as a rule.
 */
export type BatteryModuleState = NorthvoltState & {
  readonly id: Scalars['ID']['output']
  readonly idref?: Maybe<IdRef>
  readonly meta: StateMeta
  /**
   * Position (1-based) of the module within the parent system. Null if this module has no parent, or
   * if the position within the parent system is not available.
   */
  readonly position?: Maybe<Scalars['Int']['output']>
  /**
   * If the module is currently balancing the charge level of the cells or cellgroups. Null element in the array means
   * the module is not currently reporting the corresponding signal, or this signal couldn't be retreived. The length
   * of the array is equal to the number of the voltage sensors in the module, and, thus, is equal to the length of
   * the cellVoltages array.
   */
  readonly isBalancingCells?: Maybe<ReadonlyArray<Maybe<Scalars['Boolean']['output']>>>
  /**
   * Temperatures from the sensors on the battery modules' board [°C]. These temperatures usually rise when the module
   * is balancing cells, hence the name of this field. However, the length of this array is not guaranteed to be equal
   * to the length of the isBalancingCells array, and the elements in these arrays at the same index are not guaranteed
   * to be associated.
   *
   * Null element in the array means the corresponding temperature is not currently reported from the module (the sensor
   * might not work), or couldn't be retreived. The length of the array is the number of sensors on the module board.
   *
   * The module board sensors could be heterogeneous.
   */
  readonly cellBalancingTemperatures?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  /**
   * Cell/cellgroup voltages [V]. Null element in the array means the corresponding cell voltage is not currently reported
   * from the module (the sensor might not work), or couldn't be retreived. However, the length of the array is always
   * equal to the number of the voltage sensors in the module, and, thus, is equal to the length of the isBalancingCells
   * array.
   */
  readonly cellVoltages?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  /**
   * Module cell temperature sensors [°C]. Null element in the array means the corresponding temperature is not
   * currently reported from the module (the sensor might not work), or couldn't be retreived. However, the length of the
   * array is always equal to the number of the temperature sensors on the module's cells.
   */
  readonly cellTemperatures?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  /**
   * Find the documentation for the variables here:
   * https://systems.northvolt.com/wrapper/batintel/dbt_documentation/#!/source/source.data_lake.stage_telemetry.cellblock
   */
  readonly agg?: Maybe<AggModule>
  /** DCIR measurement */
  readonly dcir?: Maybe<Scalars['Float']['output']>
  /** Cell batch number */
  readonly cellBatchNumber?: Maybe<Scalars['String']['output']>
  /** Cell capacity grade */
  readonly cellCapacityGrade?: Maybe<Scalars['Int']['output']>
  /** Module Connectivity ID */
  readonly connectivityId?: Maybe<Scalars['String']['output']>
  /** The serial number written on the physical label of the module */
  readonly serialNumber?: Maybe<Scalars['String']['output']>
  /** Cell type identifiers, one per module. E.g. 1 for Samsung, 2 for BAK */
  readonly cellType?: Maybe<Scalars['Int']['output']>
  /** Accumulated Ampere Hours of charging */
  readonly lifetimeChargedAh?: Maybe<Scalars['Int']['output']>
  /** Accumulated Watt Hours of charging */
  readonly lifetimeChargedWh?: Maybe<Scalars['Int']['output']>
  /** Accumulated Ampere Hours of discharging */
  readonly lifetimeDischargedAh?: Maybe<Scalars['Int']['output']>
  /** Accumulated Watt Hours of discharging */
  readonly lifetimeDischargedWh?: Maybe<Scalars['Int']['output']>
  /** CSB ID (32 ASCII chars written to module by supplier) */
  readonly csbId?: Maybe<Scalars['String']['output']>
}

export type AggModule = {
  readonly timeAtEnd?: Maybe<Scalars['Time']['output']>
  readonly cellVoltageMax?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  readonly cellVoltageMin?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  readonly cellVoltageAvg?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  readonly cellVoltageMaxStep?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
}

export type BatteryQuery = {
  readonly filter?: InputMaybe<BatteryFilter>
}

export enum OrderBy {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export enum SortBy {
  Observed = 'Observed',
  DisplayName = 'DisplayName',
  Id = 'ID',
  Kind = 'Kind',
}

export type BatteryFilter = {
  readonly search?: InputMaybe<Scalars['String']['input']>
  readonly generation?: InputMaybe<BatterySystemGeneration>
  readonly hasState?: InputMaybe<Scalars['Boolean']['input']>
  readonly kind?: InputMaybe<ReadonlyArray<BatterySystemKind>>
  readonly limit?: InputMaybe<Scalars['Int']['input']>
  readonly sortBy?: InputMaybe<SortBy>
  readonly orderBy?: InputMaybe<OrderBy>
}

export type ModuleInput = {
  readonly connectivityId?: InputMaybe<Scalars['String']['input']>
  readonly labelId?: InputMaybe<Scalars['String']['input']>
}

export type Query = {
  /** List all connected battery systems. (Batlocal, should be removed when batlocal is removed) */
  readonly batterySystems: BatterySystemsResult
  /** List all connected battery systems. (Batcloud batterysystems query) */
  readonly systems: BatterySystemsResult
  /** List all connected battery systems from a list of ids. */
  readonly systemsByIDs: BatterySystemsResult
  /** Get the pre-signed url to the battery360 S3 data for one specific system */
  readonly battery360?: Maybe<Scalars['String']['output']>
  /** List all faults for a specific battery system. Empty list means no faults are reported at the moment. */
  readonly batteryFaults: ReadonlyArray<BatteryFault>
  /** Retrieve information about a specific battery system. */
  readonly batterySystem: BatterySystem
  /** List all products which the user is allowed to see. */
  readonly products: ReadonlyArray<Product>
  /** List all tenants. Only Northvolt users can make this query. */
  readonly tenants: ReadonlyArray<Tenant>
  /** List all tags which the user is allowed to see. */
  readonly tags: ReadonlyArray<Scalars['String']['output']>
  /** Retrieve information about a specific module. */
  readonly module: ModuleResult
  /** Get the state of the GQL server itself */
  readonly serverState?: Maybe<GqlServerStateResult>
  /** Retrieve the state of a thing. */
  readonly state?: Maybe<
    | BatteryMasterUnitState
    | BatteryFaults
    | BatteryModuleState
    | BatterySystemState
    | EdgeGatewayState
  >
  /** Query telemetry data. */
  readonly telemetry?: Maybe<TelemetryResult>
  readonly diagnosticsPage?: Maybe<DiagnosticsPage>
  readonly diagnosticsPages: ReadonlyArray<DiagnosticsPage>
}

export type QueryBatterySystemsArgs = {
  query?: InputMaybe<BatteryQuery>
}

export type QuerySystemsArgs = {
  filter?: InputMaybe<BatteryFilter>
}

export type QuerySystemsByIDsArgs = {
  ids: ReadonlyArray<Scalars['String']['input']>
}

export type QueryBattery360Args = {
  id: Scalars['ID']['input']
  version: Scalars['String']['input']
}

export type QueryBatteryFaultsArgs = {
  batterySystemID: Scalars['ID']['input']
}

export type QueryBatterySystemArgs = {
  id: Scalars['ID']['input']
}

export type QueryModuleArgs = {
  details: ModuleInput
}

export type QueryStateArgs = {
  id: Scalars['ID']['input']
  storageKey?: InputMaybe<Scalars['String']['input']>
}

export type QueryTelemetryArgs = {
  query?: InputMaybe<TelemetryQuery>
}

export type QueryDiagnosticsPageArgs = {
  systemId: Scalars['ID']['input']
  systemKind: BatterySystemKind
  id: Scalars['ID']['input']
}

export type QueryDiagnosticsPagesArgs = {
  systemId: Scalars['ID']['input']
  systemKind: BatterySystemKind
}

export type BatterySystemsResult = {
  readonly items: ReadonlyArray<BatterySystem>
  readonly cursor?: Maybe<Scalars['String']['output']>
}

export type ModuleResult = {
  readonly connectivityId: Scalars['String']['output']
  readonly labelId?: Maybe<Scalars['String']['output']>
  readonly tenant?: Maybe<Scalars['String']['output']>
  readonly createdAt?: Maybe<Scalars['Time']['output']>
  readonly metadata?: Maybe<Scalars['String']['output']>
}

/**
 * A software error within any Spider process: mapper or batlocal.
 *
 * This type is similar to BatteryFault, but BatteryFault represents a fault in the battery or the BMS.
 */
export type SpiderError = {
  /** A short error message which is also used as an identity for SpiderErrors. */
  readonly shortMessage: Scalars['String']['output']
  /**
   * An optional full error message. If multiple errors with the same shortMessage occurred since the
   * Spider process (or processes) have started, this can be a full message of any of those errors.
   */
  readonly fullMessage?: Maybe<Scalars['String']['output']>
  /**
   * The number of times errors with the shortMessage of this error occurred since the Spider process
   * (or processes) have started.
   */
  readonly occurrenceCount?: Maybe<Scalars['Int']['output']>
  /**
   * The system time on TED when a error with the shortMessage of this error ocurred for the first time
   * since the Spider process (or processes) have started.
   */
  readonly firstOccurredAt?: Maybe<Scalars['Time']['output']>
  /**
   * The system time on TED when a error with the shortMessage of this error ocurred for the last time
   * since the Spider process (or processes) have started.
   */
  readonly lastOccurredAt?: Maybe<Scalars['Time']['output']>
}

export enum BatterySystemKind {
  Pack = 'Pack',
  Hub = 'Hub',
  Subpack = 'Subpack',
}

export enum BatterySystemGeneration {
  C2 = 'C2',
  D = 'D',
  B2 = 'B2',
}

/**
 * A Northvolt battery system.
 *
 * Null in any field means the retrieval of the value for that field failed.
 */
export type BatterySystem = NorthvoltIdentity & {
  readonly id: Scalars['ID']['output']
  readonly meta: IdentityMeta
  readonly idref?: Maybe<IdRef>
  readonly observed?: Maybe<Scalars['String']['output']>
  /**
   * The human readable display name of the system (aka "Norhtvolt ID"). It can be set by customers via
   * API or as part of the provisioning process through Identity Management.
   */
  readonly displayName?: Maybe<Scalars['String']['output']>
  /** The kind of battery system */
  readonly kind?: Maybe<BatterySystemKind>
  /** The generation of the battery system */
  readonly generation?: Maybe<BatterySystemGeneration>
  /** The parent system which this subsystem is part of. Null means the system has no parent. */
  readonly parentSystem?: Maybe<BatterySystem>
  /**
   * The subsystems that this system is composed of. The array always has the same length: the number of the subsystem
   * positions, determined by the kind and the generation of this battery system. A null element in the array means the
   * subsystem at the corresponding position is physically missing, or is offline, or doesn't report any data yet.
   *
   * When the whole array field is null, it means this battery system doesn't have children subsystems and is composed of
   * modules instead (i. e., the modules array must not be null).
   */
  readonly subSystems?: Maybe<ReadonlyArray<Maybe<BatterySystem>>>
  /**
   * Battery modules reported by the system. The array always has the same length: the number of the module positions,
   * determined by the kind and the generation of this battery system. A null element in the array means the module at
   * the corresponding position is physically missing, or is offline, or doesn't report any data yet.
   *
   * When the whole array field is null, it means this battery system is not composed of modules directly but instead is
   * composed of children subsystems (i. e., the subSystems array must not be null).
   */
  readonly modules?: Maybe<ReadonlyArray<Maybe<BatteryModule>>>
  /** The geographical location of the system (lat/lon) */
  readonly location?: Maybe<GeoLocation>
  /** Can include location name, country, or any other tags. */
  readonly tags: ReadonlyArray<Scalars['String']['output']>
  /**
   * A list of computers that are included in the battery system. An empty array means that the battery system has no
   * computers or that its computers don't report data.
   */
  readonly computers?: Maybe<ReadonlyArray<BatteryMasterUnit | EdgeGateway>>
  /** An overview of the most important parameters reported by the battery system */
  readonly state?: Maybe<BatterySystemState>
  /** Information about the various faults in a system and their status */
  readonly faults?: Maybe<BatteryFaults>
  /**
   * Unique connectivity ID of the system. Starts with 'ST-' for cores (string) and
   * 'SY-' for packs (system).
   */
  readonly connectivityId?: Maybe<Scalars['String']['output']>
  readonly diagnosticsPages?: Maybe<ReadonlyArray<DiagnosticsPage>>
}

export type GeoLocationInput = {
  readonly longitude: Scalars['Float']['input']
  readonly latitude: Scalars['Float']['input']
}

export type CreatePackInput = {
  readonly tenant: Scalars['String']['input']
  readonly product: Scalars['String']['input']
  readonly generation: BatterySystemGeneration
  readonly displayName?: InputMaybe<Scalars['String']['input']>
}

export type CreateCoreInput = {
  readonly tenant: Scalars['String']['input']
  readonly generation: BatterySystemGeneration
  readonly macAddress: Scalars['String']['input']
  readonly displayName?: InputMaybe<Scalars['String']['input']>
}

export type CreateVoltpackMobileHubInput = {
  readonly tenant: Scalars['String']['input']
  readonly generation: BatterySystemGeneration
  readonly macAddress: Scalars['String']['input']
  readonly displayName?: InputMaybe<Scalars['String']['input']>
}

export type Tenant = {
  readonly name: Scalars['String']['output']
}

export type Product = {
  readonly name: Scalars['String']['output']
}

export type CreateModuleInput = {
  readonly labelId?: InputMaybe<Scalars['ID']['input']>
  readonly tenant?: InputMaybe<Scalars['String']['input']>
  readonly metadata?: InputMaybe<Scalars['Map']['input']>
}

export type CreateEssStringInput = {
  readonly dcpmID: Scalars['String']['input']
  readonly stringNumber: Scalars['Int']['input']
  readonly productName?: InputMaybe<Scalars['String']['input']>
}

export type Mutation = {
  readonly createPack: BatterySystemResult
  readonly setPackDisplayName: BatterySystemResult
  readonly setPackTags: BatterySystemResult
  readonly setPackTenant: BatterySystemResult
  readonly setPackGeoLocation: BatterySystemResult
  readonly createCore: BatterySystemResult
  readonly setCoreDisplayName: BatterySystemResult
  readonly setCoreTags: BatterySystemResult
  readonly setCoreTenant: BatterySystemResult
  readonly setCoreMacAddress: BatterySystemResult
  readonly createVoltpackMobileHub: CreateVoltpackMobileHubResult
  readonly setVoltpackMobileHubDisplayName: BatterySystemResult
  readonly setVoltpackMobileHubTenant: BatterySystemResult
  readonly setVoltpackMobileHubMacAddress: BatterySystemResult
  readonly setVoltpackMobileHubTags: BatterySystemResult
  readonly createTenant: BatterySystemResult
  readonly addPublicKeyFingerprint: BatterySystemResult
  readonly addFallbackMacAddress: BatterySystemResult
  readonly createESSString: CreateEssStringResult
  readonly createModule: CreateModuleResult
  readonly setModuleLabelId: BatterySystemResult
  readonly setModuleTenant: BatterySystemResult
}

export type MutationCreatePackArgs = {
  id: Scalars['ID']['input']
  packDetails: CreatePackInput
}

export type MutationSetPackDisplayNameArgs = {
  id: Scalars['ID']['input']
  displayName: Scalars['String']['input']
}

export type MutationSetPackTagsArgs = {
  id: Scalars['ID']['input']
  tags: ReadonlyArray<Scalars['String']['input']>
}

export type MutationSetPackTenantArgs = {
  id: Scalars['ID']['input']
  tenant: Scalars['ID']['input']
}

export type MutationSetPackGeoLocationArgs = {
  id: Scalars['ID']['input']
  geoLocation: GeoLocationInput
}

export type MutationCreateCoreArgs = {
  id: Scalars['ID']['input']
  coreDetails: CreateCoreInput
}

export type MutationSetCoreDisplayNameArgs = {
  id: Scalars['ID']['input']
  displayName: Scalars['String']['input']
}

export type MutationSetCoreTagsArgs = {
  id: Scalars['ID']['input']
  tags: ReadonlyArray<Scalars['String']['input']>
}

export type MutationSetCoreTenantArgs = {
  id: Scalars['ID']['input']
  tenant: Scalars['ID']['input']
}

export type MutationSetCoreMacAddressArgs = {
  id: Scalars['ID']['input']
  macAddress: Scalars['String']['input']
}

export type MutationCreateVoltpackMobileHubArgs = {
  id: Scalars['ID']['input']
  voltpackMobileHubDetails: CreateVoltpackMobileHubInput
}

export type MutationSetVoltpackMobileHubDisplayNameArgs = {
  id: Scalars['ID']['input']
  displayName: Scalars['String']['input']
}

export type MutationSetVoltpackMobileHubTenantArgs = {
  id: Scalars['ID']['input']
  tenant: Scalars['ID']['input']
}

export type MutationSetVoltpackMobileHubMacAddressArgs = {
  id: Scalars['ID']['input']
  macAddress: Scalars['String']['input']
}

export type MutationSetVoltpackMobileHubTagsArgs = {
  id: Scalars['ID']['input']
  tags: ReadonlyArray<Scalars['String']['input']>
}

export type MutationCreateTenantArgs = {
  name: Scalars['String']['input']
}

export type MutationAddPublicKeyFingerprintArgs = {
  fingerprint: Scalars['String']['input']
  hardwareSe: Scalars['Boolean']['input']
  tenant: Scalars['String']['input']
}

export type MutationAddFallbackMacAddressArgs = {
  mac: Scalars['String']['input']
}

export type MutationCreateEssStringArgs = {
  stringDetails: CreateEssStringInput
}

export type MutationCreateModuleArgs = {
  moduleDetails: CreateModuleInput
}

export type MutationSetModuleLabelIdArgs = {
  id: Scalars['ID']['input']
  labelId: Scalars['ID']['input']
}

export type MutationSetModuleTenantArgs = {
  id: Scalars['ID']['input']
  tenant: Scalars['ID']['input']
}

export type BatterySystemResult = {
  readonly success: Scalars['Boolean']['output']
}

export type CreateEssStringResult = {
  readonly connectivityId?: Maybe<Scalars['String']['output']>
  readonly macAddress?: Maybe<Scalars['String']['output']>
}

export type CreateModuleResult = {
  readonly connectivityId: Scalars['String']['output']
}

export type CreateVoltpackMobileHubResult = {
  readonly connectivityId: Scalars['String']['output']
}

export enum ContactorKind {
  Subpack = 'Subpack',
  Main = 'Main',
  Charging = 'Charging',
  Cooling = 'Cooling',
}

/**
 * Overview state of a battery system.
 *
 * Null in any field means the retrieval of the value for that field failed.
 *
 * A general note about all energy counter fields (ending with -Ah and -Wh): aggregations of cycled
 * energy (and equivalent cycle count, which is derived from cycled energy) and charge are always done
 * independently between parent systems and subsystems. If either a parent system or subsystem don't
 * include a microcontroller to do the aggregation at their level, then that value is not derived from
 * the respective field of the other system(s) (hierarchically above or below).
 *
 * This decision helps avoiding complexities and ambiguities of energy counting in the face of
 * subsystem swaps.
 *
 * Example: PON *pack* doesn't have its own microcontrollers that aggregate energy counters, therefore,
 * all energy counters in PON pack systems will be null. The spider-mapper or batcloud will **not**
 * sum the respective values from the PON *cores* (comprising the respective pack) to create the values
 * for the pack. On the other hand, the energy counters for PON cores can be non-null because
 * ACO microcontrollers in these cores aggregate energy counters independently.
 */
export type BatterySystemState = NorthvoltState & {
  readonly id: Scalars['ID']['output']
  readonly meta: StateMeta
  readonly idref?: Maybe<IdRef>
  /**
   * Experimental state of a Voltpack Mobile Hub.
   * Not guaranteed to be backwards compatible.
   * Use with caution.
   *
   * Only non-null when mapper is running with environment variable SPIDER_VOLTPACK_MOBILE_HUB == true.
   */
  readonly hubState?: Maybe<HubState>
  /**
   * Position (1-based) of the system within the parent system. Null if this battery system has no
   * parent, or if the position within the parent system is not available.
   */
  readonly position?: Maybe<Scalars['Int']['output']>
  /** If the system is connected to the Internet. */
  readonly isConnected?: Maybe<Scalars['Boolean']['output']>
  /**
   * The type of the battery system based on pack type returned from BMU
   * @deprecated Will become obsolete as pack type will be removed from the BMU
   */
  readonly type?: Maybe<Scalars['String']['output']>
  /** Software versions of components of this system. */
  readonly softwareVersions?: Maybe<ReadonlyArray<SoftwareVersion>>
  /** Hardware versions or revisions of components of this system. */
  readonly hardwareVersions?: Maybe<ReadonlyArray<HardwareVersion>>
  /**
   * State of Charge of the system [percent, 0.0...100.0].
   *
   * Note that "true" stateOfCharge is also not "absolute" in the sense that at 0% SoC may still be
   * battery capacity avaiable. However, pushing beyond these limits could be a safety risk, be outside
   * the battery design specifications, or degrade battery quickly.
   * Thus, "true" stateOfCharge can really be thought of as "maximum operational state-of-charge".
   */
  readonly stateOfCharge?: Maybe<Scalars['Float']['output']>
  /**
   * The operational State of Charge of the system [percent, 0.0...100.0]
   *
   * When a narrower state of charge window is used in an application, this is the adjusted value
   * indicating a 0% to 100% state of charge within the operational range. For example, if the
   * operational range is 5% to 90% "true" state of charge, then operationalStateOfCharge will be
   * 0% when stateOfCharge is 5%, 100% when stateOfCharge is 90%, and report adjusted values in between.
   * Even the main "true" stateOfCharge may be adjusted for a "maximum operational" range.
   *
   * If a system operates outside its operational range, values might be clamped to the 0% to 100%
   * range, or they could be negative or above 100%. Sometimes the system can operate even outside
   * the operational window, or there could be special operational modes available
   * to operate outside the normal limits, but absolute safety limits are always in place.
   */
  readonly operationalStateOfCharge?: Maybe<Scalars['Float']['output']>
  /**
   * ---- BEGIN DEPRECATED FIELDS
   * State of Health of the system [percent, 0.0...100.0]
   *
   * This field is decommissioned and stateOfHealthV2 is used. Don't reuse stateOfHealth because
   * it has stale and bad data in redshift.
   */
  readonly stateOfHealthV2?: Maybe<Scalars['Float']['output']>
  /**
   * System charge capacity estimate [Ah]
   *
   * It is expected to become less than nominal capacity as the battery is used
   */
  readonly capacityEstimateAh?: Maybe<Scalars['Float']['output']>
  /**
   * System resistance estimate [Ohm]
   *
   * It is expected to become higher than the resistance of the new system as the battery is used
   * ---- END DEPRECATED FIELDS
   */
  readonly resistanceEstimateOhms?: Maybe<Scalars['Float']['output']>
  /**
   * State of Health the system [percent, 0.0...100.0]
   *
   * This deprecates the stateOfHealthV2 field with a new lookup table based estimation. Over
   * time it will also support calibration based on cloud estimates and field measurements.
   *
   * Do not use stateOfHealth(1) or stateOfHealthV2. They will be removed in the future.
   */
  readonly stateOfHealthV3?: Maybe<Scalars['Int']['output']>
  /**
   * System charge capacity estimate [Ah]
   *
   * This deprecates capacityEstimateAh which will be removed in the future
   *
   * Note that unit is omitted from the field name to be consistent with the RTDB convention
   * used for this metric.
   */
  readonly chargeCapacityEstimate?: Maybe<Scalars['Float']['output']>
  /**
   * System discharge capacity estimate [Ah]
   *
   * Note that unit is omitted from the field name to be consistent with the RTDB convention
   * used for this metric.
   */
  readonly dischargeCapacityEstimate?: Maybe<Scalars['Float']['output']>
  /**
   * System resistance estimate [mOhm]
   *
   * This deprecates the resistanceEstimateOhms field which will be removed in the future.
   */
  readonly resistanceEstimate?: Maybe<Scalars['Int']['output']>
  /**
   * Equivalent cycles (derived from lifetimeCycledWh) during the life of the battery system.
   * (See general info regarding energy counters in the comment to BatterySystemState.)
   */
  readonly lifetimeEquivalentCycleCount?: Maybe<Scalars['Float']['output']>
  /**
   * Total energy [Wh] charged and discharged during the life of the battery system. (See general info
   * regarding energy counters in the comment to BatterySystemState.)
   */
  readonly lifetimeCycledWh?: Maybe<Scalars['Float']['output']>
  /**
   * Total energy [Wh] charged during the life of the battery system. (See general info regarding
   * energy counters in the comment to BatterySystemState.)
   */
  readonly lifetimeChargedWh?: Maybe<Scalars['Float']['output']>
  /**
   * Total energy [Wh] charged through the charger during the life of the battery system. This does
   * *not* include regenerative charging and charging through an externally powered vehicle when
   * charging does not come from a BMS-controlled charger. This happens in
   * MLE packs. (See general info regarding energy counters in the comment to BatterySystemState.)
   */
  readonly lifetimeChargerChargedWh?: Maybe<Scalars['Float']['output']>
  /**
   * Total energy [Wh] discharged during the life of the battery system. (See general info regarding
   * energy counters in the comment to BatterySystemState.)
   */
  readonly lifetimeDischargedWh?: Maybe<Scalars['Float']['output']>
  /**
   * Total electric charge [Ah] charged during the life of the battery system. (See general info
   * regarding energy counters in the comment to BatterySystemState.)
   */
  readonly lifetimeChargedAh?: Maybe<Scalars['Float']['output']>
  /**
   * Total electric charge [Ah] discharged during the life of the battery system. (See general info
   * regarding energy counters in the comment to BatterySystemState.)
   */
  readonly lifetimeDischargedAh?: Maybe<Scalars['Float']['output']>
  /**
   * Total electric charge [Ah] charged through the charger during the life of the battery system. This
   * does *not* include regenerative charging and charging through an externally powered vehicle when
   * charging does not come from a BMS-controlled charger. This happends in MLE packs.
   * (See general info regarding energy counters in the comment to BatterySystemState.)
   */
  readonly lifetimeChargerChargedAh?: Maybe<Scalars['Float']['output']>
  /** Momental system voltage [V] */
  readonly voltage?: Maybe<Scalars['Float']['output']>
  /**
   * Voltage external to the system [V].
   * The voltage outside of the system the state is reported for.
   */
  readonly externalVoltage?: Maybe<Scalars['Float']['output']>
  /** Momental system current [A]. Could be negative, which means the system is charging. */
  readonly current?: Maybe<Scalars['Float']['output']>
  /** Cell temperature statistics within this battery system. */
  readonly cellTemperatureStats?: Maybe<BatteryModuleMetricStats>
  /** Cell/cellgroup voltage statistics within this battery system. */
  readonly cellVoltageStats?: Maybe<BatteryModuleMetricStats>
  /**
   * Isolation value/limit for system. Isolation with nulls in value and limit fields means isolation is not yet
   * reported.
   */
  readonly isolation?: Maybe<BatteryIsolation>
  /**
   * Information regarding the BMS board if this is a BatterySystemState of a core, or regarding the
   * pack-BMU board if this is a BatterySystemState of a pack.
   */
  readonly bmsBoard?: Maybe<BmsBoardInfo>
  /** MDS board status, TODO: Add description */
  readonly mdsStatus?: Maybe<MdsStatus>
  /**
   * Contactor statuses for the system. Every type of battery system has a fixed set of the contactor kinds, for
   * example:
   *  - core: Subpack.
   *  - pack: Main, Charging, Cooling.
   *
   * Thus, the "contactors" field in a BatterySystemState corresponding to a core should be a single BatteryContactor
   * whose kind is ContactorKind.Main. In a BatterySystemState corresponding to a pack the "contactors" field should be an
   * array of four BatteryContactor objects with different contactor kinds (in unspecified order).
   *
   * The array never has two or more Contactor objects with the same ContactorKind.
   *
   * If the array doesn't include one or several BatteryContactor objects which should normally be reported for the given
   * type of battery system, e. g. the array is empty in the BatterySystemState corresponding to a core, it means that
   * the retrieval of the missing contactor state(s) failed.
   *
   * A BatteryContactor with nulls in both "open" and "stateOfHealth" fields means the corresponding contactor state is not
   * yet reported.
   */
  readonly contactors: ReadonlyArray<BatteryContactor>
  /** Disables triggering CSOH errors */
  readonly isContactorSohErrorDisabled?: Maybe<Scalars['Boolean']['output']>
  /**
   * Indicates whether the system is currently connected to a charging station.
   * This value is only reported on top level systems (e.g. pack). Cores will return null values.
   */
  readonly isChargerConnected?: Maybe<Scalars['Boolean']['output']>
  /**
   * Find the documentation for the variables here:
   * https://systems.northvolt.com/wrapper/batintel/dbt_documentation/#!/source/source.data_lake.stage_telemetry.system_and_string
   */
  readonly soh?: Maybe<Soh>
  readonly aggCore?: Maybe<AggCore>
  readonly sPackState?: Maybe<Scalars['Int']['output']>
  readonly sChcoState?: Maybe<Scalars['Int']['output']>
  readonly chargePowerLimit?: Maybe<Scalars['Float']['output']>
  readonly dischargePowerLimit?: Maybe<Scalars['Float']['output']>
  readonly chargeCurrentLimit?: Maybe<Scalars['Float']['output']>
  readonly dischargeCurrentLimit?: Maybe<Scalars['Float']['output']>
  /**
   * This counter counts the seconds since the OCV correction of the SOC algorithm and gives
   * us an insight into SoC and the algorithm's reliability, among others.
   */
  readonly timeSinceOcvCorrection?: Maybe<Scalars['Int']['output']>
  /**
   * Time measurements
   * TODO: timeSinceOcvCorrection should be refactored into this object
   */
  readonly timeMeasurements?: Maybe<TimeMeasurements>
  /** Diagnostic values for troubleshooting contactor connect/disconnect issues */
  readonly contactorDiagnostic?: Maybe<ContactorDiagnostic>
  /** Ambient eTray temperature. Only available on cores. */
  readonly ambientEtrayTemperature?: Maybe<Scalars['Float']['output']>
  /** Ambient pack temperatures in packs that are equipped with a MEB */
  readonly ambientPackTemperatures?: Maybe<MebTemperatures>
  /** Charging inlet temperatures in packs that are equipped with a MEB */
  readonly chargingInletTemperatures?: Maybe<ChargingInletTemperatures>
  /** DCDC/Supply voltages tracked in the PSB */
  readonly psbVoltages?: Maybe<PsbVoltages>
  /** Operational/battery status of the system */
  readonly operationalState?: Maybe<OperationalState>
  /** Block DCIR variables */
  readonly blockDcir?: Maybe<BlockDcirState>
  /** Momental system current from the secondary sensor [A]. Could be negative, which means the system is charging. */
  readonly currentSecondary?: Maybe<Scalars['Float']['output']>
  /** Will there be charging (for Epiroc) or dynamic enabling (for standalone which sometimes mean charging) */
  readonly chargeMode?: Maybe<Scalars['Boolean']['output']>
  /**
   * The list of SpiderErrors occurred on a TED attached to this BatterySystem. This field is null if
   * this BatterySystem doesn't have a TED itself (e. g., gen-D pack) or retrieval of the Spider errors
   * failed.
   *
   * All SpiderErrors in this list have different shortMessage.
   *
   * The list may be incomplete (truncated for performance reasons), and the order of errors in this
   * list is unspecified.
   */
  readonly spiderErrors?: Maybe<ReadonlyArray<SpiderError>>
  /**
   * Total energy (Wh) charged during life of system and its children.
   * Parent and children values may diverge
   * upon replacement of one or more modules.
   */
  readonly energyThroughputCharge?: Maybe<NodeSummary>
  /**
   * Unique connectivity ID of the system. Starts with 'ST-' for strings (eg cores)
   *  and 'SY-' for systems (eg packs).
   */
  readonly connectivityId?: Maybe<Scalars['String']['output']>
  /**
   * CSB write error counter, used for tracking occurrences in the cloud.
   * The array represents data for 7 or 8 modules depending on VPC type
   */
  readonly csbWriteErrorCounter?: Maybe<ReadonlyArray<Maybe<Scalars['Int']['output']>>>
}

/**
 * Isolation data for a battery system.
 *
 * The value and valueOhms fields are the last _measured_ isolation values, and observedAt is the time
 * when the isolation values were measured (or the best approximation thereof). All three fields are
 * nulls if no isolation measurement is available.
 *
 * The intent of the additional observedAt and wasObservedDuringCurrentPowerCycle fields for this
 * specific value is to hold on to an older measurement even after it stops being reported (for
 * example the passive isolation measurement for cores, that typically stops once the core connects),
 * as long as a measurement was taken during the same power cycle of the pack.
 *
 * For active isolation measurements (for example when a Bender device measures the pack isolation),
 * new measurements will typically be taken during the full operation of the pack, and the observed
 * fields are less relevant.
 *
 * # Note: our current setup often fails to capture any measurement at all for the cores of a pack
 * # in use, since the cores have connected long before the TED starts up. We also don't hold on to
 * # any old value.
 */
export type BatteryIsolation = {
  /** Isolation value [Ohm/V]. */
  readonly value?: Maybe<Scalars['Float']['output']>
  /** Isolation value [Ohm]. */
  readonly valueOhms?: Maybe<Scalars['Float']['output']>
  /** Isolation limit [Ohm] */
  readonly limit?: Maybe<Scalars['Float']['output']>
  /**
   * The time when the isolation measurement device observed the isolation value, or when another
   * microcontroller or computer received the isolation value from the isolation measurement device
   * if the latter doesn't record the time itself.
   *
   * The semantic of this field is different from StateMeta.Observed: observedAt is more like the
   * measurement time, or the best approximation of the measurement time.
   * TODO: rename to 'measuredAt' when/if rethinking the API.
   */
  readonly observedAt?: Maybe<Scalars['Time']['output']>
  /**
   * If the isolation value was observed during the current power cycle.
   *
   * TODO: rename to 'wasMeasuredDuringCurrentPowerCycle' when/if rethinking the API.
   */
  readonly wasObservedDuringCurrentPowerCycle?: Maybe<Scalars['Boolean']['output']>
}

/** Information about a contactor */
export type BatteryContactor = {
  /** The kind of the contactor */
  readonly kind: ContactorKind
  /** If the contactor is open */
  readonly isOpen?: Maybe<Scalars['Boolean']['output']>
  /** State of Health of the contactor [percent, 0.0...100.0] */
  readonly stateOfHealth?: Maybe<Scalars['Float']['output']>
}

/**
 * Statistics about a certain battery cell/cellgroup metric within some battery system. The min, max, and average values
 * are calculated across all the latest metric values recorded from all cells/cellgroups within the battery system, even
 * though these values could be recorded at different times. This means that in a sense, these statistics are both
 * "over battery cell population" of the battery system as well as "over time", although at most one value from each
 * specific cell/cellgroup can be accounted.
 */
export type BatteryModuleMetricStats = {
  /** The minimum value recorded */
  readonly min?: Maybe<Scalars['Float']['output']>
  /** The maximum value recorded */
  readonly max?: Maybe<Scalars['Float']['output']>
  /** The average of all values */
  readonly mean?: Maybe<Scalars['Float']['output']>
  /** The location of the cell/cellgroup where the minimum value was recorded within the battery system */
  readonly minLoc?: Maybe<BatteryModuleSensorRelativeLocation>
  /** The location of the cell/cellgroup where the maximum value was recorded within the battery system */
  readonly maxLoc?: Maybe<BatteryModuleSensorRelativeLocation>
}

/** A location of some sensor within some module within some battery system. */
export type BatteryModuleSensorRelativeLocation = {
  /**
   * The sequence of subsystem positions (1-based ordinal numbers) determining the location of the leaf battery system
   * (to which the located sensor belongs) in the hierarchical structure of battery systems. The first number is the
   * position of the target child subsystem in the battery system for which this BatteryModuleSensorRelativeLocation is
   * defined. The second number in the list is the position of the target child subsystem in the battery system determined
   * by the first number, and so on.
   *
   * An empty list [] means the battery system (for which this BatteryModuleSensorRelativeLocation is defined) is a leaf
   * system (a core or a rack) itself: it contains modules directly, not other subsystems.
   *
   * Note that subsystem ordinal numbers start at 1, not 0.
   */
  readonly subSystemPath: ReadonlyArray<Scalars['Int']['output']>
  /**
   * The position (1-based ordinal number) within the leaf battery system (determined by subSystemPath) of the module to
   * which the located sensor belongs.
   *
   * Note that module positions start at 1, not 0, i. e. a module position is a value between 1 and 8 (inclusive) for
   * an eight-module core.
   */
  readonly module: Scalars['Int']['output']
  /**
   * The ordinal number (1-based) of the located sensor within the module.
   *
   * Note that sensor ordinal numbers start at 1, not 0. The end ordinal (maximum value) depends on the sensor type
   * (temperature or voltage), the generation, and the kind of the battery system to which the located sensor belongs.
   */
  readonly sensor: Scalars['Int']['output']
}

/**
 * Information about BMS board of a core or about a pack-BMU board of a pack (the pack-BMU is more of
 * a box (STW ESX), but there is a board inside).
 */
export type BmsBoardInfo = {
  /**
   * The voltage of the battery connected to the board.
   *
   * In BMSBoardInfo of a core, this the low-voltage lithium polymer (LiPo) battery used to power
   * real-time clock on the BMS board. If the battery is not connected (isBatteryConnected is false),
   * the value of this field might be null, or 0.
   */
  readonly batteryVoltage?: Maybe<Scalars['Float']['output']>
  /** Whether the board's battery is charging. */
  readonly isBatteryCharging?: Maybe<Scalars['Boolean']['output']>
  /** Whether the battery is connected to the board. (The battery may be physically absent.) */
  readonly isBatteryConnected?: Maybe<Scalars['Boolean']['output']>
  /**
   * Cumulative time adjustments made to the BMS board RTC clock when adjusting its time to the
   * TED time when it is synchronized from NTP.
   *
   * This field is only present in a BMSBoardInfo of a core, not pack.
   */
  readonly cumulativeTimeAdjustment?: Maybe<Scalars['Milliseconds']['output']>
  /** The supply voltage to the board. Typically around 24 V. */
  readonly supplyVoltage?: Maybe<Scalars['Float']['output']>
  /** The chip temperatures on the board. */
  readonly chipTemperatures?: Maybe<ReadonlyArray<ChipTemperature>>
}

export type ChipTemperature = {
  /** Name of the chip, for example, "ACO". */
  readonly chip: Scalars['String']['output']
  /** The chip temperature [°C]. */
  readonly temperature: Scalars['Float']['output']
}

/** Version information for the software of a component of a battery system. */
export type SoftwareVersion = {
  /** Software name. */
  readonly software: Scalars['String']['output']
  /** Software version. */
  readonly version: Scalars['String']['output']
}

/** Version information for the hardware of a component of a battery system. */
export type HardwareVersion = {
  /** Hardware name. */
  readonly hardware: Scalars['String']['output']
  /** Hardware version or revision. */
  readonly version: Scalars['String']['output']
}

/** TODO: Add documentation */
export type AggCore = {
  readonly timeAtEnd?: Maybe<Scalars['Time']['output']>
  readonly currentMax?: Maybe<Scalars['Float']['output']>
  readonly currentMin?: Maybe<Scalars['Float']['output']>
  readonly currentAvg?: Maybe<Scalars['Float']['output']>
  readonly currentStdDev?: Maybe<Scalars['Float']['output']>
  readonly currentMaxStep?: Maybe<Scalars['Float']['output']>
  readonly voltageMax?: Maybe<Scalars['Float']['output']>
  readonly voltageMin?: Maybe<Scalars['Float']['output']>
  readonly voltageAvg?: Maybe<Scalars['Float']['output']>
  readonly voltageStdDev?: Maybe<Scalars['Float']['output']>
  readonly voltageMaxStep?: Maybe<Scalars['Float']['output']>
  readonly acoUpTimeS?: Maybe<Scalars['Int']['output']>
  readonly acoTotalUpTimeS?: Maybe<Scalars['Int']['output']>
  readonly currentFlowingTimeS?: Maybe<Scalars['Int']['output']>
  readonly coulombCountCoulombs?: Maybe<Scalars['Float']['output']>
}

export type TimeMeasurements = {
  /** Current BMS time as unix timestamp */
  readonly bmsTime?: Maybe<Scalars['Int']['output']>
  /** Last time since cell balancing as unix timestamp */
  readonly lastTimeSinceCellBalancing?: Maybe<Scalars['Int']['output']>
}

/** Diagnostic information to troubleshoot contactor disconnections */
export type ContactorDiagnostic = {
  /** Disconnection reason. The enum refers to the connection state machine */
  readonly disconnectionReason?: Maybe<Scalars['Int']['output']>
  /** Flag to indicate to preheat functionality that the system is disconnected to rotate cores */
  readonly rotationOngoing?: Maybe<Scalars['Boolean']['output']>
  /** Number of cores that are enabled but prevented from connecting to the HV bus due to voltage difference */
  readonly nrCoresPreventedFromConnecting?: Maybe<Scalars['Int']['output']>
  /** B Value is a static constant used in the calibration algorithm */
  readonly bValue?: Maybe<Scalars['Float']['output']>
  /** Calibration Conditions are met flag */
  readonly calibrationConditionsMet?: Maybe<Scalars['Boolean']['output']>
  /** Calculated C Values for negative contactor */
  readonly calculatedCValueNegative?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  /** Calculated C Values for positive contactor */
  readonly calculatedCValuePositive?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  /** Calculated C Values for precharge contactor */
  readonly calculatedCValuePrecharge?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  /** Average C Value for negative contactor */
  readonly averageCValueNegative?: Maybe<Scalars['Float']['output']>
  /** Average C Value for positive contactor */
  readonly averageCValuePositive?: Maybe<Scalars['Float']['output']>
  /** Average C Value for precharge contactor */
  readonly averageCValuePrecharge?: Maybe<Scalars['Float']['output']>
  /** Calibration temperature */
  readonly calibrationTemperature?: Maybe<Scalars['Float']['output']>
  /** Calibration Voltage for negative contactor */
  readonly calibrationVoltageNegative?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  /** Calibration Voltage for positive contactor */
  readonly calibrationVoltagePositive?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  /** Calibration Voltage for precharge contactor */
  readonly calibrationVoltagePrecharge?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  /** Calibration Timings for negative contactor in milliseconds */
  readonly calibrationTimingsNegative?: Maybe<ReadonlyArray<Maybe<Scalars['Int']['output']>>>
  /** Calibration Timings for positive contactor in milliseconds */
  readonly calibrationTimingsPositive?: Maybe<ReadonlyArray<Maybe<Scalars['Int']['output']>>>
  /** Calibration Timings for precharge contactor in milliseconds */
  readonly calibrationTimingsPrecharge?: Maybe<ReadonlyArray<Maybe<Scalars['Int']['output']>>>
  /**
   * Status of the state machine for calibration of welded contactor diagnostics.
   * Possible values are:
   *   0: CONTACTOR_CALIB_NOT_RUN
   *   1: CONTACTOR_CALIB_NEEDED
   *   2: CONTACTOR_CALIB_OK
   *   3: CONTACTOR_CALIB_INITIATED
   *   4: CONTACTOR_CALIB_ABORTED
   *   5: CONTACTOR_CALIB_INTERNAL_ERROR
   */
  readonly calibrationStatus?: Maybe<Scalars['Int']['output']>
  /** Last Attempted Calibration as unix timestamp */
  readonly lastAttemptedCalibrationTime?: Maybe<Scalars['Int']['output']>
  /** Last Successful Calibration as unix timestamp */
  readonly lastSuccessfulCalibrationTime?: Maybe<Scalars['Int']['output']>
  /** KL30 Voltage before closing negative contactor */
  readonly milliVoltageBeforeClosingNegative?: Maybe<Scalars['Int']['output']>
  /** KL30 Voltage before closing positive contactor */
  readonly milliVoltageBeforeClosingPositive?: Maybe<Scalars['Int']['output']>
  /** KL30 Voltage before closing precharge contactor */
  readonly milliVoltageBeforeClosingPrecharge?: Maybe<Scalars['Int']['output']>
  /** KL30 Voltage during closing negative contactor */
  readonly milliVoltageDuringClosingNegative?: Maybe<Scalars['Int']['output']>
  /** KL30 Voltage during closing positive contactor */
  readonly milliVoltageDuringClosingPositive?: Maybe<Scalars['Int']['output']>
  /** KL30 Voltage during closing precharge contactor */
  readonly milliVoltageDuringClosingPrecharge?: Maybe<Scalars['Int']['output']>
  /** KL30 Voltage after closing negative contactor */
  readonly milliVoltageAfterClosingNegative?: Maybe<Scalars['Int']['output']>
  /** KL30 Voltage after closing positive contactor */
  readonly milliVoltageAfterClosingPositive?: Maybe<Scalars['Int']['output']>
  /** KL30 Voltage after closing precharge contactor */
  readonly milliVoltageAfterClosingPrecharge?: Maybe<Scalars['Int']['output']>
  /** Estimated contactor temperatures (combined) */
  readonly estimatedContactorTemperature?: Maybe<Scalars['Float']['output']>
  /** Nominal (estimated) closing time for positive contactor */
  readonly nominalClosingTimePositive?: Maybe<Scalars['Float']['output']>
  /** Nominal (estimated) closing time for negative contactor */
  readonly nominalClosingTimeNegative?: Maybe<Scalars['Float']['output']>
  /** Actual (estimated) closing time for positive contactor */
  readonly actualClosingTimePositive?: Maybe<Scalars['Int']['output']>
  /** Actual (estimated) closing time for negative contactor */
  readonly actualClosingTimeNegative?: Maybe<Scalars['Int']['output']>
  /** Actual (estimated) closing time for precharge contactor */
  readonly actualClosingTimePrecharge?: Maybe<Scalars['Int']['output']>
  /** Diagnostic status NOK counter */
  readonly diagnosticStatusNOKCounter?: Maybe<Scalars['Int']['output']>
  /** Flag to enable or disable contactor diagnostic */
  readonly diagnosticDisabled?: Maybe<Scalars['Boolean']['output']>
  /** Flag to activate calibration */
  readonly calibrationRequested?: Maybe<Scalars['Boolean']['output']>
  /** Flag to abort calibration */
  readonly calibrationAbortRequested?: Maybe<Scalars['Boolean']['output']>
  /**
   * Counters for occurrences of positive/negative contactor weldings. If two consecutive occurrences
   * are detectted, the contactor shall be considered welded.
   */
  readonly weldedPositiveContactorCounter?: Maybe<Scalars['Int']['output']>
  readonly weldedNegativeContactorCounter?: Maybe<Scalars['Int']['output']>
}

/**
 * MEB Ambient temperature data
 * Note that these readings are only relevant for products with a MEB and only on the pack level.
 */
export type MebTemperatures = {
  /** DCDC Casing NTC thermistor temperature reading, degree Celsius */
  readonly DCDCCasingNTCTemperature?: Maybe<Scalars['Float']['output']>
  /** PSB Ambient NTC thermistor temperature reading, degree Celsius */
  readonly PSBAmbientNTCTemperature?: Maybe<Scalars['Float']['output']>
  /** LV battery NTC thermistor temperature reading, degree Celsius */
  readonly LVBatteryTemperature?: Maybe<Scalars['Float']['output']>
}

/** MDS, TODO: Add documentation */
export type MdsStatus = {
  /** Is there an MDS board installed? */
  readonly mdsBoardInstalled?: Maybe<Scalars['Boolean']['output']>
  /** Is MEB DCDC enforced? */
  readonly mebDcdcEnforced?: Maybe<Scalars['Boolean']['output']>
  /** Is TMS DCDC supply detection enabled? */
  readonly tmsDcdcSupplyDetection?: Maybe<Scalars['Boolean']['output']>
  /** Is TMS DCDC enforced? */
  readonly tmsDcdcEnforced?: Maybe<Scalars['Boolean']['output']>
}

/** VC-VCCU charging inlet temperatures, degree Celsius */
export type ChargingInletTemperatures = {
  /** DC temperature inlet pin, PTC1 */
  readonly vcvccuPtc1Temperature?: Maybe<Scalars['Int']['output']>
  /** DC temperature inlet pin, PTC2 */
  readonly vcvccuPtc2Temperature?: Maybe<Scalars['Int']['output']>
}

/**
 * Operational state of the battery system. Note that it's represented as two different
 * enums derived from different parts of a battery system. batteryState (colloquially referred
 * to as "bast") is an enum defined in the pBMU diagnostic interface, and as such, is only
 * available on the pack level in systems that have a MEB+pBMU. operationalState is a BMS pack/core
 * level state machine.
 * TODO: More research is needed to understand the overlap between these two state machines
 */
export type OperationalState = {
  /**
   * Operational status.
   * TODO: Dig up link to enum definition in BMS code base.
   * Maybe TODO: Replicate enum in GQL schema?
   */
  readonly operationalStatus?: Maybe<Scalars['Int']['output']>
  /**
   * Battery state
   * Ref: https://github.com/northvolt/BMS/blob/65a0aba191ac73c0bdbf78d7388028ed44cc54e5/BMU/source/APPL/bast.h#L29-L50
   * Maybe TODO: Replicate enum in GQL schema?
   */
  readonly batteryState?: Maybe<Scalars['Int']['output']>
}

/**
 * Various voltages tracked in the PSB
 * Note: Reported values are in millivoltages
 */
export type PsbVoltages = {
  readonly dcdcSupplyVoltage?: Maybe<Scalars['Int']['output']>
  readonly diagnosticsSupplyVoltage?: Maybe<Scalars['Int']['output']>
  readonly batterySupplyVoltage?: Maybe<Scalars['Int']['output']>
  readonly vehicleKL30Voltage?: Maybe<Scalars['Int']['output']>
}

/**
 * Block DCIR variables
 * Note that a "block" in this context refers to a group of cells in a battery system,
 * sometimes also referred to as "cell groups".
 * On industrial cores, there are 28 cells per block, 24 blocks per module and 7 or 8 modules
 * per core depending on the product type.
 */
export type BlockDcirState = {
  /** Number of samples in the DCIR measurement */
  readonly numSamples?: Maybe<ReadonlyArray<Maybe<Scalars['Int']['output']>>>
  /** DCIR values in milliohms */
  readonly values?: Maybe<ReadonlyArray<Maybe<Scalars['Int']['output']>>>
  /** Clears the memories in the cell block DCIR module */
  readonly resetMemories?: Maybe<Scalars['Boolean']['output']>
  /** Lower cell block voltage limit for when to allow block DCIR estimates */
  readonly udiffLimit?: Maybe<Scalars['Float']['output']>
  /** Lower core current limit for when to allow block DCIR estimates */
  readonly idiffLimit?: Maybe<Scalars['Float']['output']>
  /** Smoothing factor for exponential smoothing for block DCIR estimates */
  readonly smoothingFactor?: Maybe<Scalars['Float']['output']>
}

/** Variables used for SoH estimation in the cloud. TODO: Better documentation needed. */
export type Soh = {
  readonly validatedStepResistance?: Maybe<Scalars['Float']['output']>
  readonly nrStepResistanceEstimatesInBin?: Maybe<ReadonlyArray<Maybe<Scalars['Int']['output']>>>
  readonly voltageDiff?: Maybe<Scalars['Float']['output']>
  readonly currentDiff?: Maybe<Scalars['Float']['output']>
  readonly latestStepResistanceEstimate?: Maybe<Scalars['Float']['output']>
  readonly latestResistanceEstimate?: Maybe<Scalars['Float']['output']>
  readonly sohStepResistanceBelowMinusTenDeg?: Maybe<Scalars['Float']['output']>
  readonly sohStepResistanceMinusTenToZeroDeg?: Maybe<Scalars['Float']['output']>
  readonly sohStepResistanceZeroToTenDeg?: Maybe<Scalars['Float']['output']>
  readonly sohStepResistanceTenToTwentyfiveDeg?: Maybe<Scalars['Float']['output']>
  readonly sohStepResistanceAboveTwentyfiveDeg?: Maybe<Scalars['Float']['output']>
  readonly ewmFiltResistance1?: Maybe<Scalars['Float']['output']>
  readonly ewmFiltResistance2?: Maybe<Scalars['Float']['output']>
  readonly sohMedianFilter1Values?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  readonly sohMedianFilter2Values?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  readonly sohMedianFilter1Ages?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  readonly sohMedianFilter2Ages?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>
  readonly tSOHAverageTemperature?: Maybe<Scalars['Float']['output']>
  readonly capacityReference1SOH?: Maybe<Scalars['Float']['output']>
  readonly capacityReference2SOH?: Maybe<Scalars['Float']['output']>
  readonly nrTotalSOHEstimates1?: Maybe<Scalars['Int']['output']>
  readonly nrTotalSOHEstimates2?: Maybe<Scalars['Int']['output']>
  readonly capacityBasedPercent?: Maybe<Scalars['Float']['output']>
  readonly estimatedCapacity?: Maybe<Scalars['Float']['output']>
  readonly capacityLatestEstimate?: Maybe<Scalars['Float']['output']>
  readonly estimatedResistance?: Maybe<Scalars['Float']['output']>
  readonly vpcAgedUseableCapacity?: Maybe<Scalars['Int']['output']>
}

/**
 * NodeSummary holds value for an object and summary statistics of its children
 * Deprecated (reason: "Values are reported directly as floats instead")
 */
export type NodeSummary = {
  readonly value: Scalars['Float']['output']
  readonly children?: Maybe<SummaryStats>
}

/** SummaryStats summarizes statistics for a collection of values */
export type SummaryStats = {
  readonly min: Scalars['Float']['output']
  readonly max: Scalars['Float']['output']
  readonly mean: Scalars['Float']['output']
}

/** An overview of the current exceptions reported by a system. */
export type ExceptionSummary = {
  readonly description?: Maybe<Scalars['String']['output']>
  readonly warningCount?: Maybe<Scalars['Int']['output']>
  readonly alarmCount?: Maybe<Scalars['Int']['output']>
}

export enum CommandStatus {
  New = 'new',
  Accepted = 'accepted',
  Rejected = 'rejected',
  InProgress = 'inProgress',
  Done = 'done',
  Failed = 'failed',
}

/**  NorthvoltCommand is the interface all command objects implement.  */
export type Command = {
  readonly id: Scalars['ID']['output']
  readonly target: Scalars['ID']['output']
  readonly actor: Scalars['ID']['output']
  readonly meta: CommandMeta
}

/**  CommandMeta is the meta information all command objects provide.  */
export type CommandMeta = {
  /**  type is the GraphQL type of the object.  */
  readonly type: Scalars['String']['output']
  /** The GraphQL interfaces that the type of the object implements. */
  readonly ifaces: ReadonlyArray<Scalars['String']['output']>
  /** The current status of the command. */
  readonly status: CommandStatus
  /** When the command was created. */
  readonly created: Scalars['Time']['output']
  /** When the command was last updated. */
  readonly updated?: Maybe<Scalars['Time']['output']>
  /** Last point in time that the command should be executed. */
  readonly expires?: Maybe<Scalars['Time']['output']>
}

/** A command that can be used for simple testing. */
export type TestCommand = Command & {
  readonly id: Scalars['ID']['output']
  readonly target: Scalars['ID']['output']
  readonly actor: Scalars['ID']['output']
  readonly meta: CommandMeta
  /** A message that should be received on the other side. */
  readonly message: Scalars['String']['output']
}

/** A command that can be used to check if a device is currently listening. */
export type PingCommand = Command & {
  readonly id: Scalars['ID']['output']
  readonly target: Scalars['ID']['output']
  readonly actor: Scalars['ID']['output']
  readonly meta: CommandMeta
}

/** A condition that must be fulfilled in order for something to happen. */
export type Condition = {
  /** The name of the condition */
  readonly name: Scalars['String']['output']
  /**
   * The action that must be performed in order to
   * fulfill the condition.
   */
  readonly action: Scalars['String']['output']
  /** True if the condition is fulfilled. */
  readonly ok: Scalars['Boolean']['output']
}

export type DummyLocation = NorthvoltIdentity & {
  readonly id: Scalars['ID']['output']
  readonly idref?: Maybe<IdRef>
  readonly meta: IdentityMeta
  readonly displayName?: Maybe<Scalars['String']['output']>
  readonly name?: Maybe<Scalars['String']['output']>
}

export type Computer = {
  readonly id: Scalars['ID']['output']
  readonly meta: IdentityMeta
  readonly idref?: Maybe<IdRef>
  readonly displayName?: Maybe<Scalars['String']['output']>
}

/**
 * EdgeGateway defines all gateway computers in the factory and
 * battery packs which deliver telemetry data and interact with
 * one or more devices they are connected with.
 */
export type EdgeGateway = Computer &
  NorthvoltIdentity & {
    readonly id: Scalars['ID']['output']
    readonly idref?: Maybe<IdRef>
    readonly meta: IdentityMeta
    readonly displayName?: Maybe<Scalars['String']['output']>
    /** The machine that the edge gateway sits in or is connected to. */
    readonly host?: Maybe<
      | BatteryMasterUnit
      | BatteryModule
      | BatterySystem
      | DummyLocation
      | EdgeGateway
      | LegacyLadybugIdentity
    >
    /**  Name of the vendor of the device  */
    readonly vendor?: Maybe<Scalars['String']['output']>
    /**  Model name of the device.  */
    readonly model?: Maybe<Scalars['String']['output']>
    /**  Last reported state of the EdgeGateway  */
    readonly state?: Maybe<EdgeGatewayState>
  }

/**
 * Contains relevant parameters for provisioning the device.
 * We don't store this in the shadow to avoid that the provisioning
 * service has to examine all shadow updates for all devices just
 * to find the handful of provisioning requests.
 */
export type EdgeGatewayDeviceInfo = {
  /**
   * Balena device identifier.
   * Only applicable for Balena devices.
   */
  readonly balenaUUID: Scalars['ID']['output']
  /**
   * Balena application name.
   * Only applicable for Balena devices.
   */
  readonly balenaApp: Scalars['String']['output']
  /**
   * Balena unique application ID.
   * Only applicable for Balena devices.
   */
  readonly balenaAppID: Scalars['String']['output']
  /**
   * Balena device name.
   * Only applicable for Balena devices.
   */
  readonly balenaName: Scalars['String']['output']
  /** Name of the unit. */
  readonly unitName: Scalars['String']['output']
  /** Name of the device. */
  readonly deviceName: Scalars['String']['output']
  /** Optional description of the device. */
  readonly desc?: Maybe<Scalars['String']['output']>
  /** FBS of the switch the gateway is connected to. */
  readonly switchName: Scalars['String']['output']
  /** Type of the switch the gateway is connected to. */
  readonly switchType: Scalars['String']['output']
  /** Name of the port on the switch the gateway is connected to. */
  readonly portName: Scalars['String']['output']
  /**
   * List of network interface connections that should
   * be configured on the device.
   */
  readonly connections?: Maybe<ReadonlyArray<Maybe<EdgeGatewayNetworkConn>>>
}

export type EdgeGatewayNetworkConn = {
  /** Name of the network config, e.g. "it-vlan", "dhcp", ... */
  readonly name: Scalars['String']['output']
  /** Optional description of the connection. */
  readonly desc?: Maybe<Scalars['String']['output']>
  /**
   * Name of the network interface. Can be a physical interface
   * like 'eth0' or a logical name like 'vlan' or 'dhcp'.
   */
  readonly dev: Scalars['String']['output']
  /**
   * IP address of the interface including the netmask, e.g. 1.2.3.4/26
   * or 2001:db8::1/64. Use 'auto' to configure the address via DHCP or
   * IPv6 auto-configuration.
   */
  readonly addr: Scalars['String']['output']
  /** VLAN ID of the interface for tagged traffic. */
  readonly vlan: Scalars['Int']['output']
}

/**  EdgeGatewayState is the status of the gateway computer itself.  */
export type EdgeGatewayState = NorthvoltState & {
  readonly id: Scalars['ID']['output']
  readonly idref?: Maybe<IdRef>
  readonly meta: StateMeta
  readonly metrics?: Maybe<EdgeGatewayMetrics>
}

export type EdgeGatewayMetrics = {
  readonly pub?: Maybe<EdgeGatewayPublisherMetrics>
  readonly sys?: Maybe<EdgeGatewaySystemMetrics>
  readonly gpu?: Maybe<ReadonlyArray<Maybe<EdgeGatewayGpuMetrics>>>
}

export type EdgeGatewayPublisherMetrics = {
  /**  total is the total number of messages published.  */
  readonly total: Scalars['Int']['output']
  /**  buffered is the number of messages stored in the on-disk buffer.  */
  readonly buffered: Scalars['Int']['output']
  /**  errors is the number of messages with errors.  */
  readonly errors: Scalars['Int']['output']
  /**  ids is the list of ids for which messages were published.  */
  readonly ids?: Maybe<ReadonlyArray<Scalars['String']['output']>>
}

export type EdgeGatewaySystemMetrics = {
  /**  appVersion is the version of the mapper application.  */
  readonly appVersion: Scalars['String']['output']
  /**  appUptime is the time the application has been running in seconds.  */
  readonly appUptime: Scalars['Int']['output']
  /**  balenaApp is the name of the Balena application.  */
  readonly balenaApp: Scalars['String']['output']
  /**  balenaUUID is the UUID of the Balena device.  */
  readonly balenaUUID: Scalars['String']['output']
  /**  osVersion is the version of the operating system, e.g. Linux kernel  */
  readonly osVersion: Scalars['String']['output']
  /**  arch is the system architecture, e.g. linux/arm.  */
  readonly arch: Scalars['String']['output']
  /**  cpuCount is the number of CPU cores (phys+virt) as reported by /proc/cpuinfo.  */
  readonly cpuCount: Scalars['Int']['output']
  /**  cpuModel is the model of the CPU as reported by /proc/cpuinfo.  */
  readonly cpuModel: Scalars['String']['output']
  /**  goVersion is the version of the Go runtime.  */
  readonly goVersion: Scalars['String']['output']
  /**  goos is the operating system of the Go runtime.  */
  readonly goos: Scalars['String']['output']
  /**  goarch is the system architecture of the Go runtime.  */
  readonly goarch: Scalars['String']['output']
  /**  gomaxprocs is the maximum number of CPUs by the Go runtime.  */
  readonly gomaxprocs: Scalars['Int']['output']
  /**  godebug is the setting of the GODEBUG environment variable.  */
  readonly godebug: Scalars['String']['output']
  /**  gogc is the setting of the GOGC environment variable which controls the Go garbage collector.  */
  readonly gogc: Scalars['String']['output']
  /**  intSize is the size of an int value in bits: 32 or 64.  */
  readonly intSize: Scalars['Int']['output']
  /**  load1Min is the 1min load average as reported by 'uptime'.  */
  readonly load1Min: Scalars['Float']['output']
  /**  load5Min is the 5min load average as reported by 'uptime'.  */
  readonly load5Min: Scalars['Float']['output']
  /**  load15Min is the 15min load average as reported by 'uptime'.  */
  readonly load15Min: Scalars['Float']['output']
  /**  memAvail is the amount of available memory in bytes.  */
  readonly memAvail: Scalars['Int']['output']
  /**  memFree is the amount of free memory in bytes.  */
  readonly memFree: Scalars['Int']['output']
  /**  memSwap is the amount of used swap memory in bytes.  */
  readonly memSwap: Scalars['Int']['output']
  /**  netEth0 is the primary ethernet interface.  */
  readonly netEth0: NetworkInterfaceMetrics
  /**  netEth1 is the secondary ethernet interface.  */
  readonly netEth1: NetworkInterfaceMetrics
  /**  netWlan0 is the primary WLAN interface.  */
  readonly netWlan0: NetworkInterfaceMetrics
  /**  netWwan0 is the primary LTE interface.  */
  readonly netWwan0: NetworkInterfaceMetrics
  /**  uptime is time since the last reboot in seconds.  */
  readonly uptime: Scalars['Int']['output']
}

/**  NetworkInterfaceMetrics contains metrics about a network interface.  */
export type NetworkInterfaceMetrics = {
  /**  Name is the name of the interface on the system.  */
  readonly Name: Scalars['String']['output']
  /**  Rx is the number of bytes received.  */
  readonly Rx: Scalars['Int64']['output']
  /**  Tx is the number of bytes sent.  */
  readonly Tx: Scalars['Int64']['output']
}

/**  EdgeGatewayGPUMetrics contains metrics about a GPU.  */
export type EdgeGatewayGpuMetrics = {
  /**  name is the name of the GPU.  */
  readonly name: Scalars['String']['output']
  /**  deviceNumber is the device number of this GPU on the system.  */
  readonly deviceNumber: Scalars['Int']['output']
  /**  load is the GPU usage in percent.  */
  readonly load: Scalars['Float']['output']
  /**  memUsed is the memory usage in percent.  */
  readonly memUsed: Scalars['Float']['output']
  /**  temperature is the temperature of the GPU in celsius.  */
  readonly temperature: Scalars['Float']['output']
  /**  power is the draw of the GPU in watt.  */
  readonly power: Scalars['Float']['output']
}

/** Geolocation */
export type GeoLocation = {
  /** A human readable name describing the location */
  readonly name?: Maybe<Scalars['String']['output']>
  /** Latitude */
  readonly latitude?: Maybe<Scalars['Float']['output']>
  /** Longitude */
  readonly longitude?: Maybe<Scalars['Float']['output']>
}

/**  NorthvoltIdentity is the interface all identity objects implement.  */
export type NorthvoltIdentity = {
  readonly id: Scalars['ID']['output']
  readonly idref?: Maybe<IdRef>
  readonly meta: IdentityMeta
  /** The human readable display name of the system (aka "Norhtvolt ID"). */
  readonly displayName?: Maybe<Scalars['String']['output']>
}

/**  IdentityMeta is the meta information all identity objects provide.  */
export type IdentityMeta = {
  /** type is the GraphQL type of the object. */
  readonly type: Scalars['String']['output']
  /** The GraphQL interfaces that the type of the object implements. */
  readonly ifaces: ReadonlyArray<Scalars['String']['output']>
  /** created is the time when the identity was created. */
  readonly created: Scalars['Time']['output']
  /** updated is the time when the identity was updated. */
  readonly updated?: Maybe<Scalars['Time']['output']>
  /** tenant is the identifier of the tenant to which this identity belongs. */
  readonly tenant: Scalars['ID']['output']
  /**
   * If resolved within another Identity as part of a link-map, this value
   * resolves to the key for the link from the perspective of the other part.
   */
  readonly link?: Maybe<Scalars['String']['output']>
  /** The ID of the trace that last affected this identity. */
  readonly traceID?: Maybe<Scalars['ID']['output']>
}

/** IDRef defines additional attributes for identifying a unit or a device. */
export type IdRef = {
  /** balenaApp is the name of the Balena application. */
  readonly balenaApp?: Maybe<Scalars['String']['output']>
  /** balenaUUID is the UUID of the Balena device. */
  readonly balenaUUID?: Maybe<Scalars['String']['output']>
  /**
   * hwAddr contains the MAC address of the primary ethernet interface.
   * It is used to look up a device by MAC address instead of their Northvolt ID.
   * It must be unique since MAC addresses are supposed to be globally unique.
   */
  readonly hwAddr?: Maybe<Scalars['String']['output']>
  /**
   * hostName contains the host name of the gateway.
   * It is used to look up a device by Host name instead of their Northvolt ID.
   * It must be unique since Host name must be unique for each gateway.
   */
  readonly hostName?: Maybe<Scalars['String']['output']>
  /**
   * serialNr contains the vendor specific serial number of the device.
   * It can be used as an additional parameter to identify a device. Since
   * the vendor controls the serial numbers there is no expectation of uniqueness.
   */
  readonly serialNr?: Maybe<Scalars['String']['output']>
}

/**  LadybugIdentityObject  */
export type LegacyLadybugIdentity = NorthvoltIdentity & {
  readonly id: Scalars['ID']['output']
  readonly idref?: Maybe<IdRef>
  readonly meta: IdentityMeta
  readonly displayName?: Maybe<Scalars['String']['output']>
  readonly fbs: Scalars['String']['output']
  readonly supplier?: Maybe<Scalars['String']['output']>
  readonly name?: Maybe<Scalars['String']['output']>
  readonly model?: Maybe<Scalars['String']['output']>
  readonly data?: Maybe<Scalars['Map']['output']>
}

export type GqlServerStateResult = {
  readonly gitTag: Scalars['String']['output']
}

/**  NorthvoltState is the interface all state objects implement.  */
export type NorthvoltState = {
  readonly id: Scalars['ID']['output']
  readonly idref?: Maybe<IdRef>
  readonly meta: StateMeta
}

/**  StateMeta is the meta information all state objects provide.  */
export type StateMeta = {
  /** The type of the object. */
  readonly type: Scalars['String']['output']
  /** The GraphQL interfaces that the type of the object implements. */
  readonly ifaces: ReadonlyArray<Scalars['String']['output']>
  /** The identity of the sender. */
  readonly sender: Scalars['ID']['output']
  /** The FBS of the sender. */
  readonly senderFBS?: Maybe<Scalars['String']['output']>
  /** The unique id of the message as assigned by the sender. */
  readonly msgID: Scalars['ID']['output']
  /** The tenant to which the state belongs. */
  readonly tenant: Scalars['ID']['output']
  /**
   * The time when the state change was observed. Preferably, this is
   * the timestamp at which the PLC recorded the state change. Otherwise, it is
   * the timestamp the state change was received by the gateway.
   */
  readonly observed: Scalars['Time']['output']
  /**
   * The time when the gateway processed the state change
   * notification from the PLC.
   */
  readonly mapped: Scalars['Time']['output']
  /**
   * The time when the state change was first scheduled
   * for publishing by the gateway. There is no guarantee that the
   * state change was actually published at this point.
   */
  readonly published: Scalars['Time']['output']
  /** Used to decide how the state should be stored. */
  readonly storageKey: Scalars['String']['output']
}

export type TelemetryQuery = {
  /** The start of the range in which to query the telemetry data. */
  readonly from: Scalars['Time']['input']
  /** The end of the range in which to query the telemetry data. */
  readonly to?: InputMaybe<Scalars['Time']['input']>
  /** The metrics of the data that should be retrieved. */
  readonly metrics: ReadonlyArray<Scalars['String']['input']>
  /**
   * https://prometheus.io/docs/prometheus/latest/querying/functions/#aggregation_over_time
   * Type of aggregation. Supported values:
   * "avg": the average value of all points in the specified interval.
   * "min": the minimum value of all points in the specified interval.
   * "max": the maximum value of all points in the specified interval.
   * "sum": the sum of all values in the specified interval.
   * "count: the count of all values in the specified interval.
   * "stddev": the population standard deviation of the values in the specified interval.
   * "stdvar": the population standard variance of the values in the specified interval.
   * Default value: "avg".
   */
  readonly aggregation?: InputMaybe<Scalars['String']['input']>
  /** Additional labels that should be used to query the data. */
  readonly labels?: InputMaybe<ReadonlyArray<Scalars['Map']['input']>>
  /**
   * Explicit number of datapoints that can be used to query data.
   * Resolution = {r | r is an integer, and 1 ≤ r ≤ 1000}.
   */
  readonly resolution?: InputMaybe<Scalars['Int']['input']>
}

export type TelemetryResult = {
  readonly data: ReadonlyArray<TelemetrySeries>
}

export type TelemetrySeries = {
  /** The metric name. */
  readonly metric: Scalars['String']['output']
  /** Key-value pairs describing the metric. */
  readonly labels: Scalars['Map']['output']
  /**
   * The actual time series data, consiting of one timestamp-value pair for each requested time interval.
   * The value is null for time intervals with no data.
   */
  readonly values: ReadonlyArray<TimeValue>
}

export type TimeValue = {
  readonly time: Scalars['Time']['output']
  readonly value?: Maybe<Scalars['Float']['output']>
}

export type DiagnosticsPage = {
  readonly id: Scalars['ID']['output']
  readonly systemId: Scalars['ID']['output']
  readonly systemKind: BatterySystemKind
  readonly title: Scalars['String']['output']
  /** if null could not parse that specific page item from source data */
  readonly content: ReadonlyArray<PageItem>
}

export type PageItem =
  | PageItemImage
  | PageItemPlotly
  | PageItemPlotlyReel
  | PageItemImageReel
  | PageItemHtml
  | PageItemHeader
  | PageItemText
  | PageItemLink
  | PageItemMarkdown

export type PageItemImage = {
  readonly title?: Maybe<Scalars['String']['output']>
  readonly description?: Maybe<Scalars['String']['output']>
  readonly path: Scalars['String']['output']
}

export type PageItemPlotly = {
  readonly title?: Maybe<Scalars['String']['output']>
  readonly description?: Maybe<Scalars['String']['output']>
  readonly path: Scalars['String']['output']
}

export type Reel = {
  readonly title?: Maybe<Scalars['String']['output']>
  readonly description?: Maybe<Scalars['String']['output']>
  readonly path: Scalars['String']['output']
}

export enum ReelInitialPositionEnum {
  Start = 'start',
  End = 'end',
}

export type PageItemImageReel = {
  readonly initialPosition: ReelInitialPositionEnum
  readonly reel: ReadonlyArray<Reel>
}

export type PageItemPlotlyReel = {
  readonly initialPosition: ReelInitialPositionEnum
  readonly reel: ReadonlyArray<Reel>
}

export type PageItemHtml = {
  readonly html: Scalars['String']['output']
}

export type PageItemHeader = {
  readonly header: Scalars['String']['output']
  readonly level: Scalars['Int']['output']
}

export type PageItemText = {
  readonly text: Scalars['String']['output']
}

export type PageItemLink = {
  readonly url: Scalars['String']['output']
  readonly text: Scalars['String']['output']
}

export type PageItemMarkdown = {
  readonly text: Scalars['String']['output']
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string | ((fieldNode: FieldNode) => SelectionSetNode)
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type StitchingResolver<TResult, TParent, TContext, TArgs> =
  | LegacyStitchingResolver<TResult, TParent, TContext, TArgs>
  | NewStitchingResolver<TResult, TParent, TContext, TArgs>
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  PageItem:
    | PageItemImage
    | PageItemPlotly
    | PageItemPlotlyReel
    | PageItemImageReel
    | PageItemHtml
    | PageItemHeader
    | PageItemText
    | PageItemLink
    | PageItemMarkdown
}

/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = {
  Command: TestCommand | PingCommand
  Computer:
    | BatteryMasterUnit
    | (Omit<EdgeGateway, 'host'> & { host?: Maybe<_RefType['NorthvoltIdentity']> })
  NorthvoltIdentity:
    | BatteryMasterUnit
    | (Omit<BatteryModule, 'parentSystem'> & { parentSystem?: Maybe<_RefType['BatterySystem']> })
    | (Omit<
        BatterySystem,
        'parentSystem' | 'subSystems' | 'location' | 'computers' | 'diagnosticsPages'
      > & {
        parentSystem?: Maybe<_RefType['BatterySystem']>
        subSystems?: Maybe<ReadonlyArray<Maybe<_RefType['BatterySystem']>>>
        location?: Maybe<_RefType['GeoLocation']>
        computers?: Maybe<ReadonlyArray<_RefType['Computer']>>
        diagnosticsPages?: Maybe<ReadonlyArray<_RefType['DiagnosticsPage']>>
      })
    | DummyLocation
    | (Omit<EdgeGateway, 'host'> & { host?: Maybe<_RefType['NorthvoltIdentity']> })
    | LegacyLadybugIdentity
  NorthvoltState:
    | BatteryMasterUnitState
    | BatteryFaults
    | BatteryModuleState
    | BatterySystemState
    | EdgeGatewayState
}

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  BatteryMasterUnit: ResolverTypeWrapper<BatteryMasterUnit>
  ID: ResolverTypeWrapper<Scalars['ID']['output']>
  String: ResolverTypeWrapper<Scalars['String']['output']>
  BatteryMasterUnitState: ResolverTypeWrapper<BatteryMasterUnitState>
  BatteryFaults: ResolverTypeWrapper<BatteryFaults>
  BatteryFault: ResolverTypeWrapper<BatteryFault>
  Int: ResolverTypeWrapper<Scalars['Int']['output']>
  Float: ResolverTypeWrapper<Scalars['Float']['output']>
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>
  BatteryFaultTrigger: ResolverTypeWrapper<BatteryFaultTrigger>
  HubState: ResolverTypeWrapper<HubState>
  BatteryModule: ResolverTypeWrapper<
    Omit<BatteryModule, 'parentSystem'> & { parentSystem?: Maybe<ResolversTypes['BatterySystem']> }
  >
  BatteryModuleState: ResolverTypeWrapper<BatteryModuleState>
  AggModule: ResolverTypeWrapper<AggModule>
  BatteryQuery: BatteryQuery
  OrderBy: OrderBy
  SortBy: SortBy
  BatteryFilter: BatteryFilter
  ModuleInput: ModuleInput
  Query: ResolverTypeWrapper<{}>
  BatterySystemsResult: ResolverTypeWrapper<
    Omit<BatterySystemsResult, 'items'> & { items: ReadonlyArray<ResolversTypes['BatterySystem']> }
  >
  ModuleResult: ResolverTypeWrapper<ModuleResult>
  SpiderError: ResolverTypeWrapper<SpiderError>
  BatterySystemKind: BatterySystemKind
  BatterySystemGeneration: BatterySystemGeneration
  BatterySystem: ResolverTypeWrapper<
    Omit<
      BatterySystem,
      'parentSystem' | 'subSystems' | 'location' | 'computers' | 'diagnosticsPages'
    > & {
      parentSystem?: Maybe<ResolversTypes['BatterySystem']>
      subSystems?: Maybe<ReadonlyArray<Maybe<ResolversTypes['BatterySystem']>>>
      location?: Maybe<ResolversTypes['GeoLocation']>
      computers?: Maybe<ReadonlyArray<ResolversTypes['Computer']>>
      diagnosticsPages?: Maybe<ReadonlyArray<ResolversTypes['DiagnosticsPage']>>
    }
  >
  GeoLocationInput: GeoLocationInput
  CreatePackInput: CreatePackInput
  CreateCoreInput: CreateCoreInput
  CreateVoltpackMobileHubInput: CreateVoltpackMobileHubInput
  Tenant: ResolverTypeWrapper<Tenant>
  Product: ResolverTypeWrapper<Product>
  CreateModuleInput: CreateModuleInput
  CreateESSStringInput: CreateEssStringInput
  Mutation: ResolverTypeWrapper<{}>
  BatterySystemResult: ResolverTypeWrapper<BatterySystemResult>
  CreateESSStringResult: ResolverTypeWrapper<CreateEssStringResult>
  CreateModuleResult: ResolverTypeWrapper<CreateModuleResult>
  CreateVoltpackMobileHubResult: ResolverTypeWrapper<CreateVoltpackMobileHubResult>
  ContactorKind: ContactorKind
  BatterySystemState: ResolverTypeWrapper<BatterySystemState>
  BatteryIsolation: ResolverTypeWrapper<BatteryIsolation>
  BatteryContactor: ResolverTypeWrapper<BatteryContactor>
  BatteryModuleMetricStats: ResolverTypeWrapper<BatteryModuleMetricStats>
  BatteryModuleSensorRelativeLocation: ResolverTypeWrapper<BatteryModuleSensorRelativeLocation>
  BMSBoardInfo: ResolverTypeWrapper<BmsBoardInfo>
  ChipTemperature: ResolverTypeWrapper<ChipTemperature>
  SoftwareVersion: ResolverTypeWrapper<SoftwareVersion>
  HardwareVersion: ResolverTypeWrapper<HardwareVersion>
  AggCore: ResolverTypeWrapper<AggCore>
  TimeMeasurements: ResolverTypeWrapper<TimeMeasurements>
  ContactorDiagnostic: ResolverTypeWrapper<ContactorDiagnostic>
  MEBTemperatures: ResolverTypeWrapper<MebTemperatures>
  MDSStatus: ResolverTypeWrapper<MdsStatus>
  ChargingInletTemperatures: ResolverTypeWrapper<ChargingInletTemperatures>
  OperationalState: ResolverTypeWrapper<OperationalState>
  PSBVoltages: ResolverTypeWrapper<PsbVoltages>
  BlockDCIRState: ResolverTypeWrapper<BlockDcirState>
  Soh: ResolverTypeWrapper<Soh>
  NodeSummary: ResolverTypeWrapper<NodeSummary>
  SummaryStats: ResolverTypeWrapper<SummaryStats>
  ExceptionSummary: ResolverTypeWrapper<ExceptionSummary>
  CommandStatus: CommandStatus
  Command: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Command']>
  CommandMeta: ResolverTypeWrapper<CommandMeta>
  TestCommand: ResolverTypeWrapper<TestCommand>
  PingCommand: ResolverTypeWrapper<PingCommand>
  Condition: ResolverTypeWrapper<Condition>
  DummyLocation: ResolverTypeWrapper<DummyLocation>
  Computer: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Computer']>
  EdgeGateway: ResolverTypeWrapper<
    Omit<EdgeGateway, 'host'> & { host?: Maybe<ResolversTypes['NorthvoltIdentity']> }
  >
  EdgeGatewayDeviceInfo: ResolverTypeWrapper<EdgeGatewayDeviceInfo>
  EdgeGatewayNetworkConn: ResolverTypeWrapper<EdgeGatewayNetworkConn>
  EdgeGatewayState: ResolverTypeWrapper<EdgeGatewayState>
  EdgeGatewayMetrics: ResolverTypeWrapper<EdgeGatewayMetrics>
  EdgeGatewayPublisherMetrics: ResolverTypeWrapper<EdgeGatewayPublisherMetrics>
  EdgeGatewaySystemMetrics: ResolverTypeWrapper<EdgeGatewaySystemMetrics>
  NetworkInterfaceMetrics: ResolverTypeWrapper<NetworkInterfaceMetrics>
  EdgeGatewayGPUMetrics: ResolverTypeWrapper<EdgeGatewayGpuMetrics>
  GeoLocation: ResolverTypeWrapper<GeoLocation>
  NorthvoltIdentity: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['NorthvoltIdentity']
  >
  IdentityMeta: ResolverTypeWrapper<IdentityMeta>
  IDRef: ResolverTypeWrapper<IdRef>
  LegacyLadybugIdentity: ResolverTypeWrapper<LegacyLadybugIdentity>
  GQLServerStateResult: ResolverTypeWrapper<GqlServerStateResult>
  NorthvoltState: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['NorthvoltState']>
  StateMeta: ResolverTypeWrapper<StateMeta>
  TelemetryQuery: TelemetryQuery
  TelemetryResult: ResolverTypeWrapper<TelemetryResult>
  TelemetrySeries: ResolverTypeWrapper<TelemetrySeries>
  TimeValue: ResolverTypeWrapper<TimeValue>
  Milliseconds: ResolverTypeWrapper<Scalars['Milliseconds']['output']>
  Nanoseconds: ResolverTypeWrapper<Scalars['Nanoseconds']['output']>
  Int64: ResolverTypeWrapper<Scalars['Int64']['output']>
  Time: ResolverTypeWrapper<Scalars['Time']['output']>
  Map: ResolverTypeWrapper<Scalars['Map']['output']>
  Any: ResolverTypeWrapper<Scalars['Any']['output']>
  URL: ResolverTypeWrapper<Scalars['URL']['output']>
  StringMap: ResolverTypeWrapper<Scalars['StringMap']['output']>
  NVIdentityType: ResolverTypeWrapper<Scalars['NVIdentityType']['output']>
  DiagnosticsPage: ResolverTypeWrapper<
    Omit<DiagnosticsPage, 'content'> & { content: ReadonlyArray<ResolversTypes['PageItem']> }
  >
  PageItem: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['PageItem']>
  PageItemImage: ResolverTypeWrapper<PageItemImage>
  PageItemPlotly: ResolverTypeWrapper<PageItemPlotly>
  Reel: ResolverTypeWrapper<Reel>
  ReelInitialPositionEnum: ReelInitialPositionEnum
  PageItemImageReel: ResolverTypeWrapper<PageItemImageReel>
  PageItemPlotlyReel: ResolverTypeWrapper<PageItemPlotlyReel>
  PageItemHtml: ResolverTypeWrapper<PageItemHtml>
  PageItemHeader: ResolverTypeWrapper<PageItemHeader>
  PageItemText: ResolverTypeWrapper<PageItemText>
  PageItemLink: ResolverTypeWrapper<PageItemLink>
  PageItemMarkdown: ResolverTypeWrapper<PageItemMarkdown>
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  BatteryMasterUnit: BatteryMasterUnit
  ID: Scalars['ID']['output']
  String: Scalars['String']['output']
  BatteryMasterUnitState: BatteryMasterUnitState
  BatteryFaults: BatteryFaults
  BatteryFault: BatteryFault
  Int: Scalars['Int']['output']
  Float: Scalars['Float']['output']
  Boolean: Scalars['Boolean']['output']
  BatteryFaultTrigger: BatteryFaultTrigger
  HubState: HubState
  BatteryModule: Omit<BatteryModule, 'parentSystem'> & {
    parentSystem?: Maybe<ResolversParentTypes['BatterySystem']>
  }
  BatteryModuleState: BatteryModuleState
  AggModule: AggModule
  BatteryQuery: BatteryQuery
  BatteryFilter: BatteryFilter
  ModuleInput: ModuleInput
  Query: {}
  BatterySystemsResult: Omit<BatterySystemsResult, 'items'> & {
    items: ReadonlyArray<ResolversParentTypes['BatterySystem']>
  }
  ModuleResult: ModuleResult
  SpiderError: SpiderError
  BatterySystem: Omit<
    BatterySystem,
    'parentSystem' | 'subSystems' | 'location' | 'computers' | 'diagnosticsPages'
  > & {
    parentSystem?: Maybe<ResolversParentTypes['BatterySystem']>
    subSystems?: Maybe<ReadonlyArray<Maybe<ResolversParentTypes['BatterySystem']>>>
    location?: Maybe<ResolversParentTypes['GeoLocation']>
    computers?: Maybe<ReadonlyArray<ResolversParentTypes['Computer']>>
    diagnosticsPages?: Maybe<ReadonlyArray<ResolversParentTypes['DiagnosticsPage']>>
  }
  GeoLocationInput: GeoLocationInput
  CreatePackInput: CreatePackInput
  CreateCoreInput: CreateCoreInput
  CreateVoltpackMobileHubInput: CreateVoltpackMobileHubInput
  Tenant: Tenant
  Product: Product
  CreateModuleInput: CreateModuleInput
  CreateESSStringInput: CreateEssStringInput
  Mutation: {}
  BatterySystemResult: BatterySystemResult
  CreateESSStringResult: CreateEssStringResult
  CreateModuleResult: CreateModuleResult
  CreateVoltpackMobileHubResult: CreateVoltpackMobileHubResult
  BatterySystemState: BatterySystemState
  BatteryIsolation: BatteryIsolation
  BatteryContactor: BatteryContactor
  BatteryModuleMetricStats: BatteryModuleMetricStats
  BatteryModuleSensorRelativeLocation: BatteryModuleSensorRelativeLocation
  BMSBoardInfo: BmsBoardInfo
  ChipTemperature: ChipTemperature
  SoftwareVersion: SoftwareVersion
  HardwareVersion: HardwareVersion
  AggCore: AggCore
  TimeMeasurements: TimeMeasurements
  ContactorDiagnostic: ContactorDiagnostic
  MEBTemperatures: MebTemperatures
  MDSStatus: MdsStatus
  ChargingInletTemperatures: ChargingInletTemperatures
  OperationalState: OperationalState
  PSBVoltages: PsbVoltages
  BlockDCIRState: BlockDcirState
  Soh: Soh
  NodeSummary: NodeSummary
  SummaryStats: SummaryStats
  ExceptionSummary: ExceptionSummary
  Command: ResolversInterfaceTypes<ResolversParentTypes>['Command']
  CommandMeta: CommandMeta
  TestCommand: TestCommand
  PingCommand: PingCommand
  Condition: Condition
  DummyLocation: DummyLocation
  Computer: ResolversInterfaceTypes<ResolversParentTypes>['Computer']
  EdgeGateway: Omit<EdgeGateway, 'host'> & {
    host?: Maybe<ResolversParentTypes['NorthvoltIdentity']>
  }
  EdgeGatewayDeviceInfo: EdgeGatewayDeviceInfo
  EdgeGatewayNetworkConn: EdgeGatewayNetworkConn
  EdgeGatewayState: EdgeGatewayState
  EdgeGatewayMetrics: EdgeGatewayMetrics
  EdgeGatewayPublisherMetrics: EdgeGatewayPublisherMetrics
  EdgeGatewaySystemMetrics: EdgeGatewaySystemMetrics
  NetworkInterfaceMetrics: NetworkInterfaceMetrics
  EdgeGatewayGPUMetrics: EdgeGatewayGpuMetrics
  GeoLocation: GeoLocation
  NorthvoltIdentity: ResolversInterfaceTypes<ResolversParentTypes>['NorthvoltIdentity']
  IdentityMeta: IdentityMeta
  IDRef: IdRef
  LegacyLadybugIdentity: LegacyLadybugIdentity
  GQLServerStateResult: GqlServerStateResult
  NorthvoltState: ResolversInterfaceTypes<ResolversParentTypes>['NorthvoltState']
  StateMeta: StateMeta
  TelemetryQuery: TelemetryQuery
  TelemetryResult: TelemetryResult
  TelemetrySeries: TelemetrySeries
  TimeValue: TimeValue
  Milliseconds: Scalars['Milliseconds']['output']
  Nanoseconds: Scalars['Nanoseconds']['output']
  Int64: Scalars['Int64']['output']
  Time: Scalars['Time']['output']
  Map: Scalars['Map']['output']
  Any: Scalars['Any']['output']
  URL: Scalars['URL']['output']
  StringMap: Scalars['StringMap']['output']
  NVIdentityType: Scalars['NVIdentityType']['output']
  DiagnosticsPage: Omit<DiagnosticsPage, 'content'> & {
    content: ReadonlyArray<ResolversParentTypes['PageItem']>
  }
  PageItem: ResolversUnionTypes<ResolversParentTypes>['PageItem']
  PageItemImage: PageItemImage
  PageItemPlotly: PageItemPlotly
  Reel: Reel
  PageItemImageReel: PageItemImageReel
  PageItemPlotlyReel: PageItemPlotlyReel
  PageItemHtml: PageItemHtml
  PageItemHeader: PageItemHeader
  PageItemText: PageItemText
  PageItemLink: PageItemLink
  PageItemMarkdown: PageItemMarkdown
}

export type JsonDirectiveArgs = {
  field: Scalars['String']['input']
}

export type JsonDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = JsonDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type LinkDirectiveArgs = {}

export type LinkDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = LinkDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type RequireDirectiveArgs = {
  name?: Maybe<Scalars['String']['input']>
}

export type RequireDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = RequireDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type TypeMarshalDirectiveArgs = {}

export type TypeMarshalDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = TypeMarshalDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type ResolveDirectiveArgs = {
  state?: Maybe<Scalars['Boolean']['input']>
}

export type ResolveDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ResolveDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type OneOfRolesDirectiveArgs = {
  roles?: Maybe<ReadonlyArray<Scalars['String']['input']>>
  any?: Maybe<Scalars['Boolean']['input']>
}

export type OneOfRolesDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = OneOfRolesDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type ExternalDirectiveArgs = {}

export type ExternalDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ExternalDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type GoFieldDirectiveArgs = {
  forceResolver?: Maybe<Scalars['Boolean']['input']>
  name?: Maybe<Scalars['String']['input']>
}

export type GoFieldDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = GoFieldDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type BatteryMasterUnitResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatteryMasterUnit'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['IdentityMeta'], ParentType, ContextType>
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['BatteryMasterUnitState']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatteryMasterUnitStateResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatteryMasterUnitState'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['StateMeta'], ParentType, ContextType>
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  serial?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  mcVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cscVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  scVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  pmbVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatteryFaultsResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatteryFaults'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  meta?: Resolver<ResolversTypes['StateMeta'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  list?: Resolver<Maybe<ReadonlyArray<ResolversTypes['BatteryFault']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatteryFaultResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatteryFault'],
> = {
  dtcSpn?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  fmi?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  occurrenceCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  firstOccurredAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  lastOccurredAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  reportedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  timeSinceCurrentPowerCycleStart?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  action?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  errorTrigger?: Resolver<Maybe<ResolversTypes['Int64']>, ParentType, ContextType>
  errorTriggers?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['BatteryFaultTrigger']>>,
    ParentType,
    ContextType
  >
  type?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatteryFaultTriggerResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatteryFaultTrigger'],
> = {
  code?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  timeSinceCurrentPowerCycleStart?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HubStateResolvers<ContextType = any, ParentType = ResolversParentTypes['HubState']> = {
  rtdbVariables?: Resolver<ResolversTypes['Map'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatteryModuleResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatteryModule'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  meta?: Resolver<ResolversTypes['IdentityMeta'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  parentSystem?: Resolver<Maybe<ResolversTypes['BatterySystem']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['BatteryModuleState']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatteryModuleStateResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatteryModuleState'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['StateMeta'], ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  isBalancingCells?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Boolean']>>>,
    ParentType,
    ContextType
  >
  cellBalancingTemperatures?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  cellVoltages?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  cellTemperatures?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  agg?: Resolver<Maybe<ResolversTypes['AggModule']>, ParentType, ContextType>
  dcir?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  cellBatchNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cellCapacityGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  connectivityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  serialNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cellType?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  lifetimeChargedAh?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  lifetimeChargedWh?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  lifetimeDischargedAh?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  lifetimeDischargedWh?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  csbId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AggModuleResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['AggModule'],
> = {
  timeAtEnd?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  cellVoltageMax?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  cellVoltageMin?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  cellVoltageAvg?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  cellVoltageMaxStep?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<ContextType = any, ParentType = ResolversParentTypes['Query']> = {
  batterySystems?: Resolver<
    ResolversTypes['BatterySystemsResult'],
    ParentType,
    ContextType,
    Partial<QueryBatterySystemsArgs>
  >
  systems?: Resolver<
    ResolversTypes['BatterySystemsResult'],
    ParentType,
    ContextType,
    Partial<QuerySystemsArgs>
  >
  systemsByIDs?: Resolver<
    ResolversTypes['BatterySystemsResult'],
    ParentType,
    ContextType,
    RequireFields<QuerySystemsByIDsArgs, 'ids'>
  >
  battery360?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryBattery360Args, 'id' | 'version'>
  >
  batteryFaults?: Resolver<
    ReadonlyArray<ResolversTypes['BatteryFault']>,
    ParentType,
    ContextType,
    RequireFields<QueryBatteryFaultsArgs, 'batterySystemID'>
  >
  batterySystem?: Resolver<
    ResolversTypes['BatterySystem'],
    ParentType,
    ContextType,
    RequireFields<QueryBatterySystemArgs, 'id'>
  >
  products?: Resolver<ReadonlyArray<ResolversTypes['Product']>, ParentType, ContextType>
  tenants?: Resolver<ReadonlyArray<ResolversTypes['Tenant']>, ParentType, ContextType>
  tags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  module?: Resolver<
    ResolversTypes['ModuleResult'],
    ParentType,
    ContextType,
    RequireFields<QueryModuleArgs, 'details'>
  >
  serverState?: Resolver<Maybe<ResolversTypes['GQLServerStateResult']>, ParentType, ContextType>
  state?: Resolver<
    Maybe<ResolversTypes['NorthvoltState']>,
    ParentType,
    ContextType,
    RequireFields<QueryStateArgs, 'id'>
  >
  telemetry?: Resolver<
    Maybe<ResolversTypes['TelemetryResult']>,
    ParentType,
    ContextType,
    Partial<QueryTelemetryArgs>
  >
  diagnosticsPage?: Resolver<
    Maybe<ResolversTypes['DiagnosticsPage']>,
    ParentType,
    ContextType,
    RequireFields<QueryDiagnosticsPageArgs, 'systemId' | 'systemKind' | 'id'>
  >
  diagnosticsPages?: Resolver<
    ReadonlyArray<ResolversTypes['DiagnosticsPage']>,
    ParentType,
    ContextType,
    RequireFields<QueryDiagnosticsPagesArgs, 'systemId' | 'systemKind'>
  >
}

export type BatterySystemsResultResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatterySystemsResult'],
> = {
  items?: Resolver<ReadonlyArray<ResolversTypes['BatterySystem']>, ParentType, ContextType>
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ModuleResultResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['ModuleResult'],
> = {
  connectivityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  labelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  tenant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  metadata?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SpiderErrorResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['SpiderError'],
> = {
  shortMessage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fullMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  occurrenceCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  firstOccurredAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  lastOccurredAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatterySystemResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatterySystem'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  meta?: Resolver<ResolversTypes['IdentityMeta'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  observed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  kind?: Resolver<Maybe<ResolversTypes['BatterySystemKind']>, ParentType, ContextType>
  generation?: Resolver<Maybe<ResolversTypes['BatterySystemGeneration']>, ParentType, ContextType>
  parentSystem?: Resolver<Maybe<ResolversTypes['BatterySystem']>, ParentType, ContextType>
  subSystems?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['BatterySystem']>>>,
    ParentType,
    ContextType
  >
  modules?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['BatteryModule']>>>,
    ParentType,
    ContextType
  >
  location?: Resolver<Maybe<ResolversTypes['GeoLocation']>, ParentType, ContextType>
  tags?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  computers?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Computer']>>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['BatterySystemState']>, ParentType, ContextType>
  faults?: Resolver<Maybe<ResolversTypes['BatteryFaults']>, ParentType, ContextType>
  connectivityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  diagnosticsPages?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['DiagnosticsPage']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TenantResolvers<ContextType = any, ParentType = ResolversParentTypes['Tenant']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductResolvers<ContextType = any, ParentType = ResolversParentTypes['Product']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MutationResolvers<ContextType = any, ParentType = ResolversParentTypes['Mutation']> = {
  createPack?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationCreatePackArgs, 'id' | 'packDetails'>
  >
  setPackDisplayName?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetPackDisplayNameArgs, 'id' | 'displayName'>
  >
  setPackTags?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetPackTagsArgs, 'id' | 'tags'>
  >
  setPackTenant?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetPackTenantArgs, 'id' | 'tenant'>
  >
  setPackGeoLocation?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetPackGeoLocationArgs, 'id' | 'geoLocation'>
  >
  createCore?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCoreArgs, 'id' | 'coreDetails'>
  >
  setCoreDisplayName?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetCoreDisplayNameArgs, 'id' | 'displayName'>
  >
  setCoreTags?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetCoreTagsArgs, 'id' | 'tags'>
  >
  setCoreTenant?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetCoreTenantArgs, 'id' | 'tenant'>
  >
  setCoreMacAddress?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetCoreMacAddressArgs, 'id' | 'macAddress'>
  >
  createVoltpackMobileHub?: Resolver<
    ResolversTypes['CreateVoltpackMobileHubResult'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateVoltpackMobileHubArgs, 'id' | 'voltpackMobileHubDetails'>
  >
  setVoltpackMobileHubDisplayName?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetVoltpackMobileHubDisplayNameArgs, 'id' | 'displayName'>
  >
  setVoltpackMobileHubTenant?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetVoltpackMobileHubTenantArgs, 'id' | 'tenant'>
  >
  setVoltpackMobileHubMacAddress?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetVoltpackMobileHubMacAddressArgs, 'id' | 'macAddress'>
  >
  setVoltpackMobileHubTags?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetVoltpackMobileHubTagsArgs, 'id' | 'tags'>
  >
  createTenant?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateTenantArgs, 'name'>
  >
  addPublicKeyFingerprint?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationAddPublicKeyFingerprintArgs, 'fingerprint' | 'hardwareSe' | 'tenant'>
  >
  addFallbackMacAddress?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationAddFallbackMacAddressArgs, 'mac'>
  >
  createESSString?: Resolver<
    ResolversTypes['CreateESSStringResult'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateEssStringArgs, 'stringDetails'>
  >
  createModule?: Resolver<
    ResolversTypes['CreateModuleResult'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateModuleArgs, 'moduleDetails'>
  >
  setModuleLabelId?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetModuleLabelIdArgs, 'id' | 'labelId'>
  >
  setModuleTenant?: Resolver<
    ResolversTypes['BatterySystemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSetModuleTenantArgs, 'id' | 'tenant'>
  >
}

export type BatterySystemResultResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatterySystemResult'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateEssStringResultResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['CreateESSStringResult'],
> = {
  connectivityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  macAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateModuleResultResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['CreateModuleResult'],
> = {
  connectivityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateVoltpackMobileHubResultResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['CreateVoltpackMobileHubResult'],
> = {
  connectivityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatterySystemStateResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatterySystemState'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  meta?: Resolver<ResolversTypes['StateMeta'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  hubState?: Resolver<Maybe<ResolversTypes['HubState']>, ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  isConnected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  softwareVersions?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['SoftwareVersion']>>,
    ParentType,
    ContextType
  >
  hardwareVersions?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['HardwareVersion']>>,
    ParentType,
    ContextType
  >
  stateOfCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  operationalStateOfCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  stateOfHealthV2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  capacityEstimateAh?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  resistanceEstimateOhms?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  stateOfHealthV3?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  chargeCapacityEstimate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  dischargeCapacityEstimate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  resistanceEstimate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  lifetimeEquivalentCycleCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  lifetimeCycledWh?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  lifetimeChargedWh?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  lifetimeChargerChargedWh?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  lifetimeDischargedWh?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  lifetimeChargedAh?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  lifetimeDischargedAh?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  lifetimeChargerChargedAh?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  voltage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  externalVoltage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  current?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  cellTemperatureStats?: Resolver<
    Maybe<ResolversTypes['BatteryModuleMetricStats']>,
    ParentType,
    ContextType
  >
  cellVoltageStats?: Resolver<
    Maybe<ResolversTypes['BatteryModuleMetricStats']>,
    ParentType,
    ContextType
  >
  isolation?: Resolver<Maybe<ResolversTypes['BatteryIsolation']>, ParentType, ContextType>
  bmsBoard?: Resolver<Maybe<ResolversTypes['BMSBoardInfo']>, ParentType, ContextType>
  mdsStatus?: Resolver<Maybe<ResolversTypes['MDSStatus']>, ParentType, ContextType>
  contactors?: Resolver<ReadonlyArray<ResolversTypes['BatteryContactor']>, ParentType, ContextType>
  isContactorSohErrorDisabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isChargerConnected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  soh?: Resolver<Maybe<ResolversTypes['Soh']>, ParentType, ContextType>
  aggCore?: Resolver<Maybe<ResolversTypes['AggCore']>, ParentType, ContextType>
  sPackState?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  sChcoState?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  chargePowerLimit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  dischargePowerLimit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  chargeCurrentLimit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  dischargeCurrentLimit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  timeSinceOcvCorrection?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  timeMeasurements?: Resolver<Maybe<ResolversTypes['TimeMeasurements']>, ParentType, ContextType>
  contactorDiagnostic?: Resolver<
    Maybe<ResolversTypes['ContactorDiagnostic']>,
    ParentType,
    ContextType
  >
  ambientEtrayTemperature?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  ambientPackTemperatures?: Resolver<
    Maybe<ResolversTypes['MEBTemperatures']>,
    ParentType,
    ContextType
  >
  chargingInletTemperatures?: Resolver<
    Maybe<ResolversTypes['ChargingInletTemperatures']>,
    ParentType,
    ContextType
  >
  psbVoltages?: Resolver<Maybe<ResolversTypes['PSBVoltages']>, ParentType, ContextType>
  operationalState?: Resolver<Maybe<ResolversTypes['OperationalState']>, ParentType, ContextType>
  blockDcir?: Resolver<Maybe<ResolversTypes['BlockDCIRState']>, ParentType, ContextType>
  currentSecondary?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  chargeMode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  spiderErrors?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['SpiderError']>>,
    ParentType,
    ContextType
  >
  energyThroughputCharge?: Resolver<Maybe<ResolversTypes['NodeSummary']>, ParentType, ContextType>
  connectivityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  csbWriteErrorCounter?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatteryIsolationResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatteryIsolation'],
> = {
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  valueOhms?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  observedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  wasObservedDuringCurrentPowerCycle?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatteryContactorResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatteryContactor'],
> = {
  kind?: Resolver<ResolversTypes['ContactorKind'], ParentType, ContextType>
  isOpen?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  stateOfHealth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatteryModuleMetricStatsResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatteryModuleMetricStats'],
> = {
  min?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  mean?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  minLoc?: Resolver<
    Maybe<ResolversTypes['BatteryModuleSensorRelativeLocation']>,
    ParentType,
    ContextType
  >
  maxLoc?: Resolver<
    Maybe<ResolversTypes['BatteryModuleSensorRelativeLocation']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatteryModuleSensorRelativeLocationResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BatteryModuleSensorRelativeLocation'],
> = {
  subSystemPath?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>
  module?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  sensor?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BmsBoardInfoResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BMSBoardInfo'],
> = {
  batteryVoltage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  isBatteryCharging?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isBatteryConnected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  cumulativeTimeAdjustment?: Resolver<
    Maybe<ResolversTypes['Milliseconds']>,
    ParentType,
    ContextType
  >
  supplyVoltage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  chipTemperatures?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ChipTemperature']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChipTemperatureResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['ChipTemperature'],
> = {
  chip?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  temperature?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SoftwareVersionResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['SoftwareVersion'],
> = {
  software?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HardwareVersionResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['HardwareVersion'],
> = {
  hardware?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AggCoreResolvers<ContextType = any, ParentType = ResolversParentTypes['AggCore']> = {
  timeAtEnd?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  currentMax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  currentMin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  currentAvg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  currentStdDev?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  currentMaxStep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  voltageMax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  voltageMin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  voltageAvg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  voltageStdDev?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  voltageMaxStep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  acoUpTimeS?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  acoTotalUpTimeS?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  currentFlowingTimeS?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  coulombCountCoulombs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TimeMeasurementsResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['TimeMeasurements'],
> = {
  bmsTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  lastTimeSinceCellBalancing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ContactorDiagnosticResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['ContactorDiagnostic'],
> = {
  disconnectionReason?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  rotationOngoing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  nrCoresPreventedFromConnecting?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  bValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  calibrationConditionsMet?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  calculatedCValueNegative?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  calculatedCValuePositive?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  calculatedCValuePrecharge?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  averageCValueNegative?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  averageCValuePositive?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  averageCValuePrecharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  calibrationTemperature?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  calibrationVoltageNegative?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  calibrationVoltagePositive?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  calibrationVoltagePrecharge?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  calibrationTimingsNegative?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>,
    ParentType,
    ContextType
  >
  calibrationTimingsPositive?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>,
    ParentType,
    ContextType
  >
  calibrationTimingsPrecharge?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>,
    ParentType,
    ContextType
  >
  calibrationStatus?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  lastAttemptedCalibrationTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  lastSuccessfulCalibrationTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  milliVoltageBeforeClosingNegative?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  milliVoltageBeforeClosingPositive?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  milliVoltageBeforeClosingPrecharge?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  milliVoltageDuringClosingNegative?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  milliVoltageDuringClosingPositive?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  milliVoltageDuringClosingPrecharge?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  milliVoltageAfterClosingNegative?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  milliVoltageAfterClosingPositive?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  milliVoltageAfterClosingPrecharge?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  estimatedContactorTemperature?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  nominalClosingTimePositive?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  nominalClosingTimeNegative?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  actualClosingTimePositive?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  actualClosingTimeNegative?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  actualClosingTimePrecharge?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  diagnosticStatusNOKCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  diagnosticDisabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  calibrationRequested?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  calibrationAbortRequested?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  weldedPositiveContactorCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  weldedNegativeContactorCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MebTemperaturesResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['MEBTemperatures'],
> = {
  DCDCCasingNTCTemperature?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  PSBAmbientNTCTemperature?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  LVBatteryTemperature?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MdsStatusResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['MDSStatus'],
> = {
  mdsBoardInstalled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  mebDcdcEnforced?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  tmsDcdcSupplyDetection?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  tmsDcdcEnforced?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChargingInletTemperaturesResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['ChargingInletTemperatures'],
> = {
  vcvccuPtc1Temperature?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vcvccuPtc2Temperature?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OperationalStateResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['OperationalState'],
> = {
  operationalStatus?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  batteryState?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PsbVoltagesResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['PSBVoltages'],
> = {
  dcdcSupplyVoltage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  diagnosticsSupplyVoltage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  batterySupplyVoltage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vehicleKL30Voltage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BlockDcirStateResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['BlockDCIRState'],
> = {
  numSamples?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>
  values?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>
  resetMemories?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  udiffLimit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  idiffLimit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  smoothingFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SohResolvers<ContextType = any, ParentType = ResolversParentTypes['Soh']> = {
  validatedStepResistance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  nrStepResistanceEstimatesInBin?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>,
    ParentType,
    ContextType
  >
  voltageDiff?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  currentDiff?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  latestStepResistanceEstimate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  latestResistanceEstimate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  sohStepResistanceBelowMinusTenDeg?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  sohStepResistanceMinusTenToZeroDeg?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  sohStepResistanceZeroToTenDeg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  sohStepResistanceTenToTwentyfiveDeg?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  sohStepResistanceAboveTwentyfiveDeg?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  ewmFiltResistance1?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  ewmFiltResistance2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  sohMedianFilter1Values?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  sohMedianFilter2Values?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  sohMedianFilter1Ages?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  sohMedianFilter2Ages?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  tSOHAverageTemperature?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  capacityReference1SOH?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  capacityReference2SOH?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  nrTotalSOHEstimates1?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  nrTotalSOHEstimates2?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  capacityBasedPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  estimatedCapacity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  capacityLatestEstimate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  estimatedResistance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  vpcAgedUseableCapacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NodeSummaryResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['NodeSummary'],
> = {
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  children?: Resolver<Maybe<ResolversTypes['SummaryStats']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SummaryStatsResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['SummaryStats'],
> = {
  min?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  max?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  mean?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ExceptionSummaryResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['ExceptionSummary'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  warningCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  alarmCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CommandResolvers<ContextType = any, ParentType = ResolversParentTypes['Command']> = {
  __resolveType: TypeResolveFn<'TestCommand' | 'PingCommand', ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  actor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  meta?: Resolver<ResolversTypes['CommandMeta'], ParentType, ContextType>
}

export type CommandMetaResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['CommandMeta'],
> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ifaces?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['CommandStatus'], ParentType, ContextType>
  created?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  updated?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  expires?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TestCommandResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['TestCommand'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  actor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  meta?: Resolver<ResolversTypes['CommandMeta'], ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PingCommandResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['PingCommand'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  actor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  meta?: Resolver<ResolversTypes['CommandMeta'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConditionResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['Condition'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DummyLocationResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['DummyLocation'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['IdentityMeta'], ParentType, ContextType>
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ComputerResolvers<ContextType = any, ParentType = ResolversParentTypes['Computer']> = {
  __resolveType: TypeResolveFn<'BatteryMasterUnit' | 'EdgeGateway', ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  meta?: Resolver<ResolversTypes['IdentityMeta'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
}

export type EdgeGatewayResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['EdgeGateway'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['IdentityMeta'], ParentType, ContextType>
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  host?: Resolver<Maybe<ResolversTypes['NorthvoltIdentity']>, ParentType, ContextType>
  vendor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['EdgeGatewayState']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EdgeGatewayDeviceInfoResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['EdgeGatewayDeviceInfo'],
> = {
  balenaUUID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  balenaApp?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  balenaAppID?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  balenaName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  unitName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deviceName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  desc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  switchName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  switchType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  portName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  connections?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['EdgeGatewayNetworkConn']>>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EdgeGatewayNetworkConnResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['EdgeGatewayNetworkConn'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  desc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  dev?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  addr?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  vlan?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EdgeGatewayStateResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['EdgeGatewayState'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['StateMeta'], ParentType, ContextType>
  metrics?: Resolver<Maybe<ResolversTypes['EdgeGatewayMetrics']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EdgeGatewayMetricsResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['EdgeGatewayMetrics'],
> = {
  pub?: Resolver<Maybe<ResolversTypes['EdgeGatewayPublisherMetrics']>, ParentType, ContextType>
  sys?: Resolver<Maybe<ResolversTypes['EdgeGatewaySystemMetrics']>, ParentType, ContextType>
  gpu?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['EdgeGatewayGPUMetrics']>>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EdgeGatewayPublisherMetricsResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['EdgeGatewayPublisherMetrics'],
> = {
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  buffered?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  errors?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  ids?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EdgeGatewaySystemMetricsResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['EdgeGatewaySystemMetrics'],
> = {
  appVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  appUptime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  balenaApp?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  balenaUUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  osVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  arch?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  cpuCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  cpuModel?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  goVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  goos?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  goarch?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  gomaxprocs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  godebug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  gogc?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  intSize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  load1Min?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  load5Min?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  load15Min?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  memAvail?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  memFree?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  memSwap?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  netEth0?: Resolver<ResolversTypes['NetworkInterfaceMetrics'], ParentType, ContextType>
  netEth1?: Resolver<ResolversTypes['NetworkInterfaceMetrics'], ParentType, ContextType>
  netWlan0?: Resolver<ResolversTypes['NetworkInterfaceMetrics'], ParentType, ContextType>
  netWwan0?: Resolver<ResolversTypes['NetworkInterfaceMetrics'], ParentType, ContextType>
  uptime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NetworkInterfaceMetricsResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['NetworkInterfaceMetrics'],
> = {
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  Rx?: Resolver<ResolversTypes['Int64'], ParentType, ContextType>
  Tx?: Resolver<ResolversTypes['Int64'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EdgeGatewayGpuMetricsResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['EdgeGatewayGPUMetrics'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deviceNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  load?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  memUsed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  temperature?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  power?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GeoLocationResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['GeoLocation'],
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NorthvoltIdentityResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['NorthvoltIdentity'],
> = {
  __resolveType: TypeResolveFn<
    | 'BatteryMasterUnit'
    | 'BatteryModule'
    | 'BatterySystem'
    | 'DummyLocation'
    | 'EdgeGateway'
    | 'LegacyLadybugIdentity',
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['IdentityMeta'], ParentType, ContextType>
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
}

export type IdentityMetaResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['IdentityMeta'],
> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ifaces?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  created?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  updated?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  tenant?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  traceID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IdRefResolvers<ContextType = any, ParentType = ResolversParentTypes['IDRef']> = {
  balenaApp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  balenaUUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hwAddr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hostName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  serialNr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LegacyLadybugIdentityResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['LegacyLadybugIdentity'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['IdentityMeta'], ParentType, ContextType>
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fbs?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  supplier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  data?: Resolver<Maybe<ResolversTypes['Map']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GqlServerStateResultResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['GQLServerStateResult'],
> = {
  gitTag?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NorthvoltStateResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['NorthvoltState'],
> = {
  __resolveType: TypeResolveFn<
    | 'BatteryMasterUnitState'
    | 'BatteryFaults'
    | 'BatteryModuleState'
    | 'BatterySystemState'
    | 'EdgeGatewayState',
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  idref?: Resolver<Maybe<ResolversTypes['IDRef']>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['StateMeta'], ParentType, ContextType>
}

export type StateMetaResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['StateMeta'],
> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ifaces?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  sender?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  senderFBS?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  msgID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  tenant?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  observed?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  mapped?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  published?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  storageKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TelemetryResultResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['TelemetryResult'],
> = {
  data?: Resolver<ReadonlyArray<ResolversTypes['TelemetrySeries']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TelemetrySeriesResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['TelemetrySeries'],
> = {
  metric?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  labels?: Resolver<ResolversTypes['Map'], ParentType, ContextType>
  values?: Resolver<ReadonlyArray<ResolversTypes['TimeValue']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TimeValueResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['TimeValue'],
> = {
  time?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface MillisecondsScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Milliseconds'], any> {
  name: 'Milliseconds'
}

export interface NanosecondsScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Nanoseconds'], any> {
  name: 'Nanoseconds'
}

export interface Int64ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Int64'], any> {
  name: 'Int64'
}

export interface TimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Time'], any> {
  name: 'Time'
}

export interface MapScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Map'], any> {
  name: 'Map'
}

export interface AnyScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Any'], any> {
  name: 'Any'
}

export interface UrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL'
}

export interface StringMapScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['StringMap'], any> {
  name: 'StringMap'
}

export interface NvIdentityTypeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['NVIdentityType'], any> {
  name: 'NVIdentityType'
}

export type DiagnosticsPageResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['DiagnosticsPage'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  systemId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  systemKind?: Resolver<ResolversTypes['BatterySystemKind'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  content?: Resolver<ReadonlyArray<ResolversTypes['PageItem']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageItemResolvers<ContextType = any, ParentType = ResolversParentTypes['PageItem']> = {
  __resolveType: TypeResolveFn<
    | 'PageItemImage'
    | 'PageItemPlotly'
    | 'PageItemPlotlyReel'
    | 'PageItemImageReel'
    | 'PageItemHtml'
    | 'PageItemHeader'
    | 'PageItemText'
    | 'PageItemLink'
    | 'PageItemMarkdown',
    ParentType,
    ContextType
  >
}

export type PageItemImageResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['PageItemImage'],
> = {
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageItemPlotlyResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['PageItemPlotly'],
> = {
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReelResolvers<ContextType = any, ParentType = ResolversParentTypes['Reel']> = {
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageItemImageReelResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['PageItemImageReel'],
> = {
  initialPosition?: Resolver<ResolversTypes['ReelInitialPositionEnum'], ParentType, ContextType>
  reel?: Resolver<ReadonlyArray<ResolversTypes['Reel']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageItemPlotlyReelResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['PageItemPlotlyReel'],
> = {
  initialPosition?: Resolver<ResolversTypes['ReelInitialPositionEnum'], ParentType, ContextType>
  reel?: Resolver<ReadonlyArray<ResolversTypes['Reel']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageItemHtmlResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['PageItemHtml'],
> = {
  html?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageItemHeaderResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['PageItemHeader'],
> = {
  header?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageItemTextResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['PageItemText'],
> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageItemLinkResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['PageItemLink'],
> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageItemMarkdownResolvers<
  ContextType = any,
  ParentType = ResolversParentTypes['PageItemMarkdown'],
> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = any> = {
  BatteryMasterUnit?: BatteryMasterUnitResolvers<ContextType>
  BatteryMasterUnitState?: BatteryMasterUnitStateResolvers<ContextType>
  BatteryFaults?: BatteryFaultsResolvers<ContextType>
  BatteryFault?: BatteryFaultResolvers<ContextType>
  BatteryFaultTrigger?: BatteryFaultTriggerResolvers<ContextType>
  HubState?: HubStateResolvers<ContextType>
  BatteryModule?: BatteryModuleResolvers<ContextType>
  BatteryModuleState?: BatteryModuleStateResolvers<ContextType>
  AggModule?: AggModuleResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  BatterySystemsResult?: BatterySystemsResultResolvers<ContextType>
  ModuleResult?: ModuleResultResolvers<ContextType>
  SpiderError?: SpiderErrorResolvers<ContextType>
  BatterySystem?: BatterySystemResolvers<ContextType>
  Tenant?: TenantResolvers<ContextType>
  Product?: ProductResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  BatterySystemResult?: BatterySystemResultResolvers<ContextType>
  CreateESSStringResult?: CreateEssStringResultResolvers<ContextType>
  CreateModuleResult?: CreateModuleResultResolvers<ContextType>
  CreateVoltpackMobileHubResult?: CreateVoltpackMobileHubResultResolvers<ContextType>
  BatterySystemState?: BatterySystemStateResolvers<ContextType>
  BatteryIsolation?: BatteryIsolationResolvers<ContextType>
  BatteryContactor?: BatteryContactorResolvers<ContextType>
  BatteryModuleMetricStats?: BatteryModuleMetricStatsResolvers<ContextType>
  BatteryModuleSensorRelativeLocation?: BatteryModuleSensorRelativeLocationResolvers<ContextType>
  BMSBoardInfo?: BmsBoardInfoResolvers<ContextType>
  ChipTemperature?: ChipTemperatureResolvers<ContextType>
  SoftwareVersion?: SoftwareVersionResolvers<ContextType>
  HardwareVersion?: HardwareVersionResolvers<ContextType>
  AggCore?: AggCoreResolvers<ContextType>
  TimeMeasurements?: TimeMeasurementsResolvers<ContextType>
  ContactorDiagnostic?: ContactorDiagnosticResolvers<ContextType>
  MEBTemperatures?: MebTemperaturesResolvers<ContextType>
  MDSStatus?: MdsStatusResolvers<ContextType>
  ChargingInletTemperatures?: ChargingInletTemperaturesResolvers<ContextType>
  OperationalState?: OperationalStateResolvers<ContextType>
  PSBVoltages?: PsbVoltagesResolvers<ContextType>
  BlockDCIRState?: BlockDcirStateResolvers<ContextType>
  Soh?: SohResolvers<ContextType>
  NodeSummary?: NodeSummaryResolvers<ContextType>
  SummaryStats?: SummaryStatsResolvers<ContextType>
  ExceptionSummary?: ExceptionSummaryResolvers<ContextType>
  Command?: CommandResolvers<ContextType>
  CommandMeta?: CommandMetaResolvers<ContextType>
  TestCommand?: TestCommandResolvers<ContextType>
  PingCommand?: PingCommandResolvers<ContextType>
  Condition?: ConditionResolvers<ContextType>
  DummyLocation?: DummyLocationResolvers<ContextType>
  Computer?: ComputerResolvers<ContextType>
  EdgeGateway?: EdgeGatewayResolvers<ContextType>
  EdgeGatewayDeviceInfo?: EdgeGatewayDeviceInfoResolvers<ContextType>
  EdgeGatewayNetworkConn?: EdgeGatewayNetworkConnResolvers<ContextType>
  EdgeGatewayState?: EdgeGatewayStateResolvers<ContextType>
  EdgeGatewayMetrics?: EdgeGatewayMetricsResolvers<ContextType>
  EdgeGatewayPublisherMetrics?: EdgeGatewayPublisherMetricsResolvers<ContextType>
  EdgeGatewaySystemMetrics?: EdgeGatewaySystemMetricsResolvers<ContextType>
  NetworkInterfaceMetrics?: NetworkInterfaceMetricsResolvers<ContextType>
  EdgeGatewayGPUMetrics?: EdgeGatewayGpuMetricsResolvers<ContextType>
  GeoLocation?: GeoLocationResolvers<ContextType>
  NorthvoltIdentity?: NorthvoltIdentityResolvers<ContextType>
  IdentityMeta?: IdentityMetaResolvers<ContextType>
  IDRef?: IdRefResolvers<ContextType>
  LegacyLadybugIdentity?: LegacyLadybugIdentityResolvers<ContextType>
  GQLServerStateResult?: GqlServerStateResultResolvers<ContextType>
  NorthvoltState?: NorthvoltStateResolvers<ContextType>
  StateMeta?: StateMetaResolvers<ContextType>
  TelemetryResult?: TelemetryResultResolvers<ContextType>
  TelemetrySeries?: TelemetrySeriesResolvers<ContextType>
  TimeValue?: TimeValueResolvers<ContextType>
  Milliseconds?: GraphQLScalarType
  Nanoseconds?: GraphQLScalarType
  Int64?: GraphQLScalarType
  Time?: GraphQLScalarType
  Map?: GraphQLScalarType
  Any?: GraphQLScalarType
  URL?: GraphQLScalarType
  StringMap?: GraphQLScalarType
  NVIdentityType?: GraphQLScalarType
  DiagnosticsPage?: DiagnosticsPageResolvers<ContextType>
  PageItem?: PageItemResolvers<ContextType>
  PageItemImage?: PageItemImageResolvers<ContextType>
  PageItemPlotly?: PageItemPlotlyResolvers<ContextType>
  Reel?: ReelResolvers<ContextType>
  PageItemImageReel?: PageItemImageReelResolvers<ContextType>
  PageItemPlotlyReel?: PageItemPlotlyReelResolvers<ContextType>
  PageItemHtml?: PageItemHtmlResolvers<ContextType>
  PageItemHeader?: PageItemHeaderResolvers<ContextType>
  PageItemText?: PageItemTextResolvers<ContextType>
  PageItemLink?: PageItemLinkResolvers<ContextType>
  PageItemMarkdown?: PageItemMarkdownResolvers<ContextType>
}

export type DirectiveResolvers<ContextType = any> = {
  json?: JsonDirectiveResolver<any, any, ContextType>
  link?: LinkDirectiveResolver<any, any, ContextType>
  require?: RequireDirectiveResolver<any, any, ContextType>
  typeMarshal?: TypeMarshalDirectiveResolver<any, any, ContextType>
  resolve?: ResolveDirectiveResolver<any, any, ContextType>
  oneOfRoles?: OneOfRolesDirectiveResolver<any, any, ContextType>
  external?: ExternalDirectiveResolver<any, any, ContextType>
  goField?: GoFieldDirectiveResolver<any, any, ContextType>
}

export type SubpackCardFragment = {
  readonly kind?: BatterySystemKind | null
  readonly generation?: BatterySystemGeneration | null
  readonly displayName?: string | null
  readonly observed?: string | null
  readonly id: string
  readonly meta: { readonly tenant: string }
  readonly state?: {
    readonly operationalStateOfCharge?: number | null
    readonly position?: number | null
    readonly stateOfCharge?: number | null
    readonly externalVoltage?: number | null
    readonly voltage?: number | null
    readonly current?: number | null
    readonly softwareVersions?: ReadonlyArray<{
      readonly version: string
      readonly software: string
    }> | null
    readonly cellTemperatureStats?: {
      readonly max?: number | null
      readonly min?: number | null
    } | null
    readonly isolation?: { readonly limit?: number | null; readonly value?: number | null } | null
    readonly cellVoltageStats?: {
      readonly max?: number | null
      readonly min?: number | null
      readonly mean?: number | null
    } | null
  } | null
  readonly faults?: {
    readonly list?: ReadonlyArray<{
      readonly dtcSpn: number
      readonly isActive?: boolean | null
      readonly type?: number | null
    }> | null
  } | null
  readonly parentSystem?: {
    readonly state?: { readonly operationalStateOfCharge?: number | null } | null
  } | null
}

export type DevelopmentSystemsListQueryVariables = Exact<{
  filter: BatteryFilter
}>

export type DevelopmentSystemsListQuery = {
  readonly systems: {
    readonly items: ReadonlyArray<{
      readonly id: string
      readonly displayName?: string | null
      readonly kind?: BatterySystemKind | null
      readonly tags: ReadonlyArray<string>
    }>
  }
}

export type SystemFaultsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SystemFaultsQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly kind?: BatterySystemKind | null
    readonly generation?: BatterySystemGeneration | null
    readonly state?: { readonly position?: number | null } | null
    readonly faults?: {
      readonly list?: ReadonlyArray<{
        readonly dtcSpn: number
        readonly isActive?: boolean | null
        readonly type?: number | null
        readonly occurrenceCount?: number | null
        readonly lastOccurredAt?: any | null
        readonly firstOccurredAt?: any | null
        readonly timeSinceCurrentPowerCycleStart?: number | null
        readonly errorTriggers?: ReadonlyArray<{
          readonly name?: string | null
          readonly code: number
          readonly index?: number | null
          readonly value?: number | null
        }> | null
      }> | null
    } | null
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly kind?: BatterySystemKind | null
      readonly generation?: BatterySystemGeneration | null
      readonly displayName?: string | null
      readonly faults?: {
        readonly list?: ReadonlyArray<{
          readonly dtcSpn: number
          readonly isActive?: boolean | null
          readonly type?: number | null
          readonly occurrenceCount?: number | null
          readonly lastOccurredAt?: any | null
          readonly firstOccurredAt?: any | null
          readonly timeSinceCurrentPowerCycleStart?: number | null
          readonly errorTriggers?: ReadonlyArray<{
            readonly name?: string | null
            readonly code: number
            readonly index?: number | null
            readonly value?: number | null
          }> | null
        }> | null
      } | null
      readonly state?: { readonly position?: number | null } | null
      readonly subSystems?: ReadonlyArray<{
        readonly kind?: BatterySystemKind | null
        readonly generation?: BatterySystemGeneration | null
        readonly faults?: {
          readonly list?: ReadonlyArray<{
            readonly dtcSpn: number
            readonly isActive?: boolean | null
            readonly type?: number | null
            readonly occurrenceCount?: number | null
            readonly lastOccurredAt?: any | null
            readonly firstOccurredAt?: any | null
            readonly timeSinceCurrentPowerCycleStart?: number | null
          }> | null
        } | null
      } | null> | null
    } | null> | null
  }
}

export type FaultCardFragment = {
  readonly dtcSpn: number
  readonly occurrenceCount?: number | null
  readonly firstOccurredAt?: any | null
  readonly lastOccurredAt?: any | null
  readonly timeSinceCurrentPowerCycleStart?: number | null
  readonly type?: number | null
  readonly isActive?: boolean | null
}

export type FaultInfoTriggerFragment = {
  readonly name?: string | null
  readonly code: number
  readonly index?: number | null
  readonly value?: number | null
}

export type FaultInfoFragment = {
  readonly dtcSpn: number
  readonly type?: number | null
  readonly occurrenceCount?: number | null
  readonly firstOccurredAt?: any | null
  readonly lastOccurredAt?: any | null
  readonly timeSinceCurrentPowerCycleStart?: number | null
  readonly isActive?: boolean | null
  readonly errorTriggers?: ReadonlyArray<{
    readonly name?: string | null
    readonly code: number
    readonly index?: number | null
    readonly value?: number | null
  }> | null
}

export type FaultPageFragment = {
  readonly dtcSpn: number
  readonly type?: number | null
  readonly occurrenceCount?: number | null
  readonly firstOccurredAt?: any | null
  readonly lastOccurredAt?: any | null
  readonly timeSinceCurrentPowerCycleStart?: number | null
  readonly isActive?: boolean | null
  readonly errorTriggers?: ReadonlyArray<{
    readonly name?: string | null
    readonly code: number
    readonly index?: number | null
    readonly value?: number | null
  }> | null
}

export type FaultCardListFragment = {
  readonly kind?: BatterySystemKind | null
  readonly generation?: BatterySystemGeneration | null
  readonly faults?: {
    readonly list?: ReadonlyArray<{
      readonly dtcSpn: number
      readonly occurrenceCount?: number | null
      readonly firstOccurredAt?: any | null
      readonly lastOccurredAt?: any | null
      readonly timeSinceCurrentPowerCycleStart?: number | null
      readonly type?: number | null
      readonly isActive?: boolean | null
    }> | null
  } | null
  readonly subSystems?: ReadonlyArray<{
    readonly kind?: BatterySystemKind | null
    readonly generation?: BatterySystemGeneration | null
    readonly faults?: {
      readonly list?: ReadonlyArray<{
        readonly dtcSpn: number
        readonly occurrenceCount?: number | null
        readonly firstOccurredAt?: any | null
        readonly lastOccurredAt?: any | null
        readonly timeSinceCurrentPowerCycleStart?: number | null
        readonly type?: number | null
        readonly isActive?: boolean | null
      }> | null
    } | null
  } | null> | null
}

export type FilterableFaultFragment = {
  readonly dtcSpn: number
  readonly isActive?: boolean | null
  readonly type?: number | null
  readonly occurrenceCount?: number | null
  readonly lastOccurredAt?: any | null
  readonly firstOccurredAt?: any | null
  readonly timeSinceCurrentPowerCycleStart?: number | null
}

export type FaultCardListVolthubFragment = {
  readonly kind?: BatterySystemKind | null
  readonly generation?: BatterySystemGeneration | null
  readonly faults?: {
    readonly list?: ReadonlyArray<{
      readonly dtcSpn: number
      readonly isActive?: boolean | null
      readonly type?: number | null
      readonly occurrenceCount?: number | null
      readonly lastOccurredAt?: any | null
      readonly firstOccurredAt?: any | null
      readonly timeSinceCurrentPowerCycleStart?: number | null
    }> | null
  } | null
  readonly subSystems?: ReadonlyArray<{
    readonly kind?: BatterySystemKind | null
    readonly generation?: BatterySystemGeneration | null
    readonly faults?: {
      readonly list?: ReadonlyArray<{
        readonly dtcSpn: number
        readonly isActive?: boolean | null
        readonly type?: number | null
        readonly occurrenceCount?: number | null
        readonly lastOccurredAt?: any | null
        readonly firstOccurredAt?: any | null
        readonly timeSinceCurrentPowerCycleStart?: number | null
      }> | null
    } | null
  } | null> | null
}

export type FaultCardListVoltpackSubsystemFragment = {
  readonly id: string
  readonly kind?: BatterySystemKind | null
  readonly generation?: BatterySystemGeneration | null
  readonly displayName?: string | null
  readonly state?: { readonly position?: number | null } | null
  readonly faults?: {
    readonly list?: ReadonlyArray<{
      readonly dtcSpn: number
      readonly isActive?: boolean | null
    }> | null
  } | null
}

export type FaultCardListVoltpackFragment = {
  readonly kind?: BatterySystemKind | null
  readonly generation?: BatterySystemGeneration | null
  readonly faults?: {
    readonly list?: ReadonlyArray<{
      readonly dtcSpn: number
      readonly isActive?: boolean | null
      readonly type?: number | null
      readonly occurrenceCount?: number | null
      readonly lastOccurredAt?: any | null
      readonly firstOccurredAt?: any | null
      readonly timeSinceCurrentPowerCycleStart?: number | null
    }> | null
  } | null
  readonly subSystems?: ReadonlyArray<{
    readonly kind?: BatterySystemKind | null
    readonly generation?: BatterySystemGeneration | null
    readonly faults?: {
      readonly list?: ReadonlyArray<{
        readonly dtcSpn: number
        readonly occurrenceCount?: number | null
        readonly firstOccurredAt?: any | null
        readonly lastOccurredAt?: any | null
        readonly timeSinceCurrentPowerCycleStart?: number | null
        readonly type?: number | null
        readonly isActive?: boolean | null
      }> | null
    } | null
  } | null> | null
}

export type FaultCardListVoltpackCoreFragment = {
  readonly kind?: BatterySystemKind | null
  readonly generation?: BatterySystemGeneration | null
  readonly faults?: {
    readonly list?: ReadonlyArray<{
      readonly dtcSpn: number
      readonly isActive?: boolean | null
      readonly type?: number | null
      readonly occurrenceCount?: number | null
      readonly lastOccurredAt?: any | null
      readonly firstOccurredAt?: any | null
      readonly timeSinceCurrentPowerCycleStart?: number | null
    }> | null
  } | null
  readonly subSystems?: ReadonlyArray<{
    readonly kind?: BatterySystemKind | null
    readonly generation?: BatterySystemGeneration | null
    readonly faults?: {
      readonly list?: ReadonlyArray<{
        readonly dtcSpn: number
        readonly isActive?: boolean | null
        readonly type?: number | null
        readonly occurrenceCount?: number | null
        readonly lastOccurredAt?: any | null
        readonly firstOccurredAt?: any | null
        readonly timeSinceCurrentPowerCycleStart?: number | null
      }> | null
    } | null
  } | null> | null
}

export type ChargingFaultsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ChargingFaultsQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly kind?: BatterySystemKind | null
    readonly generation?: BatterySystemGeneration | null
    readonly faults?: {
      readonly list?: ReadonlyArray<{
        readonly dtcSpn: number
        readonly occurrenceCount?: number | null
        readonly firstOccurredAt?: any | null
        readonly lastOccurredAt?: any | null
        readonly timeSinceCurrentPowerCycleStart?: number | null
        readonly type?: number | null
        readonly isActive?: boolean | null
      }> | null
    } | null
  }
}

export type ContactorDiagnosticsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ContactorDiagnosticsQuery = {
  readonly batterySystem: {
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly state?: {
        readonly position?: number | null
        readonly contactorDiagnostic?: {
          readonly bValue?: number | null
          readonly calibrationConditionsMet?: boolean | null
          readonly calculatedCValueNegative?: ReadonlyArray<number | null> | null
          readonly calculatedCValuePositive?: ReadonlyArray<number | null> | null
          readonly calculatedCValuePrecharge?: ReadonlyArray<number | null> | null
          readonly averageCValueNegative?: number | null
          readonly averageCValuePositive?: number | null
          readonly averageCValuePrecharge?: number | null
          readonly calibrationTemperature?: number | null
          readonly calibrationVoltageNegative?: ReadonlyArray<number | null> | null
          readonly calibrationVoltagePositive?: ReadonlyArray<number | null> | null
          readonly calibrationVoltagePrecharge?: ReadonlyArray<number | null> | null
          readonly calibrationTimingsNegative?: ReadonlyArray<number | null> | null
          readonly calibrationTimingsPositive?: ReadonlyArray<number | null> | null
          readonly calibrationTimingsPrecharge?: ReadonlyArray<number | null> | null
          readonly calibrationStatus?: number | null
          readonly lastAttemptedCalibrationTime?: number | null
          readonly lastSuccessfulCalibrationTime?: number | null
          readonly milliVoltageBeforeClosingNegative?: number | null
          readonly milliVoltageBeforeClosingPositive?: number | null
          readonly milliVoltageBeforeClosingPrecharge?: number | null
          readonly milliVoltageAfterClosingNegative?: number | null
          readonly milliVoltageAfterClosingPositive?: number | null
          readonly milliVoltageAfterClosingPrecharge?: number | null
          readonly milliVoltageDuringClosingNegative?: number | null
          readonly milliVoltageDuringClosingPositive?: number | null
          readonly milliVoltageDuringClosingPrecharge?: number | null
          readonly estimatedContactorTemperature?: number | null
          readonly nominalClosingTimePositive?: number | null
          readonly nominalClosingTimeNegative?: number | null
          readonly actualClosingTimePositive?: number | null
          readonly actualClosingTimeNegative?: number | null
          readonly actualClosingTimePrecharge?: number | null
          readonly diagnosticStatusNOKCounter?: number | null
          readonly diagnosticDisabled?: boolean | null
          readonly calibrationRequested?: boolean | null
          readonly calibrationAbortRequested?: boolean | null
          readonly weldedPositiveContactorCounter?: number | null
          readonly weldedNegativeContactorCounter?: number | null
        } | null
        readonly bmsBoard?: { readonly supplyVoltage?: number | null } | null
      } | null
    } | null> | null
  }
}

export type BatterySystemControlQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type BatterySystemControlQuery = {
  readonly batterySystem: {
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly connectivityId?: string | null
      readonly displayName?: string | null
      readonly kind?: BatterySystemKind | null
      readonly observed?: string | null
      readonly state?: {
        readonly position?: number | null
        readonly isContactorSohErrorDisabled?: boolean | null
      } | null
    } | null> | null
  }
}

export type FirmwareCoreQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type FirmwareCoreQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly kind?: BatterySystemKind | null
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly displayName?: string | null
      readonly kind?: BatterySystemKind | null
      readonly state?: { readonly position?: number | null } | null
    } | null> | null
  }
}

export type LocalRedirectQueryVariables = Exact<{ [key: string]: never }>

export type LocalRedirectQuery = {
  readonly batterySystems: { readonly items: ReadonlyArray<{ readonly id: string }> }
}

export type CoreSettingsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CoreSettingsQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly displayName?: string | null
      readonly kind?: BatterySystemKind | null
      readonly state?: { readonly position?: number | null } | null
    } | null> | null
  }
}

export type SoftwareVersionsFragment = {
  readonly state?: {
    readonly softwareVersions?: ReadonlyArray<{
      readonly software: string
      readonly version: string
    }> | null
  } | null
}

export type SoftwareUpgradeQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SoftwareUpgradeQuery = {
  readonly batterySystem: {
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly connectivityId?: string | null
      readonly displayName?: string | null
      readonly kind?: BatterySystemKind | null
      readonly observed?: string | null
      readonly state?: {
        readonly position?: number | null
        readonly isContactorSohErrorDisabled?: boolean | null
        readonly softwareVersions?: ReadonlyArray<{
          readonly software: string
          readonly version: string
        }> | null
      } | null
    } | null> | null
  }
}

export type TestFaultsFragment = {
  readonly kind?: BatterySystemKind | null
  readonly generation?: BatterySystemGeneration | null
  readonly faults?: {
    readonly list?: ReadonlyArray<{
      readonly dtcSpn: number
      readonly isActive?: boolean | null
      readonly type?: number | null
      readonly occurrenceCount?: number | null
      readonly lastOccurredAt?: any | null
      readonly firstOccurredAt?: any | null
      readonly timeSinceCurrentPowerCycleStart?: number | null
    }> | null
  } | null
  readonly subSystems?: ReadonlyArray<{
    readonly kind?: BatterySystemKind | null
    readonly generation?: BatterySystemGeneration | null
    readonly faults?: {
      readonly list?: ReadonlyArray<{
        readonly dtcSpn: number
        readonly occurrenceCount?: number | null
        readonly firstOccurredAt?: any | null
        readonly lastOccurredAt?: any | null
        readonly timeSinceCurrentPowerCycleStart?: number | null
        readonly type?: number | null
        readonly isActive?: boolean | null
      }> | null
    } | null
  } | null> | null
}

export type TestsFragment = {
  readonly id: string
  readonly kind?: BatterySystemKind | null
  readonly generation?: BatterySystemGeneration | null
  readonly state?: {
    readonly isolation?: { readonly value?: number | null; readonly limit?: number | null } | null
  } | null
  readonly subSystems?: ReadonlyArray<{
    readonly id: string
    readonly kind?: BatterySystemKind | null
    readonly generation?: BatterySystemGeneration | null
    readonly faults?: {
      readonly list?: ReadonlyArray<{
        readonly dtcSpn: number
        readonly isActive?: boolean | null
        readonly type?: number | null
        readonly occurrenceCount?: number | null
        readonly lastOccurredAt?: any | null
        readonly firstOccurredAt?: any | null
        readonly timeSinceCurrentPowerCycleStart?: number | null
      }> | null
    } | null
    readonly subSystems?: ReadonlyArray<{
      readonly kind?: BatterySystemKind | null
      readonly generation?: BatterySystemGeneration | null
      readonly faults?: {
        readonly list?: ReadonlyArray<{
          readonly dtcSpn: number
          readonly occurrenceCount?: number | null
          readonly firstOccurredAt?: any | null
          readonly lastOccurredAt?: any | null
          readonly timeSinceCurrentPowerCycleStart?: number | null
          readonly type?: number | null
          readonly isActive?: boolean | null
        }> | null
      } | null
    } | null> | null
  } | null> | null
  readonly faults?: {
    readonly list?: ReadonlyArray<{
      readonly dtcSpn: number
      readonly isActive?: boolean | null
      readonly type?: number | null
      readonly occurrenceCount?: number | null
      readonly lastOccurredAt?: any | null
      readonly firstOccurredAt?: any | null
      readonly timeSinceCurrentPowerCycleStart?: number | null
    }> | null
  } | null
}

export type VoltpackSelfTestDataQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type VoltpackSelfTestDataQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly kind?: BatterySystemKind | null
    readonly generation?: BatterySystemGeneration | null
    readonly state?: {
      readonly isolation?: { readonly value?: number | null; readonly limit?: number | null } | null
    } | null
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly kind?: BatterySystemKind | null
      readonly generation?: BatterySystemGeneration | null
      readonly faults?: {
        readonly list?: ReadonlyArray<{
          readonly dtcSpn: number
          readonly isActive?: boolean | null
          readonly type?: number | null
          readonly occurrenceCount?: number | null
          readonly lastOccurredAt?: any | null
          readonly firstOccurredAt?: any | null
          readonly timeSinceCurrentPowerCycleStart?: number | null
        }> | null
      } | null
      readonly subSystems?: ReadonlyArray<{
        readonly kind?: BatterySystemKind | null
        readonly generation?: BatterySystemGeneration | null
        readonly faults?: {
          readonly list?: ReadonlyArray<{
            readonly dtcSpn: number
            readonly occurrenceCount?: number | null
            readonly firstOccurredAt?: any | null
            readonly lastOccurredAt?: any | null
            readonly timeSinceCurrentPowerCycleStart?: number | null
            readonly type?: number | null
            readonly isActive?: boolean | null
          }> | null
        } | null
      } | null> | null
    } | null> | null
    readonly faults?: {
      readonly list?: ReadonlyArray<{
        readonly dtcSpn: number
        readonly isActive?: boolean | null
        readonly type?: number | null
        readonly occurrenceCount?: number | null
        readonly lastOccurredAt?: any | null
        readonly firstOccurredAt?: any | null
        readonly timeSinceCurrentPowerCycleStart?: number | null
      }> | null
    } | null
  }
}

export type TestsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type TestsQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly connectivityId?: string | null
      readonly displayName?: string | null
      readonly kind?: BatterySystemKind | null
      readonly observed?: string | null
      readonly state?: {
        readonly position?: number | null
        readonly isContactorSohErrorDisabled?: boolean | null
      } | null
    } | null> | null
  }
}

export type ModuleCardFragment = {
  readonly id: string
  readonly state?: {
    readonly position?: number | null
    readonly cellVoltages?: ReadonlyArray<number | null> | null
    readonly cellTemperatures?: ReadonlyArray<number | null> | null
  } | null
}

export type ModuleVoltageHeatMapFragment = {
  readonly id: string
  readonly state?: { readonly cellVoltages?: ReadonlyArray<number | null> | null } | null
}

export type ModuleTemperatureHeatMapFragment = {
  readonly id: string
  readonly state?: { readonly cellTemperatures?: ReadonlyArray<number | null> | null } | null
}

export type ModulesQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ModulesQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly observed?: string | null
    readonly generation?: BatterySystemGeneration | null
    readonly parentSystem?: { readonly id: string } | null
    readonly modules?: ReadonlyArray<{
      readonly id: string
      readonly state?: {
        readonly position?: number | null
        readonly cellVoltages?: ReadonlyArray<number | null> | null
        readonly cellTemperatures?: ReadonlyArray<number | null> | null
        readonly isBalancingCells?: ReadonlyArray<boolean | null> | null
        readonly cellBalancingTemperatures?: ReadonlyArray<number | null> | null
        readonly dcir?: number | null
        readonly cellBatchNumber?: string | null
        readonly cellCapacityGrade?: number | null
      } | null
    } | null> | null
  }
}

export type ModulesFragment = {
  readonly id: string
  readonly state?: {
    readonly position?: number | null
    readonly cellVoltages?: ReadonlyArray<number | null> | null
    readonly cellTemperatures?: ReadonlyArray<number | null> | null
    readonly isBalancingCells?: ReadonlyArray<boolean | null> | null
    readonly cellBalancingTemperatures?: ReadonlyArray<number | null> | null
    readonly dcir?: number | null
    readonly cellBatchNumber?: string | null
    readonly cellCapacityGrade?: number | null
  } | null
}

export type ModulesCardListFragment = {
  readonly id: string
  readonly state?: {
    readonly position?: number | null
    readonly cellVoltages?: ReadonlyArray<number | null> | null
    readonly cellTemperatures?: ReadonlyArray<number | null> | null
  } | null
}

export type ModulesCellsVoltageChartFragment = {
  readonly id: string
  readonly state?: {
    readonly position?: number | null
    readonly cellVoltages?: ReadonlyArray<number | null> | null
  } | null
}

export type ModulesBalancingTemperaturesChartFragment = {
  readonly id: string
  readonly state?: {
    readonly position?: number | null
    readonly isBalancingCells?: ReadonlyArray<boolean | null> | null
    readonly cellBalancingTemperatures?: ReadonlyArray<number | null> | null
  } | null
}

export type ModulesCellTypeIdFragment = {
  readonly id: string
  readonly state?: {
    readonly dcir?: number | null
    readonly cellBatchNumber?: string | null
    readonly cellCapacityGrade?: number | null
    readonly position?: number | null
  } | null
}

export type ModulesCellsTemperatureChartFragment = {
  readonly id: string
  readonly state?: {
    readonly position?: number | null
    readonly cellTemperatures?: ReadonlyArray<number | null> | null
  } | null
}

export type ModulesVoltageComparisonChartFragment = {
  readonly id: string
  readonly state?: {
    readonly position?: number | null
    readonly cellVoltages?: ReadonlyArray<number | null> | null
  } | null
}

export type ModulesTemperatureComparisonChartFragment = {
  readonly id: string
  readonly state?: {
    readonly position?: number | null
    readonly cellTemperatures?: ReadonlyArray<number | null> | null
  } | null
}

export type Battery360QueryVariables = Exact<{
  id: Scalars['ID']['input']
  version: Scalars['String']['input']
}>

export type Battery360Query = { readonly battery360?: string | null }

export type DiagnosticsPagesQueryVariables = Exact<{
  systemId: Scalars['ID']['input']
  systemKind: BatterySystemKind
}>

export type DiagnosticsPagesQuery = {
  readonly diagnosticsPages: ReadonlyArray<{
    readonly systemId: string
    readonly systemKind: BatterySystemKind
    readonly id: string
    readonly title: string
  }>
}

export type DiagnosticsPageQueryVariables = Exact<{
  systemId: Scalars['ID']['input']
  systemKind: BatterySystemKind
  id: Scalars['ID']['input']
}>

export type DiagnosticsPageQuery = {
  readonly diagnosticsPage?: {
    readonly systemId: string
    readonly systemKind: BatterySystemKind
    readonly id: string
    readonly title: string
    readonly content: ReadonlyArray<
      | {
          readonly __typename: 'PageItemImage'
          readonly title?: string | null
          readonly description?: string | null
          readonly path: string
        }
      | {
          readonly __typename: 'PageItemPlotly'
          readonly title?: string | null
          readonly description?: string | null
          readonly path: string
        }
      | {
          readonly __typename: 'PageItemPlotlyReel'
          readonly initialPosition: ReelInitialPositionEnum
          readonly reel: ReadonlyArray<{
            readonly title?: string | null
            readonly description?: string | null
            readonly path: string
          }>
        }
      | {
          readonly __typename: 'PageItemImageReel'
          readonly initialPosition: ReelInitialPositionEnum
          readonly reel: ReadonlyArray<{
            readonly title?: string | null
            readonly description?: string | null
            readonly path: string
          }>
        }
      | { readonly __typename: 'PageItemHtml'; readonly html: string }
      | { readonly __typename: 'PageItemHeader'; readonly header: string; readonly level: number }
      | { readonly __typename: 'PageItemText'; readonly text: string }
      | { readonly __typename: 'PageItemLink'; readonly url: string; readonly text: string }
      | { readonly __typename: 'PageItemMarkdown'; readonly text: string }
    >
  } | null
}

export type HubVersionsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type HubVersionsQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly generation?: BatterySystemGeneration | null
    readonly displayName?: string | null
    readonly kind?: BatterySystemKind | null
    readonly meta: { readonly tenant: string }
    readonly computers?: ReadonlyArray<
      | { readonly __typename: 'BatteryMasterUnit'; readonly id: string }
      | { readonly __typename: 'EdgeGateway'; readonly id: string }
    > | null
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly computers?: ReadonlyArray<
        | { readonly __typename: 'BatteryMasterUnit'; readonly id: string }
        | { readonly __typename: 'EdgeGateway'; readonly id: string }
      > | null
      readonly state?: {
        readonly position?: number | null
        readonly hardwareVersions?: ReadonlyArray<{
          readonly hardware: string
          readonly version: string
        }> | null
        readonly softwareVersions?: ReadonlyArray<{
          readonly software: string
          readonly version: string
        }> | null
      } | null
    } | null> | null
    readonly state?: {
      readonly position?: number | null
      readonly hardwareVersions?: ReadonlyArray<{
        readonly hardware: string
        readonly version: string
      }> | null
      readonly softwareVersions?: ReadonlyArray<{
        readonly software: string
        readonly version: string
      }> | null
    } | null
  }
}

export type HubRtdbQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type HubRtdbQuery = {
  readonly batterySystem: {
    readonly state?: { readonly hubState?: { readonly rtdbVariables: any } | null } | null
  }
}

export type InsightsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type InsightsQuery = {
  readonly system: {
    readonly id: string
    readonly displayName?: string | null
    readonly kind?: BatterySystemKind | null
    readonly parentSystem?: {
      readonly id: string
      readonly state?: { readonly operationalStateOfCharge?: number | null } | null
    } | null
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly connectivityId?: string | null
      readonly displayName?: string | null
      readonly kind?: BatterySystemKind | null
      readonly observed?: string | null
      readonly state?: {
        readonly position?: number | null
        readonly isContactorSohErrorDisabled?: boolean | null
        readonly softwareVersions?: ReadonlyArray<{
          readonly software: string
          readonly version: string
        }> | null
      } | null
    } | null> | null
    readonly state?: {
      readonly operationalStateOfCharge?: number | null
      readonly stateOfCharge?: number | null
      readonly softwareVersions?: ReadonlyArray<{
        readonly software: string
        readonly version: string
      }> | null
    } | null
  }
}

export type MetricsDataQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type MetricsDataQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly generation?: BatterySystemGeneration | null
    readonly displayName?: string | null
    readonly kind?: BatterySystemKind | null
    readonly meta: { readonly tenant: string }
    readonly modules?: ReadonlyArray<{
      readonly id: string
      readonly state?: { readonly position?: number | null } | null
    } | null> | null
    readonly parentSystem?: {
      readonly id: string
      readonly state?: { readonly operationalStateOfCharge?: number | null } | null
    } | null
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly state?: {
        readonly position?: number | null
        readonly isContactorSohErrorDisabled?: boolean | null
        readonly contactors: ReadonlyArray<{
          readonly kind: ContactorKind
          readonly isOpen?: boolean | null
          readonly stateOfHealth?: number | null
        }>
        readonly softwareVersions?: ReadonlyArray<{
          readonly software: string
          readonly version: string
        }> | null
      } | null
    } | null> | null
    readonly state?: {
      readonly lifetimeEquivalentCycleCount?: number | null
      readonly lifetimeCycledWh?: number | null
      readonly lifetimeChargedWh?: number | null
      readonly lifetimeChargedAh?: number | null
      readonly lifetimeDischargedWh?: number | null
      readonly lifetimeDischargedAh?: number | null
      readonly lifetimeChargerChargedWh?: number | null
      readonly voltage?: number | null
      readonly current?: number | null
      readonly externalVoltage?: number | null
      readonly resistanceEstimateOhms?: number | null
      readonly capacityEstimateAh?: number | null
      readonly stateOfHealthV2?: number | null
      readonly stateOfHealthV3?: number | null
      readonly chargeCapacityEstimate?: number | null
      readonly dischargeCapacityEstimate?: number | null
      readonly resistanceEstimate?: number | null
      readonly isContactorSohErrorDisabled?: boolean | null
      readonly operationalStateOfCharge?: number | null
      readonly stateOfCharge?: number | null
      readonly isolation?: {
        readonly value?: number | null
        readonly valueOhms?: number | null
        readonly limit?: number | null
      } | null
      readonly contactors: ReadonlyArray<{
        readonly kind: ContactorKind
        readonly isOpen?: boolean | null
        readonly stateOfHealth?: number | null
      }>
      readonly bmsBoard?: { readonly supplyVoltage?: number | null } | null
      readonly cellTemperatureStats?: {
        readonly min?: number | null
        readonly max?: number | null
      } | null
      readonly cellVoltageStats?: {
        readonly min?: number | null
        readonly mean?: number | null
        readonly max?: number | null
      } | null
      readonly chargingInletTemperatures?: {
        readonly vcvccuPtc1Temperature?: number | null
        readonly vcvccuPtc2Temperature?: number | null
      } | null
      readonly softwareVersions?: ReadonlyArray<{
        readonly software: string
        readonly version: string
      }> | null
    } | null
  }
}

export type MetricsFragment = {
  readonly id: string
  readonly generation?: BatterySystemGeneration | null
  readonly displayName?: string | null
  readonly kind?: BatterySystemKind | null
  readonly meta: { readonly tenant: string }
  readonly modules?: ReadonlyArray<{
    readonly id: string
    readonly state?: { readonly position?: number | null } | null
  } | null> | null
  readonly parentSystem?: {
    readonly id: string
    readonly state?: { readonly operationalStateOfCharge?: number | null } | null
  } | null
  readonly subSystems?: ReadonlyArray<{
    readonly id: string
    readonly state?: {
      readonly position?: number | null
      readonly isContactorSohErrorDisabled?: boolean | null
      readonly contactors: ReadonlyArray<{
        readonly kind: ContactorKind
        readonly isOpen?: boolean | null
        readonly stateOfHealth?: number | null
      }>
      readonly softwareVersions?: ReadonlyArray<{
        readonly software: string
        readonly version: string
      }> | null
    } | null
  } | null> | null
  readonly state?: {
    readonly lifetimeEquivalentCycleCount?: number | null
    readonly lifetimeCycledWh?: number | null
    readonly lifetimeChargedWh?: number | null
    readonly lifetimeChargedAh?: number | null
    readonly lifetimeDischargedWh?: number | null
    readonly lifetimeDischargedAh?: number | null
    readonly lifetimeChargerChargedWh?: number | null
    readonly voltage?: number | null
    readonly current?: number | null
    readonly externalVoltage?: number | null
    readonly resistanceEstimateOhms?: number | null
    readonly capacityEstimateAh?: number | null
    readonly stateOfHealthV2?: number | null
    readonly stateOfHealthV3?: number | null
    readonly chargeCapacityEstimate?: number | null
    readonly dischargeCapacityEstimate?: number | null
    readonly resistanceEstimate?: number | null
    readonly isContactorSohErrorDisabled?: boolean | null
    readonly operationalStateOfCharge?: number | null
    readonly stateOfCharge?: number | null
    readonly isolation?: {
      readonly value?: number | null
      readonly valueOhms?: number | null
      readonly limit?: number | null
    } | null
    readonly contactors: ReadonlyArray<{
      readonly kind: ContactorKind
      readonly isOpen?: boolean | null
      readonly stateOfHealth?: number | null
    }>
    readonly bmsBoard?: { readonly supplyVoltage?: number | null } | null
    readonly cellTemperatureStats?: {
      readonly min?: number | null
      readonly max?: number | null
    } | null
    readonly cellVoltageStats?: {
      readonly min?: number | null
      readonly mean?: number | null
      readonly max?: number | null
    } | null
    readonly chargingInletTemperatures?: {
      readonly vcvccuPtc1Temperature?: number | null
      readonly vcvccuPtc2Temperature?: number | null
    } | null
    readonly softwareVersions?: ReadonlyArray<{
      readonly software: string
      readonly version: string
    }> | null
  } | null
}

export type IdentifiersQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type IdentifiersQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly connectivityId?: string | null
    readonly displayName?: string | null
    readonly kind?: BatterySystemKind | null
    readonly observed?: string | null
    readonly meta: { readonly tenant: string }
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly connectivityId?: string | null
      readonly displayName?: string | null
      readonly kind?: BatterySystemKind | null
      readonly observed?: string | null
      readonly state?: {
        readonly position?: number | null
        readonly isContactorSohErrorDisabled?: boolean | null
      } | null
    } | null> | null
    readonly state?: {
      readonly position?: number | null
      readonly isContactorSohErrorDisabled?: boolean | null
    } | null
  }
}

export type PageHeadingQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type PageHeadingQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly connectivityId?: string | null
    readonly displayName?: string | null
    readonly kind?: BatterySystemKind | null
    readonly observed?: string | null
    readonly parentSystem?: {
      readonly id: string
      readonly connectivityId?: string | null
      readonly displayName?: string | null
      readonly kind?: BatterySystemKind | null
      readonly observed?: string | null
      readonly parentSystem?: {
        readonly id: string
        readonly connectivityId?: string | null
        readonly displayName?: string | null
        readonly kind?: BatterySystemKind | null
        readonly observed?: string | null
        readonly state?: {
          readonly position?: number | null
          readonly isContactorSohErrorDisabled?: boolean | null
        } | null
      } | null
      readonly subSystems?: ReadonlyArray<{
        readonly id: string
        readonly connectivityId?: string | null
        readonly displayName?: string | null
        readonly kind?: BatterySystemKind | null
        readonly observed?: string | null
        readonly state?: {
          readonly position?: number | null
          readonly isContactorSohErrorDisabled?: boolean | null
        } | null
      } | null> | null
      readonly state?: {
        readonly position?: number | null
        readonly isContactorSohErrorDisabled?: boolean | null
      } | null
    } | null
    readonly state?: {
      readonly position?: number | null
      readonly isContactorSohErrorDisabled?: boolean | null
    } | null
  }
}

export type SubpacksListOrGridQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SubpacksListOrGridQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly subSystems?: ReadonlyArray<{
      readonly generation?: BatterySystemGeneration | null
      readonly kind?: BatterySystemKind | null
      readonly displayName?: string | null
      readonly observed?: string | null
      readonly id: string
      readonly meta: { readonly tenant: string }
      readonly state?: {
        readonly operationalStateOfCharge?: number | null
        readonly position?: number | null
        readonly stateOfCharge?: number | null
        readonly externalVoltage?: number | null
        readonly voltage?: number | null
        readonly current?: number | null
        readonly softwareVersions?: ReadonlyArray<{
          readonly version: string
          readonly software: string
        }> | null
        readonly cellTemperatureStats?: {
          readonly max?: number | null
          readonly min?: number | null
        } | null
        readonly isolation?: {
          readonly limit?: number | null
          readonly value?: number | null
        } | null
        readonly cellVoltageStats?: {
          readonly max?: number | null
          readonly min?: number | null
          readonly mean?: number | null
        } | null
      } | null
      readonly faults?: {
        readonly list?: ReadonlyArray<{
          readonly dtcSpn: number
          readonly isActive?: boolean | null
          readonly type?: number | null
        }> | null
      } | null
      readonly parentSystem?: {
        readonly state?: { readonly operationalStateOfCharge?: number | null } | null
      } | null
    } | null> | null
  }
}

export type SubpackMetricsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SubpackMetricsQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly observed?: string | null
      readonly state?: {
        readonly position?: number | null
        readonly stateOfCharge?: number | null
        readonly voltage?: number | null
        readonly current?: number | null
        readonly cellTemperatureStats?: {
          readonly min?: number | null
          readonly max?: number | null
        } | null
        readonly cellVoltageStats?: {
          readonly min?: number | null
          readonly mean?: number | null
          readonly max?: number | null
        } | null
      } | null
    } | null> | null
  }
}

export type SubpacksTableQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SubpacksTableQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly generation?: BatterySystemGeneration | null
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly displayName?: string | null
      readonly observed?: string | null
      readonly meta: { readonly tenant: string }
      readonly state?: {
        readonly position?: number | null
        readonly stateOfCharge?: number | null
        readonly voltage?: number | null
        readonly current?: number | null
        readonly cellTemperatureStats?: {
          readonly min?: number | null
          readonly max?: number | null
          readonly mean?: number | null
        } | null
        readonly cellVoltageStats?: {
          readonly min?: number | null
          readonly max?: number | null
          readonly mean?: number | null
        } | null
        readonly isolation?: {
          readonly value?: number | null
          readonly limit?: number | null
        } | null
      } | null
    } | null> | null
  }
}

export type SubpacksTableParentSystemFragment = {
  readonly id: string
  readonly generation?: BatterySystemGeneration | null
  readonly subSystems?: ReadonlyArray<{
    readonly id: string
    readonly displayName?: string | null
    readonly observed?: string | null
    readonly meta: { readonly tenant: string }
    readonly state?: {
      readonly position?: number | null
      readonly stateOfCharge?: number | null
      readonly voltage?: number | null
      readonly current?: number | null
      readonly cellTemperatureStats?: {
        readonly min?: number | null
        readonly max?: number | null
        readonly mean?: number | null
      } | null
      readonly cellVoltageStats?: {
        readonly min?: number | null
        readonly max?: number | null
        readonly mean?: number | null
      } | null
      readonly isolation?: { readonly value?: number | null; readonly limit?: number | null } | null
    } | null
  } | null> | null
}

export type SubpacksTableSystemFragment = {
  readonly id: string
  readonly displayName?: string | null
  readonly observed?: string | null
  readonly meta: { readonly tenant: string }
  readonly state?: {
    readonly position?: number | null
    readonly stateOfCharge?: number | null
    readonly voltage?: number | null
    readonly current?: number | null
    readonly cellTemperatureStats?: {
      readonly min?: number | null
      readonly max?: number | null
      readonly mean?: number | null
    } | null
    readonly cellVoltageStats?: {
      readonly min?: number | null
      readonly max?: number | null
      readonly mean?: number | null
    } | null
    readonly isolation?: { readonly value?: number | null; readonly limit?: number | null } | null
  } | null
}

export type SystemMainQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SystemMainQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly kind?: BatterySystemKind | null
    readonly observed?: string | null
  }
}

export type SystemLocationFragment = {
  readonly location?: {
    readonly name?: string | null
    readonly latitude?: number | null
    readonly longitude?: number | null
  } | null
}

export type SystemSoftwareFragment = {
  readonly state?: {
    readonly softwareVersions?: ReadonlyArray<{
      readonly software: string
      readonly version: string
    }> | null
  } | null
}

export type SystemMenuFragment = {
  readonly generation?: BatterySystemGeneration | null
  readonly id: string
  readonly connectivityId?: string | null
  readonly displayName?: string | null
  readonly kind?: BatterySystemKind | null
  readonly observed?: string | null
  readonly subSystems?: ReadonlyArray<{
    readonly generation?: BatterySystemGeneration | null
    readonly id: string
    readonly connectivityId?: string | null
    readonly displayName?: string | null
    readonly kind?: BatterySystemKind | null
    readonly observed?: string | null
    readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
    readonly faults?: {
      readonly list?: ReadonlyArray<{
        readonly dtcSpn: number
        readonly isActive?: boolean | null
      }> | null
    } | null
    readonly state?: {
      readonly position?: number | null
      readonly isContactorSohErrorDisabled?: boolean | null
      readonly softwareVersions?: ReadonlyArray<{
        readonly software: string
        readonly version: string
      }> | null
    } | null
    readonly location?: {
      readonly name?: string | null
      readonly latitude?: number | null
      readonly longitude?: number | null
    } | null
  } | null> | null
  readonly parentSystem?: {
    readonly generation?: BatterySystemGeneration | null
    readonly id: string
    readonly connectivityId?: string | null
    readonly displayName?: string | null
    readonly kind?: BatterySystemKind | null
    readonly observed?: string | null
    readonly subSystems?: ReadonlyArray<{
      readonly generation?: BatterySystemGeneration | null
      readonly id: string
      readonly connectivityId?: string | null
      readonly displayName?: string | null
      readonly kind?: BatterySystemKind | null
      readonly observed?: string | null
      readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
      readonly faults?: {
        readonly list?: ReadonlyArray<{
          readonly dtcSpn: number
          readonly isActive?: boolean | null
        }> | null
      } | null
      readonly state?: {
        readonly position?: number | null
        readonly isContactorSohErrorDisabled?: boolean | null
        readonly softwareVersions?: ReadonlyArray<{
          readonly software: string
          readonly version: string
        }> | null
      } | null
      readonly location?: {
        readonly name?: string | null
        readonly latitude?: number | null
        readonly longitude?: number | null
      } | null
    } | null> | null
    readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
    readonly faults?: {
      readonly list?: ReadonlyArray<{
        readonly dtcSpn: number
        readonly isActive?: boolean | null
      }> | null
    } | null
    readonly state?: {
      readonly position?: number | null
      readonly isContactorSohErrorDisabled?: boolean | null
      readonly softwareVersions?: ReadonlyArray<{
        readonly software: string
        readonly version: string
      }> | null
    } | null
    readonly location?: {
      readonly name?: string | null
      readonly latitude?: number | null
      readonly longitude?: number | null
    } | null
  } | null
  readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
  readonly faults?: {
    readonly list?: ReadonlyArray<{
      readonly dtcSpn: number
      readonly isActive?: boolean | null
    }> | null
  } | null
  readonly state?: {
    readonly position?: number | null
    readonly isContactorSohErrorDisabled?: boolean | null
    readonly softwareVersions?: ReadonlyArray<{
      readonly software: string
      readonly version: string
    }> | null
  } | null
  readonly location?: {
    readonly name?: string | null
    readonly latitude?: number | null
    readonly longitude?: number | null
  } | null
}

export type SystemMenu2Fragment = {
  readonly generation?: BatterySystemGeneration | null
  readonly id: string
  readonly connectivityId?: string | null
  readonly displayName?: string | null
  readonly kind?: BatterySystemKind | null
  readonly observed?: string | null
  readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
  readonly faults?: {
    readonly list?: ReadonlyArray<{
      readonly dtcSpn: number
      readonly isActive?: boolean | null
    }> | null
  } | null
  readonly state?: {
    readonly position?: number | null
    readonly isContactorSohErrorDisabled?: boolean | null
    readonly softwareVersions?: ReadonlyArray<{
      readonly software: string
      readonly version: string
    }> | null
  } | null
  readonly location?: {
    readonly name?: string | null
    readonly latitude?: number | null
    readonly longitude?: number | null
  } | null
}

export type SystemMenuQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SystemMenuQuery = {
  readonly batterySystem: {
    readonly generation?: BatterySystemGeneration | null
    readonly id: string
    readonly connectivityId?: string | null
    readonly displayName?: string | null
    readonly kind?: BatterySystemKind | null
    readonly observed?: string | null
    readonly subSystems?: ReadonlyArray<{
      readonly generation?: BatterySystemGeneration | null
      readonly id: string
      readonly connectivityId?: string | null
      readonly displayName?: string | null
      readonly kind?: BatterySystemKind | null
      readonly observed?: string | null
      readonly subSystems?: ReadonlyArray<{
        readonly generation?: BatterySystemGeneration | null
        readonly id: string
        readonly connectivityId?: string | null
        readonly displayName?: string | null
        readonly kind?: BatterySystemKind | null
        readonly observed?: string | null
        readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
        readonly faults?: {
          readonly list?: ReadonlyArray<{
            readonly dtcSpn: number
            readonly isActive?: boolean | null
          }> | null
        } | null
        readonly state?: {
          readonly position?: number | null
          readonly isContactorSohErrorDisabled?: boolean | null
          readonly softwareVersions?: ReadonlyArray<{
            readonly software: string
            readonly version: string
          }> | null
        } | null
        readonly location?: {
          readonly name?: string | null
          readonly latitude?: number | null
          readonly longitude?: number | null
        } | null
      } | null> | null
      readonly parentSystem?: {
        readonly generation?: BatterySystemGeneration | null
        readonly id: string
        readonly connectivityId?: string | null
        readonly displayName?: string | null
        readonly kind?: BatterySystemKind | null
        readonly observed?: string | null
        readonly subSystems?: ReadonlyArray<{
          readonly generation?: BatterySystemGeneration | null
          readonly id: string
          readonly connectivityId?: string | null
          readonly displayName?: string | null
          readonly kind?: BatterySystemKind | null
          readonly observed?: string | null
          readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
          readonly faults?: {
            readonly list?: ReadonlyArray<{
              readonly dtcSpn: number
              readonly isActive?: boolean | null
            }> | null
          } | null
          readonly state?: {
            readonly position?: number | null
            readonly isContactorSohErrorDisabled?: boolean | null
            readonly softwareVersions?: ReadonlyArray<{
              readonly software: string
              readonly version: string
            }> | null
          } | null
          readonly location?: {
            readonly name?: string | null
            readonly latitude?: number | null
            readonly longitude?: number | null
          } | null
        } | null> | null
        readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
        readonly faults?: {
          readonly list?: ReadonlyArray<{
            readonly dtcSpn: number
            readonly isActive?: boolean | null
          }> | null
        } | null
        readonly state?: {
          readonly position?: number | null
          readonly isContactorSohErrorDisabled?: boolean | null
          readonly softwareVersions?: ReadonlyArray<{
            readonly software: string
            readonly version: string
          }> | null
        } | null
        readonly location?: {
          readonly name?: string | null
          readonly latitude?: number | null
          readonly longitude?: number | null
        } | null
      } | null
      readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
      readonly faults?: {
        readonly list?: ReadonlyArray<{
          readonly dtcSpn: number
          readonly isActive?: boolean | null
        }> | null
      } | null
      readonly state?: {
        readonly position?: number | null
        readonly isContactorSohErrorDisabled?: boolean | null
        readonly softwareVersions?: ReadonlyArray<{
          readonly software: string
          readonly version: string
        }> | null
      } | null
      readonly location?: {
        readonly name?: string | null
        readonly latitude?: number | null
        readonly longitude?: number | null
      } | null
    } | null> | null
    readonly parentSystem?: {
      readonly generation?: BatterySystemGeneration | null
      readonly id: string
      readonly connectivityId?: string | null
      readonly displayName?: string | null
      readonly kind?: BatterySystemKind | null
      readonly observed?: string | null
      readonly subSystems?: ReadonlyArray<{
        readonly generation?: BatterySystemGeneration | null
        readonly id: string
        readonly connectivityId?: string | null
        readonly displayName?: string | null
        readonly kind?: BatterySystemKind | null
        readonly observed?: string | null
        readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
        readonly faults?: {
          readonly list?: ReadonlyArray<{
            readonly dtcSpn: number
            readonly isActive?: boolean | null
          }> | null
        } | null
        readonly state?: {
          readonly position?: number | null
          readonly isContactorSohErrorDisabled?: boolean | null
          readonly softwareVersions?: ReadonlyArray<{
            readonly software: string
            readonly version: string
          }> | null
        } | null
        readonly location?: {
          readonly name?: string | null
          readonly latitude?: number | null
          readonly longitude?: number | null
        } | null
      } | null> | null
      readonly parentSystem?: {
        readonly generation?: BatterySystemGeneration | null
        readonly id: string
        readonly connectivityId?: string | null
        readonly displayName?: string | null
        readonly kind?: BatterySystemKind | null
        readonly observed?: string | null
        readonly subSystems?: ReadonlyArray<{
          readonly generation?: BatterySystemGeneration | null
          readonly id: string
          readonly connectivityId?: string | null
          readonly displayName?: string | null
          readonly kind?: BatterySystemKind | null
          readonly observed?: string | null
          readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
          readonly faults?: {
            readonly list?: ReadonlyArray<{
              readonly dtcSpn: number
              readonly isActive?: boolean | null
            }> | null
          } | null
          readonly state?: {
            readonly position?: number | null
            readonly isContactorSohErrorDisabled?: boolean | null
            readonly softwareVersions?: ReadonlyArray<{
              readonly software: string
              readonly version: string
            }> | null
          } | null
          readonly location?: {
            readonly name?: string | null
            readonly latitude?: number | null
            readonly longitude?: number | null
          } | null
        } | null> | null
        readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
        readonly faults?: {
          readonly list?: ReadonlyArray<{
            readonly dtcSpn: number
            readonly isActive?: boolean | null
          }> | null
        } | null
        readonly state?: {
          readonly position?: number | null
          readonly isContactorSohErrorDisabled?: boolean | null
          readonly softwareVersions?: ReadonlyArray<{
            readonly software: string
            readonly version: string
          }> | null
        } | null
        readonly location?: {
          readonly name?: string | null
          readonly latitude?: number | null
          readonly longitude?: number | null
        } | null
      } | null
      readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
      readonly faults?: {
        readonly list?: ReadonlyArray<{
          readonly dtcSpn: number
          readonly isActive?: boolean | null
        }> | null
      } | null
      readonly state?: {
        readonly position?: number | null
        readonly isContactorSohErrorDisabled?: boolean | null
        readonly softwareVersions?: ReadonlyArray<{
          readonly software: string
          readonly version: string
        }> | null
      } | null
      readonly location?: {
        readonly name?: string | null
        readonly latitude?: number | null
        readonly longitude?: number | null
      } | null
    } | null
    readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
    readonly faults?: {
      readonly list?: ReadonlyArray<{
        readonly dtcSpn: number
        readonly isActive?: boolean | null
      }> | null
    } | null
    readonly state?: {
      readonly position?: number | null
      readonly isContactorSohErrorDisabled?: boolean | null
      readonly softwareVersions?: ReadonlyArray<{
        readonly software: string
        readonly version: string
      }> | null
    } | null
    readonly location?: {
      readonly name?: string | null
      readonly latitude?: number | null
      readonly longitude?: number | null
    } | null
  }
}

export type VersionsFragment = {
  readonly id: string
  readonly generation?: BatterySystemGeneration | null
  readonly displayName?: string | null
  readonly kind?: BatterySystemKind | null
  readonly meta: { readonly tenant: string }
  readonly computers?: ReadonlyArray<
    | { readonly __typename: 'BatteryMasterUnit'; readonly id: string }
    | { readonly __typename: 'EdgeGateway'; readonly id: string }
  > | null
  readonly subSystems?: ReadonlyArray<{
    readonly id: string
    readonly computers?: ReadonlyArray<
      | { readonly __typename: 'BatteryMasterUnit'; readonly id: string }
      | { readonly __typename: 'EdgeGateway'; readonly id: string }
    > | null
    readonly state?: {
      readonly position?: number | null
      readonly hardwareVersions?: ReadonlyArray<{
        readonly hardware: string
        readonly version: string
      }> | null
      readonly softwareVersions?: ReadonlyArray<{
        readonly software: string
        readonly version: string
      }> | null
    } | null
  } | null> | null
  readonly state?: {
    readonly position?: number | null
    readonly hardwareVersions?: ReadonlyArray<{
      readonly hardware: string
      readonly version: string
    }> | null
    readonly softwareVersions?: ReadonlyArray<{
      readonly software: string
      readonly version: string
    }> | null
  } | null
}

export type VersionsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type VersionsQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly generation?: BatterySystemGeneration | null
    readonly displayName?: string | null
    readonly kind?: BatterySystemKind | null
    readonly meta: { readonly tenant: string }
    readonly computers?: ReadonlyArray<
      | { readonly __typename: 'BatteryMasterUnit'; readonly id: string }
      | { readonly __typename: 'EdgeGateway'; readonly id: string }
    > | null
    readonly subSystems?: ReadonlyArray<{
      readonly id: string
      readonly computers?: ReadonlyArray<
        | { readonly __typename: 'BatteryMasterUnit'; readonly id: string }
        | { readonly __typename: 'EdgeGateway'; readonly id: string }
      > | null
      readonly state?: {
        readonly position?: number | null
        readonly hardwareVersions?: ReadonlyArray<{
          readonly hardware: string
          readonly version: string
        }> | null
        readonly softwareVersions?: ReadonlyArray<{
          readonly software: string
          readonly version: string
        }> | null
      } | null
    } | null> | null
    readonly state?: {
      readonly position?: number | null
      readonly hardwareVersions?: ReadonlyArray<{
        readonly hardware: string
        readonly version: string
      }> | null
      readonly softwareVersions?: ReadonlyArray<{
        readonly software: string
        readonly version: string
      }> | null
    } | null
  }
}

export type TelemetryDataFragment = {
  readonly metric: string
  readonly labels: any
  readonly values: ReadonlyArray<{ readonly time: any; readonly value?: number | null }>
}

export type TelemetryDataQueryVariables = Exact<{
  from: Scalars['Time']['input']
  to: Scalars['Time']['input']
  metrics: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
  labels: ReadonlyArray<Scalars['Map']['input']> | Scalars['Map']['input']
  resolution: Scalars['Int']['input']
  aggregation?: InputMaybe<Scalars['String']['input']>
}>

export type TelemetryDataQuery = {
  readonly telemetry?: {
    readonly data: ReadonlyArray<{
      readonly metric: string
      readonly labels: any
      readonly values: ReadonlyArray<{ readonly time: any; readonly value?: number | null }>
    }>
  } | null
}

export type Test_FirstSystemFragment = {
  readonly faults?: {
    readonly list?: ReadonlyArray<{ readonly fmi?: number | null; readonly dtcSpn: number }> | null
  } | null
  readonly state?: { readonly meta: { readonly observed: any } } | null
}

export type Test_SecondSystemFragment = {
  readonly displayName?: string | null
  readonly faults?: {
    readonly list?: ReadonlyArray<{
      readonly dtcSpn: number
      readonly isActive?: boolean | null
    }> | null
  } | null
}

export type Test_CombinedSystemFragment = {
  readonly displayName?: string | null
  readonly faults?: {
    readonly list?: ReadonlyArray<{
      readonly fmi?: number | null
      readonly dtcSpn: number
      readonly isActive?: boolean | null
    }> | null
  } | null
  readonly state?: { readonly meta: { readonly observed: any } } | null
}

export type Test_QueryQueryVariables = Exact<{ [key: string]: never }>

export type Test_QueryQuery = {
  readonly batterySystems: {
    readonly items: ReadonlyArray<{
      readonly id: string
      readonly displayName?: string | null
      readonly faults?: {
        readonly list?: ReadonlyArray<{
          readonly fmi?: number | null
          readonly dtcSpn: number
          readonly isActive?: boolean | null
        }> | null
      } | null
      readonly state?: { readonly meta: { readonly observed: any } } | null
    }>
  }
}

export type CreateCoreMutationVariables = Exact<{
  id: Scalars['ID']['input']
  core: CreateCoreInput
  tags: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
}>

export type CreateCoreMutation = {
  readonly createCore: { readonly success: boolean }
  readonly setCoreTags: { readonly success: boolean }
}

export type CreatePackMutationVariables = Exact<{
  id: Scalars['ID']['input']
  pack: CreatePackInput
  tags: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
}>

export type CreatePackMutation = {
  readonly createPack: { readonly success: boolean }
  readonly setPackTags: { readonly success: boolean }
}

export type CreateSecureElementMutationVariables = Exact<{
  fingerprint: Scalars['String']['input']
  hardwareSe: Scalars['Boolean']['input']
  tenant: Scalars['String']['input']
}>

export type CreateSecureElementMutation = {
  readonly addPublicKeyFingerprint: { readonly success: boolean }
}

export type CreateVoltpackMobileHubMutationVariables = Exact<{
  id: Scalars['ID']['input']
  vpmHub: CreateVoltpackMobileHubInput
  tags: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
}>

export type CreateVoltpackMobileHubMutation = {
  readonly createVoltpackMobileHub: { readonly connectivityId: string }
  readonly setVoltpackMobileHubTags: { readonly success: boolean }
}

export type EditCoreQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type EditCoreQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly displayName?: string | null
    readonly tags: ReadonlyArray<string>
    readonly meta: { readonly tenant: string }
    readonly computers?: ReadonlyArray<{ readonly id: string } | { readonly id: string }> | null
    readonly parentSystem?: { readonly id: string; readonly displayName?: string | null } | null
  }
}

export type SetCoreDisplayNameMutationVariables = Exact<{
  id: Scalars['ID']['input']
  displayName: Scalars['String']['input']
}>

export type SetCoreDisplayNameMutation = {
  readonly setCoreDisplayName: { readonly success: boolean }
}

export type SetCoreTenantMutationVariables = Exact<{
  id: Scalars['ID']['input']
  tenant: Scalars['ID']['input']
}>

export type SetCoreTenantMutation = { readonly setCoreTenant: { readonly success: boolean } }

export type SetCoreMacAddressMutationVariables = Exact<{
  id: Scalars['ID']['input']
  macAddress: Scalars['String']['input']
}>

export type SetCoreMacAddressMutation = {
  readonly setCoreMacAddress: { readonly success: boolean }
}

export type SetCoreTagsMutationVariables = Exact<{
  id: Scalars['ID']['input']
  tags: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
}>

export type SetCoreTagsMutation = { readonly setCoreTags: { readonly success: boolean } }

export type EditPackQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type EditPackQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly displayName?: string | null
    readonly tags: ReadonlyArray<string>
    readonly meta: { readonly tenant: string }
    readonly computers?: ReadonlyArray<{ readonly id: string } | { readonly id: string }> | null
  }
}

export type SetPackDisplayNameMutationVariables = Exact<{
  id: Scalars['ID']['input']
  displayName: Scalars['String']['input']
}>

export type SetPackDisplayNameMutation = {
  readonly setPackDisplayName: { readonly success: boolean }
}

export type SetPackTenantMutationVariables = Exact<{
  id: Scalars['ID']['input']
  tenant: Scalars['ID']['input']
}>

export type SetPackTenantMutation = { readonly setPackTenant: { readonly success: boolean } }

export type SetPackTagsMutationVariables = Exact<{
  id: Scalars['ID']['input']
  tags: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
}>

export type SetPackTagsMutation = { readonly setPackTags: { readonly success: boolean } }

export type EditVoltpackMobileHubQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type EditVoltpackMobileHubQuery = {
  readonly batterySystem: {
    readonly id: string
    readonly displayName?: string | null
    readonly tags: ReadonlyArray<string>
    readonly meta: { readonly tenant: string }
    readonly computers?: ReadonlyArray<{ readonly id: string } | { readonly id: string }> | null
  }
}

export type SetVoltpackMobileHubDisplayNameMutationVariables = Exact<{
  id: Scalars['ID']['input']
  displayName: Scalars['String']['input']
}>

export type SetVoltpackMobileHubDisplayNameMutation = {
  readonly setVoltpackMobileHubDisplayName: { readonly success: boolean }
}

export type SetVoltpackMobileHubTenantMutationVariables = Exact<{
  id: Scalars['ID']['input']
  tenant: Scalars['ID']['input']
}>

export type SetVoltpackMobileHubTenantMutation = {
  readonly setVoltpackMobileHubTenant: { readonly success: boolean }
}

export type SetVoltpackMobileHubTagsMutationVariables = Exact<{
  id: Scalars['ID']['input']
  tags: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
}>

export type SetVoltpackMobileHubTagsMutation = {
  readonly setVoltpackMobileHubTags: { readonly success: boolean }
}

export type SetVoltpackMobileHubMacAddressMutationVariables = Exact<{
  id: Scalars['ID']['input']
  macAddress: Scalars['String']['input']
}>

export type SetVoltpackMobileHubMacAddressMutation = {
  readonly setVoltpackMobileHubMacAddress: { readonly success: boolean }
}

export type TenantsAndProductsQueryVariables = Exact<{ [key: string]: never }>

export type TenantsAndProductsQuery = {
  readonly products: ReadonlyArray<{ readonly name: string }>
  readonly tenants: ReadonlyArray<{ readonly name: string }>
}

export type SystemFragment = {
  readonly id: string
  readonly kind?: BatterySystemKind | null
  readonly displayName?: string | null
  readonly tags: ReadonlyArray<string>
  readonly observed?: string | null
  readonly connectivityId?: string | null
  readonly meta: { readonly tenant: string }
  readonly computers?: ReadonlyArray<{ readonly id: string } | { readonly id: string }> | null
  readonly subSystems?: ReadonlyArray<{
    readonly state?: {
      readonly position?: number | null
      readonly softwareVersions?: ReadonlyArray<{
        readonly software: string
        readonly version: string
      }> | null
    } | null
  } | null> | null
  readonly state?: {
    readonly operationalStateOfCharge?: number | null
    readonly stateOfCharge?: number | null
    readonly position?: number | null
    readonly isContactorSohErrorDisabled?: boolean | null
    readonly softwareVersions?: ReadonlyArray<{
      readonly software: string
      readonly version: string
    }> | null
  } | null
  readonly parentSystem?: {
    readonly state?: { readonly operationalStateOfCharge?: number | null } | null
  } | null
}

export type SystemsListQueryVariables = Exact<{
  filter: BatteryFilter
}>

export type SystemsListQuery = {
  readonly systems: { readonly items: ReadonlyArray<{ readonly id: string }> }
}

export type SystemsByIdsQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
}>

export type SystemsByIdsQuery = {
  readonly systemsByIDs: {
    readonly items: ReadonlyArray<{
      readonly id: string
      readonly kind?: BatterySystemKind | null
      readonly displayName?: string | null
      readonly tags: ReadonlyArray<string>
      readonly observed?: string | null
      readonly connectivityId?: string | null
      readonly meta: { readonly tenant: string }
      readonly computers?: ReadonlyArray<{ readonly id: string } | { readonly id: string }> | null
      readonly subSystems?: ReadonlyArray<{
        readonly state?: {
          readonly position?: number | null
          readonly softwareVersions?: ReadonlyArray<{
            readonly software: string
            readonly version: string
          }> | null
        } | null
      } | null> | null
      readonly state?: {
        readonly operationalStateOfCharge?: number | null
        readonly stateOfCharge?: number | null
        readonly position?: number | null
        readonly isContactorSohErrorDisabled?: boolean | null
        readonly softwareVersions?: ReadonlyArray<{
          readonly software: string
          readonly version: string
        }> | null
      } | null
      readonly parentSystem?: {
        readonly state?: { readonly operationalStateOfCharge?: number | null } | null
      } | null
    }>
  }
}

type PageItemImageContent_PageItemImage_Fragment = {
  readonly __typename: 'PageItemImage'
  readonly title?: string | null
  readonly description?: string | null
  readonly path: string
}

type PageItemImageContent_PageItemPlotly_Fragment = { readonly __typename: 'PageItemPlotly' }

type PageItemImageContent_PageItemPlotlyReel_Fragment = {
  readonly __typename: 'PageItemPlotlyReel'
}

type PageItemImageContent_PageItemImageReel_Fragment = { readonly __typename: 'PageItemImageReel' }

type PageItemImageContent_PageItemHtml_Fragment = { readonly __typename: 'PageItemHtml' }

type PageItemImageContent_PageItemHeader_Fragment = { readonly __typename: 'PageItemHeader' }

type PageItemImageContent_PageItemText_Fragment = { readonly __typename: 'PageItemText' }

type PageItemImageContent_PageItemLink_Fragment = { readonly __typename: 'PageItemLink' }

type PageItemImageContent_PageItemMarkdown_Fragment = { readonly __typename: 'PageItemMarkdown' }

export type PageItemImageContentFragment =
  | PageItemImageContent_PageItemImage_Fragment
  | PageItemImageContent_PageItemPlotly_Fragment
  | PageItemImageContent_PageItemPlotlyReel_Fragment
  | PageItemImageContent_PageItemImageReel_Fragment
  | PageItemImageContent_PageItemHtml_Fragment
  | PageItemImageContent_PageItemHeader_Fragment
  | PageItemImageContent_PageItemText_Fragment
  | PageItemImageContent_PageItemLink_Fragment
  | PageItemImageContent_PageItemMarkdown_Fragment

type PageItemPlotlyContent_PageItemImage_Fragment = { readonly __typename: 'PageItemImage' }

type PageItemPlotlyContent_PageItemPlotly_Fragment = {
  readonly __typename: 'PageItemPlotly'
  readonly title?: string | null
  readonly description?: string | null
  readonly path: string
}

type PageItemPlotlyContent_PageItemPlotlyReel_Fragment = {
  readonly __typename: 'PageItemPlotlyReel'
}

type PageItemPlotlyContent_PageItemImageReel_Fragment = { readonly __typename: 'PageItemImageReel' }

type PageItemPlotlyContent_PageItemHtml_Fragment = { readonly __typename: 'PageItemHtml' }

type PageItemPlotlyContent_PageItemHeader_Fragment = { readonly __typename: 'PageItemHeader' }

type PageItemPlotlyContent_PageItemText_Fragment = { readonly __typename: 'PageItemText' }

type PageItemPlotlyContent_PageItemLink_Fragment = { readonly __typename: 'PageItemLink' }

type PageItemPlotlyContent_PageItemMarkdown_Fragment = { readonly __typename: 'PageItemMarkdown' }

export type PageItemPlotlyContentFragment =
  | PageItemPlotlyContent_PageItemImage_Fragment
  | PageItemPlotlyContent_PageItemPlotly_Fragment
  | PageItemPlotlyContent_PageItemPlotlyReel_Fragment
  | PageItemPlotlyContent_PageItemImageReel_Fragment
  | PageItemPlotlyContent_PageItemHtml_Fragment
  | PageItemPlotlyContent_PageItemHeader_Fragment
  | PageItemPlotlyContent_PageItemText_Fragment
  | PageItemPlotlyContent_PageItemLink_Fragment
  | PageItemPlotlyContent_PageItemMarkdown_Fragment

type PageItemImageReelContent_PageItemImage_Fragment = { readonly __typename: 'PageItemImage' }

type PageItemImageReelContent_PageItemPlotly_Fragment = { readonly __typename: 'PageItemPlotly' }

type PageItemImageReelContent_PageItemPlotlyReel_Fragment = {
  readonly __typename: 'PageItemPlotlyReel'
}

type PageItemImageReelContent_PageItemImageReel_Fragment = {
  readonly __typename: 'PageItemImageReel'
  readonly initialPosition: ReelInitialPositionEnum
  readonly reel: ReadonlyArray<{
    readonly title?: string | null
    readonly description?: string | null
    readonly path: string
  }>
}

type PageItemImageReelContent_PageItemHtml_Fragment = { readonly __typename: 'PageItemHtml' }

type PageItemImageReelContent_PageItemHeader_Fragment = { readonly __typename: 'PageItemHeader' }

type PageItemImageReelContent_PageItemText_Fragment = { readonly __typename: 'PageItemText' }

type PageItemImageReelContent_PageItemLink_Fragment = { readonly __typename: 'PageItemLink' }

type PageItemImageReelContent_PageItemMarkdown_Fragment = {
  readonly __typename: 'PageItemMarkdown'
}

export type PageItemImageReelContentFragment =
  | PageItemImageReelContent_PageItemImage_Fragment
  | PageItemImageReelContent_PageItemPlotly_Fragment
  | PageItemImageReelContent_PageItemPlotlyReel_Fragment
  | PageItemImageReelContent_PageItemImageReel_Fragment
  | PageItemImageReelContent_PageItemHtml_Fragment
  | PageItemImageReelContent_PageItemHeader_Fragment
  | PageItemImageReelContent_PageItemText_Fragment
  | PageItemImageReelContent_PageItemLink_Fragment
  | PageItemImageReelContent_PageItemMarkdown_Fragment

type PageItemPlotlyReelContent_PageItemImage_Fragment = { readonly __typename: 'PageItemImage' }

type PageItemPlotlyReelContent_PageItemPlotly_Fragment = { readonly __typename: 'PageItemPlotly' }

type PageItemPlotlyReelContent_PageItemPlotlyReel_Fragment = {
  readonly __typename: 'PageItemPlotlyReel'
  readonly initialPosition: ReelInitialPositionEnum
  readonly reel: ReadonlyArray<{
    readonly title?: string | null
    readonly description?: string | null
    readonly path: string
  }>
}

type PageItemPlotlyReelContent_PageItemImageReel_Fragment = {
  readonly __typename: 'PageItemImageReel'
}

type PageItemPlotlyReelContent_PageItemHtml_Fragment = { readonly __typename: 'PageItemHtml' }

type PageItemPlotlyReelContent_PageItemHeader_Fragment = { readonly __typename: 'PageItemHeader' }

type PageItemPlotlyReelContent_PageItemText_Fragment = { readonly __typename: 'PageItemText' }

type PageItemPlotlyReelContent_PageItemLink_Fragment = { readonly __typename: 'PageItemLink' }

type PageItemPlotlyReelContent_PageItemMarkdown_Fragment = {
  readonly __typename: 'PageItemMarkdown'
}

export type PageItemPlotlyReelContentFragment =
  | PageItemPlotlyReelContent_PageItemImage_Fragment
  | PageItemPlotlyReelContent_PageItemPlotly_Fragment
  | PageItemPlotlyReelContent_PageItemPlotlyReel_Fragment
  | PageItemPlotlyReelContent_PageItemImageReel_Fragment
  | PageItemPlotlyReelContent_PageItemHtml_Fragment
  | PageItemPlotlyReelContent_PageItemHeader_Fragment
  | PageItemPlotlyReelContent_PageItemText_Fragment
  | PageItemPlotlyReelContent_PageItemLink_Fragment
  | PageItemPlotlyReelContent_PageItemMarkdown_Fragment

type PageItemHtmlContent_PageItemImage_Fragment = { readonly __typename: 'PageItemImage' }

type PageItemHtmlContent_PageItemPlotly_Fragment = { readonly __typename: 'PageItemPlotly' }

type PageItemHtmlContent_PageItemPlotlyReel_Fragment = { readonly __typename: 'PageItemPlotlyReel' }

type PageItemHtmlContent_PageItemImageReel_Fragment = { readonly __typename: 'PageItemImageReel' }

type PageItemHtmlContent_PageItemHtml_Fragment = {
  readonly __typename: 'PageItemHtml'
  readonly html: string
}

type PageItemHtmlContent_PageItemHeader_Fragment = { readonly __typename: 'PageItemHeader' }

type PageItemHtmlContent_PageItemText_Fragment = { readonly __typename: 'PageItemText' }

type PageItemHtmlContent_PageItemLink_Fragment = { readonly __typename: 'PageItemLink' }

type PageItemHtmlContent_PageItemMarkdown_Fragment = { readonly __typename: 'PageItemMarkdown' }

export type PageItemHtmlContentFragment =
  | PageItemHtmlContent_PageItemImage_Fragment
  | PageItemHtmlContent_PageItemPlotly_Fragment
  | PageItemHtmlContent_PageItemPlotlyReel_Fragment
  | PageItemHtmlContent_PageItemImageReel_Fragment
  | PageItemHtmlContent_PageItemHtml_Fragment
  | PageItemHtmlContent_PageItemHeader_Fragment
  | PageItemHtmlContent_PageItemText_Fragment
  | PageItemHtmlContent_PageItemLink_Fragment
  | PageItemHtmlContent_PageItemMarkdown_Fragment

type PageItemTextContent_PageItemImage_Fragment = { readonly __typename: 'PageItemImage' }

type PageItemTextContent_PageItemPlotly_Fragment = { readonly __typename: 'PageItemPlotly' }

type PageItemTextContent_PageItemPlotlyReel_Fragment = { readonly __typename: 'PageItemPlotlyReel' }

type PageItemTextContent_PageItemImageReel_Fragment = { readonly __typename: 'PageItemImageReel' }

type PageItemTextContent_PageItemHtml_Fragment = { readonly __typename: 'PageItemHtml' }

type PageItemTextContent_PageItemHeader_Fragment = { readonly __typename: 'PageItemHeader' }

type PageItemTextContent_PageItemText_Fragment = {
  readonly __typename: 'PageItemText'
  readonly text: string
}

type PageItemTextContent_PageItemLink_Fragment = { readonly __typename: 'PageItemLink' }

type PageItemTextContent_PageItemMarkdown_Fragment = { readonly __typename: 'PageItemMarkdown' }

export type PageItemTextContentFragment =
  | PageItemTextContent_PageItemImage_Fragment
  | PageItemTextContent_PageItemPlotly_Fragment
  | PageItemTextContent_PageItemPlotlyReel_Fragment
  | PageItemTextContent_PageItemImageReel_Fragment
  | PageItemTextContent_PageItemHtml_Fragment
  | PageItemTextContent_PageItemHeader_Fragment
  | PageItemTextContent_PageItemText_Fragment
  | PageItemTextContent_PageItemLink_Fragment
  | PageItemTextContent_PageItemMarkdown_Fragment

type PageItemLinkContent_PageItemImage_Fragment = { readonly __typename: 'PageItemImage' }

type PageItemLinkContent_PageItemPlotly_Fragment = { readonly __typename: 'PageItemPlotly' }

type PageItemLinkContent_PageItemPlotlyReel_Fragment = { readonly __typename: 'PageItemPlotlyReel' }

type PageItemLinkContent_PageItemImageReel_Fragment = { readonly __typename: 'PageItemImageReel' }

type PageItemLinkContent_PageItemHtml_Fragment = { readonly __typename: 'PageItemHtml' }

type PageItemLinkContent_PageItemHeader_Fragment = { readonly __typename: 'PageItemHeader' }

type PageItemLinkContent_PageItemText_Fragment = { readonly __typename: 'PageItemText' }

type PageItemLinkContent_PageItemLink_Fragment = {
  readonly __typename: 'PageItemLink'
  readonly url: string
  readonly text: string
}

type PageItemLinkContent_PageItemMarkdown_Fragment = { readonly __typename: 'PageItemMarkdown' }

export type PageItemLinkContentFragment =
  | PageItemLinkContent_PageItemImage_Fragment
  | PageItemLinkContent_PageItemPlotly_Fragment
  | PageItemLinkContent_PageItemPlotlyReel_Fragment
  | PageItemLinkContent_PageItemImageReel_Fragment
  | PageItemLinkContent_PageItemHtml_Fragment
  | PageItemLinkContent_PageItemHeader_Fragment
  | PageItemLinkContent_PageItemText_Fragment
  | PageItemLinkContent_PageItemLink_Fragment
  | PageItemLinkContent_PageItemMarkdown_Fragment

type PageItemHeaderContent_PageItemImage_Fragment = { readonly __typename: 'PageItemImage' }

type PageItemHeaderContent_PageItemPlotly_Fragment = { readonly __typename: 'PageItemPlotly' }

type PageItemHeaderContent_PageItemPlotlyReel_Fragment = {
  readonly __typename: 'PageItemPlotlyReel'
}

type PageItemHeaderContent_PageItemImageReel_Fragment = { readonly __typename: 'PageItemImageReel' }

type PageItemHeaderContent_PageItemHtml_Fragment = { readonly __typename: 'PageItemHtml' }

type PageItemHeaderContent_PageItemHeader_Fragment = {
  readonly __typename: 'PageItemHeader'
  readonly header: string
  readonly level: number
}

type PageItemHeaderContent_PageItemText_Fragment = { readonly __typename: 'PageItemText' }

type PageItemHeaderContent_PageItemLink_Fragment = { readonly __typename: 'PageItemLink' }

type PageItemHeaderContent_PageItemMarkdown_Fragment = { readonly __typename: 'PageItemMarkdown' }

export type PageItemHeaderContentFragment =
  | PageItemHeaderContent_PageItemImage_Fragment
  | PageItemHeaderContent_PageItemPlotly_Fragment
  | PageItemHeaderContent_PageItemPlotlyReel_Fragment
  | PageItemHeaderContent_PageItemImageReel_Fragment
  | PageItemHeaderContent_PageItemHtml_Fragment
  | PageItemHeaderContent_PageItemHeader_Fragment
  | PageItemHeaderContent_PageItemText_Fragment
  | PageItemHeaderContent_PageItemLink_Fragment
  | PageItemHeaderContent_PageItemMarkdown_Fragment

type PageItemMarkdownContent_PageItemImage_Fragment = { readonly __typename: 'PageItemImage' }

type PageItemMarkdownContent_PageItemPlotly_Fragment = { readonly __typename: 'PageItemPlotly' }

type PageItemMarkdownContent_PageItemPlotlyReel_Fragment = {
  readonly __typename: 'PageItemPlotlyReel'
}

type PageItemMarkdownContent_PageItemImageReel_Fragment = {
  readonly __typename: 'PageItemImageReel'
}

type PageItemMarkdownContent_PageItemHtml_Fragment = { readonly __typename: 'PageItemHtml' }

type PageItemMarkdownContent_PageItemHeader_Fragment = { readonly __typename: 'PageItemHeader' }

type PageItemMarkdownContent_PageItemText_Fragment = { readonly __typename: 'PageItemText' }

type PageItemMarkdownContent_PageItemLink_Fragment = { readonly __typename: 'PageItemLink' }

type PageItemMarkdownContent_PageItemMarkdown_Fragment = {
  readonly __typename: 'PageItemMarkdown'
  readonly text: string
}

export type PageItemMarkdownContentFragment =
  | PageItemMarkdownContent_PageItemImage_Fragment
  | PageItemMarkdownContent_PageItemPlotly_Fragment
  | PageItemMarkdownContent_PageItemPlotlyReel_Fragment
  | PageItemMarkdownContent_PageItemImageReel_Fragment
  | PageItemMarkdownContent_PageItemHtml_Fragment
  | PageItemMarkdownContent_PageItemHeader_Fragment
  | PageItemMarkdownContent_PageItemText_Fragment
  | PageItemMarkdownContent_PageItemLink_Fragment
  | PageItemMarkdownContent_PageItemMarkdown_Fragment

type PageItemContent_PageItemImage_Fragment = {
  readonly __typename: 'PageItemImage'
  readonly title?: string | null
  readonly description?: string | null
  readonly path: string
}

type PageItemContent_PageItemPlotly_Fragment = {
  readonly __typename: 'PageItemPlotly'
  readonly title?: string | null
  readonly description?: string | null
  readonly path: string
}

type PageItemContent_PageItemPlotlyReel_Fragment = {
  readonly __typename: 'PageItemPlotlyReel'
  readonly initialPosition: ReelInitialPositionEnum
  readonly reel: ReadonlyArray<{
    readonly title?: string | null
    readonly description?: string | null
    readonly path: string
  }>
}

type PageItemContent_PageItemImageReel_Fragment = {
  readonly __typename: 'PageItemImageReel'
  readonly initialPosition: ReelInitialPositionEnum
  readonly reel: ReadonlyArray<{
    readonly title?: string | null
    readonly description?: string | null
    readonly path: string
  }>
}

type PageItemContent_PageItemHtml_Fragment = {
  readonly __typename: 'PageItemHtml'
  readonly html: string
}

type PageItemContent_PageItemHeader_Fragment = {
  readonly __typename: 'PageItemHeader'
  readonly header: string
  readonly level: number
}

type PageItemContent_PageItemText_Fragment = {
  readonly __typename: 'PageItemText'
  readonly text: string
}

type PageItemContent_PageItemLink_Fragment = {
  readonly __typename: 'PageItemLink'
  readonly url: string
  readonly text: string
}

type PageItemContent_PageItemMarkdown_Fragment = {
  readonly __typename: 'PageItemMarkdown'
  readonly text: string
}

export type PageItemContentFragment =
  | PageItemContent_PageItemImage_Fragment
  | PageItemContent_PageItemPlotly_Fragment
  | PageItemContent_PageItemPlotlyReel_Fragment
  | PageItemContent_PageItemImageReel_Fragment
  | PageItemContent_PageItemHtml_Fragment
  | PageItemContent_PageItemHeader_Fragment
  | PageItemContent_PageItemText_Fragment
  | PageItemContent_PageItemLink_Fragment
  | PageItemContent_PageItemMarkdown_Fragment

export type GqlTestQueryVariables = Exact<{ [key: string]: never }>

export type GqlTestQuery = {
  readonly batterySystems: {
    readonly items: ReadonlyArray<{ readonly id: string; readonly displayName?: string | null }>
  }
}

export type GqlBatterySystemFragment = { readonly id: string; readonly displayName?: string | null }

export type GqlBatterySystemStateFragment = {
  readonly voltage?: number | null
  readonly current?: number | null
}

export type GqlTest2QueryVariables = Exact<{ [key: string]: never }>

export type GqlTest2Query = {
  readonly batterySystems: {
    readonly items: ReadonlyArray<{
      readonly id: string
      readonly displayName?: string | null
      readonly state?: { readonly voltage?: number | null; readonly current?: number | null } | null
    }>
  }
}

export type GqlBatterySubSystemFragment = {
  readonly id: string
  readonly displayName?: string | null
  readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
}

export type GqlTest3QueryVariables = Exact<{ [key: string]: never }>

export type GqlTest3Query = {
  readonly batterySystems: {
    readonly items: ReadonlyArray<{
      readonly id: string
      readonly displayName?: string | null
      readonly subSystems?: ReadonlyArray<{
        readonly id: string
        readonly displayName?: string | null
        readonly modules?: ReadonlyArray<{ readonly id: string } | null> | null
      } | null> | null
    }>
  }
}

export type SampleQueryWithoutVariablesQueryVariables = Exact<{ [key: string]: never }>

export type SampleQueryWithoutVariablesQuery = {
  readonly systemsByIDs: {
    readonly items: ReadonlyArray<{ readonly id: string; readonly displayName?: string | null }>
  }
}

export type SampleQueryWithVariablesQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type SampleQueryWithVariablesQuery = {
  readonly systemsByIDs: {
    readonly items: ReadonlyArray<{ readonly id: string; readonly displayName?: string | null }>
  }
}

export type LowestAcoVersionFragment = {
  readonly subSystems?: ReadonlyArray<{
    readonly state?: {
      readonly position?: number | null
      readonly softwareVersions?: ReadonlyArray<{
        readonly software: string
        readonly version: string
      }> | null
    } | null
  } | null> | null
  readonly state?: {
    readonly softwareVersions?: ReadonlyArray<{
      readonly software: string
      readonly version: string
    }> | null
  } | null
}

export type StateOfChargeFragment = {
  readonly state?: {
    readonly operationalStateOfCharge?: number | null
    readonly stateOfCharge?: number | null
  } | null
  readonly parentSystem?: {
    readonly state?: { readonly operationalStateOfCharge?: number | null } | null
  } | null
}

export type SystemBasicInfoFragment = {
  readonly id: string
  readonly connectivityId?: string | null
  readonly displayName?: string | null
  readonly kind?: BatterySystemKind | null
  readonly observed?: string | null
  readonly state?: {
    readonly position?: number | null
    readonly isContactorSohErrorDisabled?: boolean | null
  } | null
}
