import { ReactNode } from 'react'

import { ChargingState } from '@northvolt/charging'

export type HeaderMenuItem = {
  type: 'item'
  title: NonNullable<ReactNode>
  icon?: ReactNode
  onClick?: () => void
}

export type HeaderMenuLink = {
  type: 'link'
  title: NonNullable<ReactNode>
  icon?: ReactNode
  link: string
  externalLink?: boolean
}

export type HeaderMenuSection = {
  type: 'section'
  title: string
}

export type HeaderMenuDivider = {
  type: 'divider'
}

export type HeaderUser = Partial<
  Pick<AuthUser, 'firstName' | 'lastName' | 'email' | 'roles' | 'tenant'>
>

export type HeaderMenuOption =
  | HeaderMenuItem
  | HeaderMenuLink
  | HeaderMenuDivider
  | HeaderMenuSection

export type HeaderProps = {
  menu?: HeaderMenuOption[]
  appName: ReactNode
  appNameShort?: string // optional shortened app name for mobile rendering
  title: ReactNode
  titleLink?:
    | string
    | {
        link: string
        externalLink?: boolean
      }
  environment: 'production' | 'development' | 'localhost' | 'stage' | 'offline'
  user: HeaderUser
  theme?: 'light' | 'dark' | 'offBlack' // off-black = #222222
  transparent?: boolean
  align?: 'left' | 'responsive'
  onMenu?: () => void
  version?: string
  showVersionInTitle?: boolean
  /**
   * presenter: removes header altogether, for use in a control tower context where no interaction
   * with header elements is needed
   *
   * absolute: positions the header absolutely over the background. Useful for apps where you have
   * a fullscreen presentational view of some kind (such as the fleet map application)
   *
   * fixed: fixed header on the top
   *
   * default: normal header positioned on top of the page
   */
  displayMode?: 'presenter' | 'absolute' | 'fixed' | 'default'
  showNotificationDot?: boolean
  latestVersion?: string
  chargingState?: ChargingState | null
  withAppsDropdown?: boolean

  /**
   * If true, the app is a prerelease version.
   */
  isPrerelease?: boolean

  /**
   * If set, this determines the last time when the app should be used.
   */
  prereleaseExpiresAt?: string
}

export enum RolesEnum {
  /**
   * read access, tenant: any
   */
  BATTERY_DIAGNOSTICS_READER = 'orb-battery-diagnostics-reader',

  /**
   * write access, tenant: any
   */
  BATTERY_DIAGNOSTICS_WRITER = 'orb-battery-diagnostics-writer',

  /**
   * production access, tenant: Epiroc only
   */
  BATTERY_DIAGNOSTICS_PRODUCTION = 'orb-battery-diagnostics-production',

  /**
   * admin access, tenant: Epiroc only
   */
  BATTERY_DIAGNOSTICS_ADMIN = 'orb-battery-diagnostics-admin',

  /**
   * root admin access, tenant: Northvolt only
   */
  ROOT = 'root', // root admin access, tenant: Northvolt
}

export type AuthUser = {
  email: string
  firstName: string
  fullName: string
  lastName: string
  roles: RolesEnum[]
  tenant: string
  avatar?: string
  jobTitle: string
}

export type JwtValue = {
  readonly tokenString: string
  readonly decodedContents: any
  readonly expires: Date
}
